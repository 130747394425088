import React, { forwardRef, Fragment } from "react";
import Form from "dinamicform";
import Select from "react-select";
import TextArea from "./TextArea";
import { SketchPicker } from 'react-color'
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import ListCheckbox from "./ListCheckbox";
import Input from "../input/Input";
import Icon from '@material-ui/core/Icon';
import moment from 'moment'
import TextEditorQuill from "./TextEditorQuill";
import DownloadFiles from "app/utils/DownloadFiles";
import AutocompleteComponent from "./Autocomplete";
import { Tooltip } from '@material-ui/core';
import { IconButton } from "@mui/material";

var base64Img = require("base64-img");

//forwardRef é opcional
export default forwardRef(
  Form.bind(
    ({ props, errors, values, changeValue, submit, button }) => ({
      breakpoints: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
      errors: [
        (field) => {
          let { required, name } = field;
          let value = values[name];
          if (required && !value) {
            return "Campo Obrigatório";
          }
        },
      ],
      onError: (err) => console.log(err),
      components: [
        {
          type: "default",
          contentProps: {},
          content: (field) => (
            <div>
              <label>{field.label}</label>
              <input
                type={field.type}
                value={props.values ? (typeof props.values[field.name] == 'string' ? props.values[field.name] : '') : (typeof values[field.name] == 'string' ? values[field.name] : '')} // recomendo colocar o ||<formato do campo -> string|array|boolean/> caso contrário esse componente pode apresentar falhas; errado: value={values[field.name]}; certo: value={values[field.name] || []}
                onChange={(evt) => {
                  changeValue(field.name, evt.target.value);
                }}
                name={field.name}
                placeholder={field.placeholder}
                style={{ width: "100%" }}
                disabled={field.disabled || (field.nullable && values[field.name] === true)}
              />
              {field.nullable && (
                <div style={{ position: 'relative', left: field.col == 12 ? 380 : 125, marginTop: -38, width: 65 }}>
                  <input
                    type="checkbox"
                    checked={values[field.name] === true}
                    onChange={(evt) => {
                      changeValue(field.name, evt.currentTarget.checked);
                    }}
                  />
                  <label>Nulo</label>
                </div>
              )}

              <span style={{ color: "red" }}>{errors[field.name]}</span>
            </div>

          ),
        },
        {
          type: "money",
          contentProps: {},
          content: (field) => (
            <Fragment>
              <label>{field.label}</label>
              <Input
                value={props.values && props.values[field.name] || values[field.name] || ''}
                onchange={(evt) => {
                  changeValue(field.name, evt.target.value);
                }}
                money={true}
                name={field.name}
                disabled={field.disabled}
              />
              <span style={{ color: "red" }}>{errors[field.name]}</span>
            </Fragment>
          ),
        },
        {
          type: "checkbox",
          contentProps: {},
          content: (field) => (
            <div style={{ display: 'flex', marginTop: 25 }}>
              <input
                type={field.type}
                checked={values[field.name] || false}
                onChange={(evt) => {
                  changeValue(field.name, evt.currentTarget.checked);
                }}
                name={field.name}
                placeholder={field.placeholder}
                style={{ width: "10%", height: 15, marginTop: 5 }}
                disabled={field.disabled}
              />
              {!field.color && (
                <Tooltip title={<h4 style={{ padding: 10 }}>{field.tooltip}</h4>} placement="right">
                  <label>{field.label}</label>
                </Tooltip>
              )}
              {field.color && (
                <div className="flex items-center" style={{
                  backgroundColor: field.color,
                  height: 40,
                  width: 120,
                  borderRadius: 20,
                  margin: 'auto',
                  color: 'white',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: 16
                }}>

                  {field.label}</div>
              )}
              <span style={{ color: "red" }}>{errors[field.name]}</span>
            </div>
          ),
        },
        {
          type: "listCheckbox",
          contentProps: {}, // passo props para a tag pai desse campo, nesse caso, é uma grid coluna
          content: (field) => (
            <div>
              <label>{field.label}</label>
              <ListCheckbox
                options={props.fields
                  .filter((f) => f.name == field.name)[0]
                  .options
                }
                values={props.values[field.name] || []}
                field={field}
                changeValue={changeValue}
              />
            </div>
          ),
        },
        {
          type: 'content',
          contentProps: {},
          content: (field) => (
            field.content
          )
        },
        {
          type: "file",
          contentProps: {}, // passo props para a tag pai desse campo, nesse caso, é uma grid coluna
          content: (field) => (
            <div>
              <Fragment>
                <label>{field.label}</label>
                <input
                  type={field.type}
                  disabled={field.disabled}

                  onChange={(evt) => {
                    Array.from(evt.target.files).map(async (e) => {

                      let reader = new FileReader();
                      reader.readAsDataURL(e);
                      reader.onload = () => {
                        e.data = reader.result;
                        e.filename = e.name;
                        e.filetype = e.type;
                      };
                    });
                    changeValue(field.name, evt.target.files);
                  }}

                  multiple
                  name={field.name}
                  placeholder={field.placeholder}
                  style={{ width: "100%" }}
                />
                <span style={{ color: "red" }}>{errors[field.name]}</span>
              </Fragment>
              {props.values[field.name] && props.values[field.name].length > 0
                ? Array.from(props.values[field.name])
                  .map((f) => {
                    return (<div className="divFile">
                      <label className="labelFile" onClick={() => DownloadFiles(f.filepath, f.name)}>
                        {f.dt_cad ? moment(f.dt_cad).format('DD/MM/YYYY HH:mm:ss') : ''} -
                        {` ${f.name}`}
                      </label>
                      {f.filepath ? (<input type="button" className="buttonFile" onClick={() => props.removeFile(field.name, f)} value="x" />) : null}
                    </div>)
                  }
                  )
                :
                null
              }
            </div>
          ),
        },
        {
          type: "image",
          contentProps: {}, // passo props para a tag pai desse campo, nesse caso, é uma grid coluna
          content: (field) => (
            <div className="imageDiv"
              style={{ backgroundImage: `url('${field.src}')` }}
            >
              <Fragment>
                <label for={'imagePicker'}
                  className="imageLabel"
                >

                  <FuseSvgIcon className="text-white">heroicons-outline:camera</FuseSvgIcon>
                  <input
                    type='file'
                    disabled={field.disabled}
                    accept="image/*"
                    onChange={(evt) => {
                      const file = evt.target.files[0]
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => {
                        file.data = reader.result;
                        file.filename = file.name;
                        file.filetype = file.type;
                        changeValue(field.name, file);
                      }

                    }}
                    id={'imagePicker'}
                    name={field.name}
                    placeholder={field.placeholder}
                    style={{ width: "100%", display: 'none' }}
                  />
                  <span style={{ color: "red" }}>{errors[field.name]}</span>
                </label>
              </Fragment>
            </div>
          ),
        },
        {
          type: "textarea",
          contentProps: {}, // passo props para a tag pai desse campo, nesse caso, é uma grid coluna
          content: (field) => (
            <div>
              <label>{field.label}</label>
              <TextArea

                disabled={field.disabled}
                value={values[field.name] || ""} // recomendo colocar o ||<formato do campo -> string|array|boolean/> caso contrário esse componente pode apresentar falhas; errado: value={values[field.name]}; certo: value={values[field.name] || []}
                onChange={(evt) => changeValue(field.name, evt)}
                f={field}
              />
              <span style={{ color: "red" }}>{errors[field.name]}</span>
            </div>
          ),
        },
        {
          type: "textEditor",
          contentProps: {}, // passo props para a tag pai desse campo, nesse caso, é uma grid coluna
          content: (field) => (
            <div>
              <label>{field.label}</label>
              <TextArea

                disabled={field.disabled}
                value={values[field.name] || ""} // recomendo colocar o ||<formato do campo -> string|array|boolean/> caso contrário esse componente pode apresentar falhas; errado: value={values[field.name]}; certo: value={values[field.name] || []}
                onChange={(evt) => { changeValue(field.name, evt) }}
                f={field}
              />
              <span style={{ color: "red" }}>{errors[field.name]}</span>
            </div>
          ),
        },
        {
          type: "select",
          contentProps: {},
          content: (field) => (
            <div>
              <label>{field.label}</label>
              <div style={{ display: 'flex' }}>
                <Select
                  isClearable={field.isClearable}

                  formatOptionLabel={(option) => (
                    <div style={{ color: 'black' }}>
                      {option.label}
                    </div>
                  )}
                  options={
                    props.fields.filter((f) => f.name == field.name)[0].options.filter(o => o.invisible !== true)
                  }
                  value={
                    props.fields
                      .filter((f) => f.name == field.name)[0]
                      .options.filter(function (option) {
                        return option.value === (props.values ? props.values[field.name] : values[field.name]);
                      }) || []
                  }
                  name={field.name}
                  placeholder={field.placeholder}
                  onChange={(evt) => {
                    if (evt == null) {
                      changeValue(field.name, 0);
                    } else {
                      changeValue(field.name, evt.value);

                    }
                  }}
                  className="select"
                  isDisabled={field.disabled}
                />
                {field.showAll && (
                  <div >
                    <IconButton
                      onClick={field.showAll}>
                      <Icon>
                        search
                      </Icon>
                    </IconButton>
                  </div>

                )}
              </div>


              <span style={{ color: "red" }}>{errors[field.name]}</span>
            </div>
          ),
        },
        {
          type: "autocomplete",
          contentProps: {},
          content: (field) => (
            <div>
              <label>{field.label}</label>
              <AutocompleteComponent
                isMulti={field.isMulti}
                value={[]}
                name={field.name}
                searchType={field.searchType}
                fieldLabel={field.fieldLabel}
                fieldValue={field.fieldValue}
                placeholder={field.placeholder}
                onChange={(evt) => {
                  changeValue(field.name, evt);
                }}
                createNewEntity={field.createNewEntity}
                className="select"
                isDisabled={field.disabled}
                field={field}
                errors={errors}
                labelDefault={field.labelDefault}
                idDefault={field.idDefault}
                getLabel={field.getLabel}
              />
              <span style={{ color: "red" }}>{errors[field.name]}</span>
            </div>
          ),
        },
        {
          type: "color",
          contentProps: {}, // passo props para a tag pai desse campo, nesse caso, é uma grid coluna
          content: (field) => (
            <div>
              <label>{field.label}</label>

              <SketchPicker
                color={values[field.name]}
                name={field.name}
                onChange={(evt) => {
                  changeValue(field.name, evt.hex);
                }}
                className="select"
                isDisabled={field.disabled}
              />
              <span style={{ color: "red" }}>{errors[field.name]}</span>
            </div>
          ),
        },
        {
          type: "multiselect",
          contentProps: {}, // passo props para a tag pai desse campo, nesse caso, é uma grid coluna
          content: (field) => (
            <div>
              <label>{field.label}</label>
              <Select
                closeMenuOnSelect={false}
                options={
                  props.fields.filter((f) => f.name == field.name)[0].options
                }
                value={
                  props.fields.filter((f) => f.name == field.name)[0].options
                    ? props.fields
                      .filter((f) => f.name == field.name)[0]
                      .options.filter((opt) =>
                        typeof values[field.name] == 'object' && values[field.name]
                          ? values[field.name].includes(opt.value)
                          : 1 == 2
                      )
                    : []
                }
                name={field.name}
                placeholder={field.placeholder}
                onChange={(evt) => {
                  changeValue(
                    field.name,
                    evt.map((e) => e.value)
                  );
                }}
                className="select"
                formatOptionLabel={(option) => (
                  <div style={{ color: option.color }}>
                    {option.label}
                  </div>
                )}
                style={{ zIndex: 10 }}
                isMulti
                isDisabled={field.disabled || (field.nullable && values[field.name] === true)}
              />
              {field.nullable && (
                <div style={{ position: 'relative', left: field.col == 12 ? 380 : 125, marginTop: -38 }}>
                  <input
                    type="checkbox"
                    checked={values[field.name] === true}
                    onChange={(evt) => {
                      changeValue(field.name, evt.currentTarget.checked);
                    }}
                  />
                  <label>Nulo</label>
                </div>
              )}
              < span style={{ color: "red" }}>{errors[field.name]}</span>
            </div>
          ),
        },
      ],
      button:
        props.hasSubmit && (
          <div style={{ marginTop: props.buttonBellow ? props.buttonBellow : 0 }}>
            <button disabled={props.disableButton} className={props.disableButton ? 'buttonSubmit buttonOpacity' : 'buttonSubmit'} onClick={submit}> {props.button?.label ? props.button.label : 'SALVAR'} </button>,
          </div>
        )

    })
  )
);
