import React, { useState, useEffect } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { useResizeDetector } from 'react-resize-detector';
import moment from 'moment-timezone'
export default function ProposalProductForm({
    params,
    id_proposals, vehicle, values, setPage, getData }) {
    const [valuesForm, setValuesForm] = useState(values)
    const [vehicles, setVehicles] = useState([])
    const { width, height, ref } = useResizeDetector();
    const [valuesProduct, setValuesProduct] = useState(values)
    const [products, setProducts] = useState([])
    const [status, setStatus] = useState([])
    const [users, setUsers] = useState([])

    useEffect(() => {
        axios
            .get(
                Constants.APIEndpoints.PRODUCT + "/getAllProducts")
            .then((res) => {
                setProducts(res.data[0])
            })
        axios
            .get(
                Constants.APIEndpoints.STATUS + "/getAllStatus")
            .then((res) => {
                setStatus(res.data[0])
            })

        axios
            .get(
                Constants.APIEndpoints.USER + "/getAllUsersSimple")
            .then((res) => {
                setUsers(res.data)
            })

        if (!values.month_received) {
            let values_ = valuesProduct
            values_.month_received = moment().month()
            setValuesProduct({ ...values_ })
        }
        if (values.dt_start) {
            let values_ = valuesProduct
            values_.dt_start = moment(values.dt_start).format('YYYY-MM-DD')
            setValuesProduct({ ...values_ })
        }
        if (values.dt_end) {
            let values_ = valuesProduct
            values_.dt_end = moment(values.dt_end).format('YYYY-MM-DD')
            setValuesProduct({ ...values_ })
        }
    }, [])

    const addProduct = (v) => {
        let values = valuesProduct
        values.fk_id_proposal = id_proposals
        const url = values.id_rel_proposal_product ? '/updateproposalproduct' : '/saveproposalproduct'
        axios.post(Constants.APIEndpoints.PROPOSAL + url, values).then(res => {
            getData()
            setPage('list')
        })
    }
    const monthList = [
        { value: 0, label: "Janeiro" },
        { value: 1, label: "Fevereiro" },
        { value: 2, label: "Março" },
        { value: 3, label: "Abril" },
        { value: 4, label: "Maio" },
        { value: 5, label: "Junho" },
        { value: 6, label: "Julho" },
        { value: 7, label: "Agosto" },
        { value: 8, label: "Setembro" },
        { value: 9, label: "Outubro" },
        { value: 10, label: "Novembro" },
        { value: 11, label: "Dezembro" },
    ];
    let fieldsProduct = [
        {
            col: 3,
            type: "select",
            name: "fk_id_product",
            label: "Produto",
            options: [
                { value: 1, label: 'FACEBOOK' },
                { value: 2, label: 'INSTAGRAM' },
                { value: 3, label: 'FB/IG' },
                { value: 4, label: 'TIKTOK' },
                { value: 5, label: 'LINKEDIN' },
                { value: 6, label: 'GOOGLE - SEARCH' },
                { value: 7, label: 'GOOGLE - DISPLAY' },
                { value: 8, label: 'YOUTUBE' },
                { value: 9, label: 'PUSH ADS' },
                { value: 10, label: 'GEOLOCALIZAÇÃO' },
                { value: 11, label: 'NATIVE' },
                { value: 12, label: 'CONNECTV' },
                { value: 13, label: 'RICHMEDIA' },
                { value: 14, label: 'PROGRAMATICA' },
                { value: 15, label: 'TEADS' },
                { value: 16, label: 'TWITTER' },
                { value: 17, label: 'BANNER ARROBA MKTUP' },
            ],
        },
        {
            col: 9,
            type: "text",
            name: "objective",
            label: "Objetivo",
        },
        {
            col: 4,
            type: "select",
            name: "fk_id_executive",
            label: "Executivo",
            options: users.map(user => ({ value: user.id_user, label: user.name })),
            visible: false
        },
        // {
        //     col: 2,
        //     type: "money",
        //     name: "price",
        //     label: "Preço",
        // },
        {
            col: 5,
            type: "number",
            name: "quantity_hired",
            label: "Quantidade contratada",
        },
        {
            col: 5,
            type: "number",
            name: "quantity_delivered",
            label: "Quantidade Entregue",
        },
        {
            col: 2,
            type: "text",
            name: "percent_delivered",
            label: "Porcentagem entregue",
            disabled: true
        },
        // {
        //     col: 4,
        //     type: "number",
        //     name: "negociation",
        //     label: "Desconto",
        // },
        {
            col: 3,
            type: "date",
            name: "dt_start",
            label: "Data Inicial",
        },
        {
            col: 3,
            type: "date",
            name: "dt_end",
            label: "Data Final",
        },
        {
            col: 3,
            type: "number",
            name: "days",
            label: "Dias",
        },
        {
            col: 3,
            type: "select",
            name: "status",
            label: "Status",
            options: status.map(s => ({ value: s.id_status, label: s.name }))
        },

        {
            col: 3,
            type: "money",
            name: "platform_costs",
            label: "Custos da Plataforma",
        },

        {
            col: 3,
            type: "money",
            name: "extras_costs",
            label: "Custos Extras",
        },
        {
            col: 3,
            type: "money",
            name: "investment",
            label: "Investimento",
        },
        {
            col: 3,
            type: "text",
            name: "percent_costs",
            label: "Porcentagem de custos",
            disabled: true
        },

        {
            col: 3,
            type: "money",
            name: "lost",
            label: "Perdas",
        },
        {
            col: 3,
            type: "money",
            name: "gross_billed",
            label: "Valor Bruto",
        },
        {
            col: 3,
            type: "money",
            name: "net_billed",
            label: "Valor Líquido",
        },
        {
            col: 3,
            type: "select",
            name: "month_received",
            label: "Mês de Recebimento",
            options: monthList,

        },
        {
            col: 3,
            type: "text",
            name: "nf",
            label: "Nota Fiscal",
            visible: false
        },
        {
            col: 3,
            type: "select",
            name: "delivered_status",
            label: "Status da Entrega",
            options: [
                { value: 1, label: 'Under' },
                { value: 2, label: 'Over' },
                { value: 3, label: 'Cancelada' }
            ]

        },
        {
            col: 12,
            type: "textarea",
            name: "observation",
            label: "Observações",
        },
    ];


    return (
        <div ref={ref}>
            <CommonHeader title={'Produtos do Negócio'} onBack={() => setPage("list")}
                width={width}
            />
            <div style={{
                marginTop: 120
            }}>
                <CommonForm
                    values={valuesProduct}
                    fields={fieldsProduct}
                    onChangeField={(f, v) => {

                        let newProduct = valuesProduct
                        if (f.name == 'fk_id_product') {
                            const products = ['',
                                'FACEBOOK',
                                'INSTAGRAM',
                                'FB/IG',
                                'TIKTOK',
                                'LINKEDIN',
                                'GOOGLE - SEARCH',
                                'GOOGLE - DISPLAY',
                                'YOUTUBE',
                                'PUSH ADS',
                                'GEOLOCALIZAÇÃO',
                                'NATIVE',
                                'CONNECTV',
                                'RICHMEDIA',
                                'PROGRAMATICA',
                                'TEADS',
                                'TWITTER',
                                'BANNER ARROBA MKTUP']
                            newProduct.product_name = products[v]
                        }
                        if (f.name == 'dt_end' && valuesProduct.dt_start) {
                            newProduct.days = moment(v).diff(moment(valuesProduct.dt_start), 'days') + 1
                        }
                        if (f.name == 'dt_start' && valuesProduct.dt_end) {
                            newProduct.days = moment(v).diff(moment(valuesProduct.dt_start), 'days') + 1
                        }
                        if (f.name == 'days' && valuesProduct.dt_end) {
                            newProduct.dt_end = moment(valuesProduct.dt_start).add(v - 1, 'day').format('YYYY-MM-DD')
                        }
                        if (f.name == 'quantity_hired') {
                            newProduct.percent_delivered = `${newProduct.quantity_delivered ? ((newProduct.quantity_delivered || 0) / v * 100).toFixed(2) : 0}%`
                        }

                        if (f.name == 'quantity_delivered') {
                            newProduct.percent_delivered = `${((v / (newProduct.quantity_hired || 0)) * 100).toFixed(2)}%`
                        }
                        if (f.name == 'platform_costs') {
                            const extras_costs = newProduct.extras_costs || 0
                            const investment = newProduct.investment || 0
                            newProduct.percent_costs = investment == 0 ? '0%' : `${(((parseFloat(v) + parseFloat(extras_costs)) / investment) * 100).toFixed(2)}%`
                        }
                        if (f.name == 'extras_costs') {
                            const platform_costs = newProduct.platform_costs || 0
                            const investment = newProduct.investment || 0
                            newProduct.percent_costs = investment == 0 ? '0%' : `${(((parseFloat(platform_costs) + parseFloat(v)) / investment) * 100).toFixed(2)}%`
                        }

                        if (f.name == 'investment') {
                            const platform_costs = newProduct.platform_costs || 0
                            const extras_costs = newProduct.extras_costs || 0
                            newProduct.percent_costs = v == 0 ? '0%' : `${(((parseFloat(platform_costs) + parseFloat(extras_costs)) / v) * 100).toFixed(2)}%`

                            const lost = newProduct.lost || 0
                            newProduct.gross_billed = parseFloat(v) + parseFloat(lost)
                            newProduct.net_billed = parseFloat(newProduct.gross_billed * 0.8).toFixed(2)
                        }
                        if (f.name == 'lost') {
                            const investment = newProduct.investment || 0
                            newProduct.gross_billed = parseFloat(v) + parseFloat(investment)
                            newProduct.net_billed = parseFloat(newProduct.gross_billed * 0.8).toFixed(2)
                        }
                        newProduct[f.name] = v;
                        setValuesProduct({ ...newProduct })
                    }}
                    onSubmit={addProduct}

                />

            </div>
        </div>
    );
}

