import React, { useState, useRef } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';
import RootRef from '@material-ui/core/RootRef';
import Typography from '@material-ui/core/Typography';
import Store from 'app/utils/Store'
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from 'app/utils/Constants';
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import './Leads.css'
import BoardCard from './BoardCard';
import { Tooltip } from '@material-ui/core';

let logged_user = Store.USER

export default function BoardList({
    getData,
    list,
    index,
    usersOptions,
    columnsOptions,
    seeOnlyUserLoggedIn,
    tags,
    setOpenCreateNewCard,
    setOpenCreateNewCardWithStep,
    setSelectedColumn,
    setSelectedCard,
    selectedCard,
    getSingleLead,
    setOpenSeeActvity,
    getCardActivities,
    agencies,
    clients,
    showTimeLine,
    setOpenLossCard,
    setOpenWinCard,
    visuplac,
    openCard,
    setOpenCard
}) {
    const [openColumnEdit, setOpenColumnEdit] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)


    let allCards = []
    if (seeOnlyUserLoggedIn) {
        allCards = list.cards.filter(c => c.user_cad == logged_user.id_user)
    } else {
        allCards = list.cards
    }


    const totalNetValueCards = (allCards || []).reduce((sum, nextCard) => {
        return sum + parseFloat(nextCard.net_value || 0)
    }, 0)
    const totalWinNetValueCards = (allCards || []).filter(c => c.win == true).reduce((sum, nextCard) => {
        return sum + parseFloat(nextCard.net_value || 0)
    }, 0)
    const totalInProgessNetValueCards = (allCards || []).filter(c => c.win == false && c.loss == false).reduce((sum, nextCard) => {
        return sum + parseFloat(nextCard.net_value || 0)
    }, 0)
    const contentScrollEl = useRef(null);


    const editColumn = (values) => {
        values.fk_id_kanban_column = list.id_kanban_column
        axios.post(Constants.APIEndpoints.KANBAN + "/updateColumn", values).then(res => {
            setOpenColumnEdit(false)
            getData()
        })
    }

    const deleteColumn = () => {
        axios.post(Constants.APIEndpoints.KANBAN + "/deleteColumn", { fk_id_kanban_column: list.id_kanban_column }).then(res => {
            getData()
        })
    }


    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <Draggable draggableId={'column' + list.id_kanban_column} index={index} type="list">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                        className='listCards'
                    >
                        <Card
                            className={clsx(
                                snapshot.isDragging ? 'shadow-lg' : 'shadow',
                                'w-256 sm:w-320 mx-8 sm:mx-12 max-h-full flex flex-col rounded-20'
                            )}
                            square
                        >
                            <div style={{ display: 'flex', borderBottom: '1px solid', backgroundColor: '#e7e7e7', maxHeight: 35 }}>

                                <Typography className="titleColumn">{list.column_name}</Typography>
                                <div style={{ display: 'flex' }}>
                                    {logged_user.role == 'admin' && (
                                        <IconButton
                                            onClick={(ev) => {
                                                setOpenColumnEdit(true)
                                            }}
                                            style={{ width: 10 }}
                                        >
                                            <Icon style={{ color: 'black' }}>edit</Icon>
                                        </IconButton>
                                    )}

                                    {logged_user.role == 'admin' && (
                                        <IconButton
                                            onClick={(ev) => {
                                                setDeleteDialog(true)
                                            }}
                                            style={{ width: 10 }}

                                        >
                                            <Icon style={{ color: 'black' }}>delete</Icon>
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                            <Tooltip title={<h5>Total</h5>} placement="right">
                                <div className="cardTotalValueAndBusiness">
                                    <Icon>
                                        dashboard
                                    </Icon>
                                    <Typography>{`${totalNetValueCards.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - ${(allCards || []).length} negócios`}</Typography>
                                </div>
                            </Tooltip>
                            <Tooltip title={<h5>Ganhos</h5>} placement="right">
                                <div className="cardTotalWinValueAndBusiness">
                                    <Icon>
                                        check_circle_outline
                                    </Icon>
                                    <Typography>{`${totalWinNetValueCards.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - ${(allCards || []).filter(c => c.win == true).length} negócios`}</Typography>
                                </div>
                            </Tooltip>
                            <Tooltip title={<h5>Em Andamento</h5>} placement="right">

                                <div className="cardTotalProgressValueAndBusiness">
                                    <Icon>
                                        hourglass_empty
                                    </Icon>
                                    <Typography>{`${totalInProgessNetValueCards.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - ${(allCards || []).filter(c => c.win == true).length} negócios`}</Typography>
                                </div>
                            </Tooltip>
                            <RootRef rootRef={contentScrollEl}>
                                <CardContent className="flex flex-col flex-1 flex-auto h-full min-h-0 w-full p-0 overflow-auto">
                                    <Droppable droppableId={'column' + list.id_kanban_column} type="card" direction="vertical">
                                        {(_provided) => (
                                            <div ref={_provided.innerRef} className="flex flex-col w-full h-full p-8">


                                                {Array.from(allCards || []).map((card, index) => (
                                                    <BoardCard getData={getData}
                                                        card={card}
                                                        index={index}
                                                        list={list}
                                                        usersOptions={usersOptions}
                                                        columnsOptions={columnsOptions}
                                                        tags={tags}
                                                        setSelectedCard={setSelectedCard}
                                                        selectedCard={selectedCard}
                                                        getSingleLead={getSingleLead}
                                                        setOpenCreateNewCard={setOpenCreateNewCard}
                                                        setOpenCreateNewCardWithStep={setOpenCreateNewCardWithStep}
                                                        setOpenSeeActvity={setOpenSeeActvity}
                                                        getCardActivities={getCardActivities}
                                                        agencies={agencies}
                                                        setOpenWinCard={setOpenWinCard}
                                                        setOpenLossCard={setOpenLossCard}
                                                        clients={clients}
                                                        visuplac={visuplac}
                                                        openCard={openCard}
                                                        setOpenCard={setOpenCard}
                                                    />
                                                ))}
                                                {_provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </CardContent>
                            </RootRef>
                            {list.id_kanban_column == 0 || showTimeLine == true ? null : (
                                <CardActions className="p-0 flex-shrink-0">
                                    <div className='divCreateNewCard' onClick={() => {
                                        setSelectedCard({})
                                        setSelectedColumn(list)
                                        setOpenCreateNewCardWithStep(true)

                                    }

                                    }>Novo card</div>
                                </CardActions>
                            )}
                        </Card>
                    </div>
                )}
            </Draggable>


            <CommonDialog
                open={openColumnEdit}
                onClose={() => setOpenColumnEdit(false)}
                title="Editar Coluna"
                width="xl"
            >
                <CommonForm
                    values={list}
                    fields={[
                        {
                            col: 12,
                            name: 'name',
                            label: 'Nome',
                            type: 'text',
                            required: true
                        }

                    ]}
                    onSubmit={editColumn}
                    onChangeField={(f, v) => {
                        list.name = v
                    }}
                />

            </CommonDialog>
            {deleteDialog ? (
                <ConfirmDialog title="Deseja deletar esta coluna (isso apagará todos os cards dentro dela)?" cancel={() => setDeleteDialog(false)} confirm={deleteColumn} />
            ) : null}

        </div>
    )
}