import React, { useState } from "react";
import { useResizeDetector } from 'react-resize-detector';
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import "./Proposal.css";
import 'react-toastify/dist/ReactToastify.css';


const ProposalFinances = ({
    proposal,
    setPage
}) => {
    const [valuesForm, setValuesForm] = useState(proposal)
    const { width, height, ref } = useResizeDetector();
    const onSubmit = (values) => {
        axios.put(Constants.APIEndpoints.PROPOSAL + '/updatefinances/' + proposal.id_proposals, valuesForm).then(res => {
            setPage('list')
        })
    }
    return (
        <div ref={ref}>
            <CommonHeader title={'Valores Financeiros'} onBack={() => setPage("list")}
                width={width}
            />
            <div style={{
                marginTop: 120
            }}>
                <CommonForm
                    values={valuesForm}
                    onChangeField={(f, v) => {
                        let values = { ...valuesForm }
                        values[f.name] = v;
                        if (f.name == 'taxes_nf_percent') {
                            const taxes_nf = parseFloat(valuesForm.comission_value * (v / 100)).toFixed(2)
                            values['taxes_nf'] = taxes_nf
                        }
                        if (f.name == 'incentive') {
                            const comission = (valuesForm.comission_mosaic || 0) / 100
                            const comission_value = (valuesForm.net_value_approved - v - (valuesForm.net_value_approved * ((valuesForm.taxes_vehicle || 0) / 100))) * comission
                            values['comission_value'] = comission_value
                            const taxes_nf = parseFloat(comission_value * (1.5 / 100)).toFixed(2)
                            const received_value = comission_value - taxes_nf
                            values['received_value'] = received_value
                            values['taxes_nf'] = taxes_nf
                        }
                        setValuesForm({ ...values })
                    }}
                    onSubmit={onSubmit}
                    fields={[
                        {
                            col: 8,
                            label: 'VL. Comissão Negociado',
                            type: 'money',
                            name: 'comission_value_negotiated',
                            disabled: true
                        },
                        {
                            col: 4,
                            label: 'Data de Recebimento do Veículo',
                            type: 'date',
                            name: 'dt_received_vehicle'
                        },
                        {
                            col: 6,
                            label: 'VL. Comissão',
                            type: 'money',
                            name: 'comission_value',
                            disabled: true
                        },
                        {
                            col: 3,
                            label: 'Incentivo',
                            type: 'number',
                            name: 'incentive'
                        },
                        {
                            col: 3,
                            label: 'Impostos do veículo',
                            type: 'number',
                            name: 'taxes_vehicle'
                        },
                        {
                            col: 3,
                            label: '%Impostos NF',
                            type: 'number',
                            name: 'taxes_nf_percent'
                        },
                        {
                            col: 3,
                            label: 'VL. Imposto NF',
                            type: 'number',
                            name: 'taxes_nf',
                            disabled: true
                        },
                        {
                            col: 3,
                            label: 'Nº NF Representante',
                            type: 'text',
                            name: 'nf_mosaic'
                        },
                        {
                            col: 3,
                            label: 'Data de Vencimento da NF',
                            type: 'date',
                            name: 'nf_due_date'
                        },
                        {
                            col: 6,
                            label: 'Data de Recebimento do Representante',
                            type: 'date',
                            name: 'dt_received_mosaic'
                        },
                        {
                            col: 6,
                            label: 'Valor Recebido',
                            type: 'money',
                            name: 'received_value'
                        },
                        {
                            col: 12,
                            label: 'Observações',
                            type: 'textarea',
                            name: 'observation_finances'
                        },
                        {
                            col: 3,
                            name: 'project',
                            label: 'Projeto',
                            type: 'checkbox'
                        },
                        {
                            col: 3,
                            name: 'incentive_paid',
                            label: 'Incentivo Pago',
                            type: 'checkbox'
                        },
                        {
                            col: 6,
                            name: 'incentive_nf',
                            label: 'NF Incentivo',
                            type: 'text'
                        },
                        {
                            col: 6,
                            label: 'Data de Pagamento de Comissão ao Veículo',
                            type: 'date',
                            name: 'dt_received_comission_executive'
                        },

                    ]}

                />
            </div>
        </div>
    )
}

export default ProposalFinances