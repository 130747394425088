import React, { useState } from "react";
import Form from "../../components/form/Form.config";
import './CommonForm.css'

function CommonForm({ values, fields, onSubmit, onChangeField, removeFile, hasHeader, button, buttonBellow }) {
  const [disableButton, setDisableButton] = useState(false)

  const submit = (values) => {
    setDisableButton(true)
    onSubmit(values)

    setTimeout(() => {
      setDisableButton(false)
    }, 2500)
  }

  return (
    <div className='form' style={{ marginTop: hasHeader ? 120 : 0 }}>
      <Form
        values={values}
        spacing={2}
        fields={fields}
        onChangeField={onChangeField}
        onSubmit={submit}
        removeFile={removeFile}
        button={button}
        buttonBellow={buttonBellow}
        disableButton={disableButton}
        hasSubmit={onSubmit}
      />
    </div>
  );
}

export default CommonForm;
