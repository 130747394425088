import React, { useMemo, useEffect, useState } from "react";
import CommonTable from "../../components/table/CommonTable";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonForm from "app/components/form/CommonForm";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import Store from "app/utils/Store";
let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const Holidays = ({ }) => {
    const dispatch = useDispatch();
    // const user = useSelector(({ contactsApp }) => contactsApp.user);
    const user = [];

    const [values, setValues] = useState([])
    const [page, setPage] = useState('list')
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [data, setData] = useState([])
    const columns = useMemo(
        () => [
            {
                Header: "Nome",
                accessor: "name",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Data",
                accessor: "date_format",
                sortable: true,
            },
            {
                id: "action",
                width: 128,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex items-center">
                        {access.config_holiday_update && (
                            <IconButton
                                onClick={(ev) => {
                                    handlePage('add', row.original)
                                }}
                            >
                                <Icon>edit</Icon>
                            </IconButton>
                        )}
                        {access.config_holiday_delete && (
                            <IconButton
                                onClick={(ev) => {
                                    setValues(row.original)
                                    setDeleteDialog(true)
                                }}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        )}
                    </div>
                ),
            },
        ],
        [dispatch, user.starred]
    );

    useEffect(() => {
        getData()
    }, [])

    const handlePage = (page, obj) => {
        setPage(page)
        setValues(obj)
    }
    const onAdd = () => {
        handlePage('add')
    }

    const getData = () => {
        axios.get(Constants.APIEndpoints.HOLIDAY + '/getAllHolidays', '').then(res => {
            const holidays = res.data.map(h => {
                h.date_format = moment(h.date).format('DD/MM/YYYY')
                h.date = moment(h.date).format('YYYY-MM-DD')
                return h
            })
            setData(holidays)
        })
    }

    const saveHoliday = (data) => {
        axios.post(Constants.APIEndpoints.HOLIDAY + (data.id_holiday ? '/updateHoliday' : '/createHoliday'), data).then(res => {
            handlePage('list')
            getData()
        })
    }

    const deleteHoliday = () => {
        axios.post(Constants.APIEndpoints.HOLIDAY + '/deleteHoliday', { id_holiday: values.id_holiday }).then(res => {
            setDeleteDialog(false)
            getData()

        })
    }

    return (
        <div>
            {page == 'list' && (
                <CommonTable
                    columns={columns}
                    data={data}
                    newText="Adicionar novo Feriado"
                    onAdd={access.config_holiday_create && onAdd}
                />
            )}


            {page == 'add' && (
                <div>
                    <IconButton
                        onClick={(ev) => {
                            handlePage('list')
                        }}
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                    <CommonForm
                        fields={[
                            {
                                col: 6,
                                name: 'name',
                                label: 'Nome',
                                type: 'text',
                                required: true
                            }, {
                                col: 6,
                                name: 'date',
                                label: 'Data',
                                type: 'date',
                                required: true
                            }
                        ]}
                        values={values}
                        onSubmit={saveHoliday}
                    />
                </div>
            )}

            {deleteDialog ? (
                <ConfirmDialog title="Deseja deletar esse Feriado?" cancel={() => setDeleteDialog(false)} confirm={deleteHoliday} />
            ) : null}
        </div>
    )
}

export default Holidays