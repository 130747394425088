import axios from 'app/utils/AxiosConfig'
import Constants from 'app/utils/Constants'
import React, { useState, useEffect, useMemo } from 'react'
import Store from 'app/utils/Store'
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import CommonTable from "app/components/table/CommonTable";
import clsx from 'clsx';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import BoardList from './BoardList'
import './Leads.css'
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch } from "react-redux";
import moment from 'moment-timezone'
import CreateCard from './CreateCard'
import CreateCardWithStep from './CreateCardWithStep'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { Tooltip } from '@material-ui/core';
import WinCard from './winCard'
import FunnelPanel from './FunnelPanel'
import EditLocationIcon from '@material-ui/icons/EditLocation';
import TimelineIcon from '@material-ui/icons/Timeline';
import AddIcon from '@material-ui/icons/Add';
import { eventBus } from "app/utils/EventListener";
import { useLocation } from "react-router";
import Visualization from './SingleLead/Visualization';

let logged_user = Store.USER

export default function Leads({ visuplac }) {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [seeOnlyUserLoggedIn, setSeeOnlyUserLoggedIn] = useState(false)
    const [kanban, setKanban] = useState([])
    const [openCreateColumn, setOpenCreateColumn] = useState(false)
    const [usersOptions, setUsersOptions] = useState([])
    const [cards, setCards] = useState([])
    const [clients, setClients] = useState([])
    const [agencies, setAgencies] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [tags, setTags] = useState([])
    const [page, setPage] = useState('kanban')
    const [selectedTags, setSelectedTags] = useState([])

    const [selectedColumn, setSelectedColumn] = useState({})
    const [selectedCard, setSelectedCard] = useState({})
    const [openCreateActivity, setOpenCreateActvity] = useState(false)
    const [openSeeActivity, setOpenSeeActvity] = useState(false)
    const [idCard, setIdCard] = useState(0)
    const [cardActivities, setCardActivities] = useState([])
    const [activitySelected, setActivitySelected] = useState({})
    const [deleteDialogActivity, setDeleteDialogActivity] = useState(false)
    const [openCreateNewCard, setOpenCreateNewCard] = useState(false)
    const [openCreateNewCardWithStep, setOpenCreateNewCardWithStep] = useState(false)

    const [mailings, setMailings] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [products, setProducts] = useState([])
    const [users, setUsers] = useState([])
    const [concludedDialog, setConcludedDialog] = useState(false)
    const [openWinCard, setOpenWinCard] = useState(false)
    const [openLossCard, setOpenLossCard] = useState(false)
    const [openFunnelPanel, setOpenFunnelPanel] = useState(false)
    const [funnels, setFunnels] = useState([])
    const [idFunnelSelected, setIdFunnelSelected] = useState(0)
    const [deleteFunnelDialog, setDeleteFunnelDialog] = useState(false)
    const [showTimeLine, setShowTimeLine] = useState(false)
    const [filters, setFilters] = useState({ status: 3 })
    const [buttonSelected, setButtonSelected] = useState(0)
    const [openCard, setOpenCard] = useState(false)
    useEffect(() => {
        getData({ inProgress: true });
        getFunnels()
        axios.get(Constants.APIEndpoints.USER + "/getAllUsersSimple").then(users => {
            let users_ = users.data.map(u => ({ value: u.id_user, label: u.name }))
            setUsersOptions(users_)
        })
        axios
            .get(
                Constants.APIEndpoints.CLIENT + "/getAllClients")
            .then((res) => {
                setClients(res.data)
            })
        axios
            .get(
                Constants.APIEndpoints.AGENCY + "/getAllAgencies")
            .then((res) => {
                setAgencies(res.data)
            })
        axios.get(Constants.APIEndpoints.TAGS + "/getAllTags").then((res) => {
            setTags(res.data);
        });
        axios.post(Constants.APIEndpoints.MAILING + '/getAllMailings?').then(res => {
            setMailings(res.data)
        })
        axios.get(Constants.APIEndpoints.USER + '/getAllUsersSimple').then(res => {
            setUsers(res.data)
        })
        axios.get(Constants.APIEndpoints.VEHICLE + "/getAllVehicles").then((res) => {
            setVehicles(res.data)
        })
        axios
            .get(
                Constants.APIEndpoints.PRODUCT + "/getAllProducts")
            .then((res) => {
                setProducts(res.data[0])
            })
        openFromActivityHistory()
    }, [])
    useEffect(() => {
        if (page == 'table') {
            getDataTable()
        }
    }, [page])

    const openFromActivityHistory = () => {
        if (state) {
            if (state.mode == 'view') {
                getSingleLead(state.fk_id_card)
                setOpenCard(true)
            } else {
                axios.get(Constants.APIEndpoints.KANBAN + '/getstatuscard/' + state.fk_id_card).then(res => {
                    if (res.data.win) {
                        toast.error("Lead já deu ganho, não pode ser editado");
                    } else if (res.data.loss) {
                        toast.error("Lead já deu perda, não pode ser editado");
                    } else {
                        getSingleLead(state.fk_id_card)
                        setOpenCreateNewCardWithStep(true)
                    }
                })
            }
        }
    }

    const getDataTable = (values = {}) => {
        values.user = logged_user.id_user
        axios.post(Constants.APIEndpoints.KANBAN + "/getAllCards", values).then(res => {
            setDataTable(res.data)
        })
    }

    const getData = (values = {}) => {
        if (values.fk_id_salles_funnel > -1) {
            setIdFunnelSelected(values.fk_id_salles_funnel)
            localStorage.setItem('fk_id_salles_funnel', values.fk_id_salles_funnel)
        } else {
            const id_salles_funnel = localStorage.getItem('fk_id_salles_funnel')
            if (!isNaN(id_salles_funnel)) {
                setIdFunnelSelected(id_salles_funnel)
                values.fk_id_salles_funnel = id_salles_funnel
            } else {
                values.fk_id_salles_funnel = 0
            }
        }
        setShowTimeLine(false)
        setPage('kanban')
        values.user = logged_user.id_user
        values.filters = filters
        axios.post(Constants.APIEndpoints.KANBAN + "/getKanban", values).then(res => {
            let all_cards = []
            res.data[0].map(data => {
                all_cards.push(...data.cards)
            })
            setCards(all_cards)
            setKanban(res.data[0])
        })
    }

    const getFunnels = () => {
        setPage('kanban')
        axios.get(Constants.APIEndpoints.KANBAN + '/getfunnels').then(res => {
            const funnelDefault = { id_salles_funnel: 0, name: 'Padrão', user_cad: 0 }
            setFunnels([funnelDefault, ...res.data])
        })
    }

    const exportCards = () => {
        let file = `Cards_${moment().format('DDMMYYYY_HH:mm:ss')}.xlsx`
        const token = sessionStorage.getItem('token');
        fetch(Constants.APIEndpoints.KANBAN + '/exportCards',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Access-Token': token

                },
                body: JSON.stringify({ fk_id_salles_funnel: idFunnelSelected })
            }).then(results => {
                return results.blob()
            }).then(response => {
                let data = response;
                let elm = document.createElement('a');
                elm.href = URL.createObjectURL(data);
                elm.setAttribute('download', file);
                elm.click();
                elm.remove();
            })
    }

    const onDragEnd = (result) => {
        const notification = toast("Movendo");
        const { destination, source, draggableId } = result
        const columnOrigin = source.droppableId.replace('column', '')
        const columnDestination = destination.droppableId.replace('column', '')
        const cardId = draggableId.replace('card', '')

        if (result.type == 'card') {
            let newKanban = [...kanban]
            const card = kanban.find(k => k.id_kanban_column == columnOrigin).cards.find(c => c.id_cards == cardId)
            newKanban.find(k => k.id_kanban_column == columnDestination).cards.push(card)
            newKanban.find(k => k.id_kanban_column == columnOrigin).cards = newKanban.find(k => k.id_kanban_column == columnOrigin).cards.filter(c => c.id_cards != cardId)
            setKanban(newKanban)
            const data = {
                fk_id_column_destination: columnDestination,
                fk_id_card: cardId
            }

            axios.post(Constants.APIEndpoints.KANBAN + "/moveCard", data).then(res => {
                getData({ fk_id_salles_funnel: idFunnelSelected })
                toast.update(notification, { render: "Movido", type: toast.TYPE.SUCCESS, isLoading: false });

            })

        } else {
            const data = {
                origin: source.index,
                destination: destination.index
            }
            axios.post(Constants.APIEndpoints.KANBAN + "/moveColumn", data).then(res => {
                getData({ fk_id_salles_funnel: idFunnelSelected })
                toast.update(notification, { render: "Movido", type: toast.TYPE.SUCCESS, isLoading: false });

            })
        }

    }

    const lossCard = (values) => {
        values.fk_id_card = selectedCard.id_cards
        axios.post(Constants.APIEndpoints.KANBAN + '/losscard', values).then(res => {
            setOpenLossCard(false)
            setConcludedDialog(false)
            setOpenSeeActvity(false)
            eventBus.emit('close-moda-card', {})
            getData({ fk_id_salles_funnel: idFunnelSelected })
        })
    }

    const createNewKanban = (values) => {
        values.user_cad = logged_user.id_user
        values.fk_id_salles_funnel = idFunnelSelected
        axios.post(Constants.APIEndpoints.KANBAN + "/createColumn", values).then(res => {
            getData({ fk_id_salles_funnel: idFunnelSelected });
            setOpenCreateColumn(false)
        })
    }

    let allCards = []
    if (seeOnlyUserLoggedIn) {
        allCards = cards.filter(c => c.user_cad == logged_user.id_user)
    } else {
        allCards = cards
    }
    const totalGrossValueFromCards = allCards.reduce((sum, nextCard) => {
        return sum + parseFloat(nextCard.gross_value)
    }, 0)



    const columns = useMemo(
        () => [
            {
                Header: 'Etiquetas',
                accessor: 'tags',
                noSearchable: true,
                Cell: ({ row }) => (
                    <div>
                        {(row.original.tags || []).map(tag => (
                            <div style={{
                                backgroundColor: tag.color, height: 20, width: 60
                                , margin: 4, borderRadius: 10, display: 'flex', color: 'white', fontSize: 10,
                                justifyContent: 'center', alignItems: 'center'
                            }}>{tag.name}</div>
                        ))}
                    </div>
                )
            },
            {
                Header: "Assunto",
                accessor: "subject",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Local de Venda",
                accessor: "place_sell",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Valor Bruto",
                accessor: "gross_value",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Início",
                accessor: "dt_start",
                className: "font-medium",
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {row.original.dt_start ? moment(row.original.dt_start).format('DD/MM/YYYY') : ''}
                    </div>
                )
            }, {
                Header: "Término",
                accessor: "dt_end",
                className: "font-medium",
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {row.original.dt_end ? moment(row.original.dt_end).format('DD/MM/YYYY') : ''}
                    </div>
                )
            },
            {
                Header: "Data de Cadastro",
                accessor: "dt_cad",
                className: "font-medium",
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {row.original.dt_cad ? moment(row.original.dt_cad).format('DD/MM/YYYY') : ''}
                    </div>
                )
            },
            {
                Header: "Data de Edição",
                accessor: "dt_up",
                className: "font-medium",
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {row.original.dt_up ? moment(row.original.dt_up).format('DD/MM/YYYY') : ''}
                    </div>
                )
            },
            {
                Header: "Client",
                accessor: "client",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Agência",
                accessor: "agency",
                sortable: true,
            },
            {
                Header: "Coluna",
                accessor: "column_name",
                sortable: true,
            },
            {
                Header: "Contato",
                accessor: "contact_name",
                sortable: true,
            },
        ],
        [dispatch]
    );

    const saveCardAcvitivy = (values) => {
        values.user_cad = logged_user.id_user
        values.fk_id_card = idCard

        if (moment(`${values.dt_start} ${values.time_start}`).isSameOrAfter(moment(`${values.dt_end} ${values.time_end}`))) {
            toast("Data de término não pode ser inferior a de início");
        } else {
            const url = values.id_card_activity ? '/updateactivity' : '/createactivity'
            axios.post(Constants.APIEndpoints.KANBAN + url, values).then(res => {
                getCardActivities()
                setOpenCreateActvity(false)
                setConcludedDialog(false)
            })
        }
    }

    const getCardActivities = (card = 0) => {
        const id = card == 0 ? idCard : card
        setIdCard(id)
        axios.get(Constants.APIEndpoints.KANBAN + '/getcardactivities/' + id).then(res => {
            setCardActivities(res.data)
        })
    }

    const deleteActivity = () => {
        axios.delete(Constants.APIEndpoints.KANBAN + '/deleteactivity/' + activitySelected.id_card_activity).then(res => {
            getCardActivities()
            setDeleteDialogActivity(false)
        })
    }
    const createNewCard = (values) => {
        values.user_cad = logged_user.id_user
        values.id_column = selectedColumn.id_kanban_column
        const url = values.id_cards ? '/updateCard' : '/createCard'
        axios.post(Constants.APIEndpoints.KANBAN + url, values).then(res => {
            setIdCard(res.data.insertId)
            setOpenCreateNewCard(false)
            setOpenSeeActvity(true)
            getData({ fk_id_salles_funnel: idFunnelSelected })
            getCardActivities(res.data.insertId)

        })
    }

    const deleteFunnel = () => {
        axios.post(Constants.APIEndpoints.KANBAN + '/deletefunnel', { id_salles_funnel: idFunnelSelected }).then(res => {
            getData({ fk_id_salles_funnel: 0 })
            setDeleteFunnelDialog(false)
            getFunnels()
        })
    }
    const getTimeLine = () => {
        setShowTimeLine(true)
        axios.post(Constants.APIEndpoints.KANBAN + "/gettimeline", filters).then(res => {
            setPage('kanban')
            const all_cards = []
            res.data.map(data => {
                all_cards.push(...data.cards)
            })
            setCards(all_cards)
            setKanban(res.data)
        })
    }

    const getSingleLead = (id_cards) => {
        axios.get(Constants.APIEndpoints.KANBAN + "/getsinglelead/" + id_cards).then(res => {
            setSelectedCard(res.data[0])
        })
    }
    return (
        <div style={{ height: '100%' }}>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <div
                style={{ width: '100%', display: 'flex' }}>
                {logged_user.role == 'admin' && showTimeLine == false && (
                    <button className='buttonCreateNewColumn' style={{ minWidth: 10, borderRadius: 20, alignSelf: 'center', maxHeight: 40 }} onClick={() => setOpenCreateColumn(true)}>
                        <AddIcon />
                    </button>
                )}

                <div style={{ width: '80%' }}>
                    <CommonForm
                        fields={[
                            {
                                col: 1.6,
                                name: 'status',
                                label: 'Status',
                                type: 'select',
                                options: [
                                    { value: 1, label: 'Perdas' },
                                    { value: 2, label: 'Ganhos' },
                                    { value: 3, label: 'Em Andamento' },
                                    { value: 4, label: 'Todos' }]
                            },
                            {
                                label: 'Etiquetas',
                                col: 2,
                                name: 'tags',
                                type: 'multiselect',
                                options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color })),

                            },
                            {
                                col: 1.8,
                                name: 'fk_id_user',
                                label: 'Executivo',
                                type: 'select',
                                options: usersOptions

                            },
                            {
                                col: 1.8,
                                name: 'fk_id_vehicle',
                                label: 'Veículo',
                                type: 'select',
                                options: vehicles.map(v => ({ value: v.id_vehicle, label: v.fancy_name }))
                            },
                            {
                                col: 1.8,
                                name: 'typePeriod',
                                label: 'Período',
                                type: 'select',
                                options: [{ value: 1, label: 'Hoje' }, { value: 2, label: 'Esta semana' }, { value: 3, label: 'Este mês' }, { value: 4, label: 'Este ano' }, { value: 5, label: 'Personalizado' }]
                            },
                            {
                                col: 1.5,
                                type: 'date',
                                name: 'dt_start',
                                label: 'De',
                                disabled: filters.typePeriod !== 5
                            },
                            {
                                col: 1.5,
                                type: 'date',
                                name: 'dt_end',
                                label: 'Até',
                                disabled: filters.typePeriod !== 5
                            },
                        ]}
                        values={filters}
                        onChangeField={(f, v) => {
                            let values_ = filters
                            values_[f.name] = v
                            setFilters({ ...values_ })
                        }}

                    /></div>
                <IconButton
                    onClick={() => {
                        showTimeLine == true ? getTimeLine() : getData()
                    }}>
                    <Icon>
                        search
                    </Icon>
                </IconButton>

                <div className='buttonGroup'>
                    <Tooltip title={<h5>Funis de venda</h5>} placement="right">
                        <IconButton
                            onClick={(ev) => {
                                setButtonSelected(1)
                                setOpenFunnelPanel(true)
                            }}
                            className={buttonSelected == 1 ? 'buttonSelected' : ''}
                        >
                            <EditLocationIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<h5>Visualizar como Tabela</h5>} placement="right">
                        <IconButton
                            onClick={(ev) => {
                                setButtonSelected(2)
                                setPage(page == 'kanban' ? 'table' : 'kanban')
                            }}
                            className={buttonSelected == 2 ? 'buttonSelected' : ''}
                        >
                            <AutorenewIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<h5>Exportar Leads</h5>} placement="right">
                        <IconButton
                            onClick={(ev) => {
                                setButtonSelected(3)
                                exportCards()
                            }}
                            className={buttonSelected == 3 ? 'buttonSelected' : ''}
                        >
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<h5>Linha do Tempo</h5>} placement="right">
                        <IconButton
                            onClick={() => {
                                setButtonSelected(4)
                                getTimeLine()
                            }}
                            className={buttonSelected == 4 ? 'buttonSelected' : ''}
                        >
                            <TimelineIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <Typography
                style={{ marginLeft: 30, marginTop: 10 }}
            >{`${totalGrossValueFromCards.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - ${allCards.length} negócios`}</Typography>

            <CommonDialog
                open={openLossCard}
                onClose={() => setOpenLossCard(false)}
                title="Perda"
                width="md"
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            name: 'loss',
                            label: 'Justificativa',
                            type: 'textarea',
                        },
                    ]}
                    onSubmit={lossCard}
                />

            </CommonDialog>


            <CommonDialog
                open={openCreateNewCard}
                onClose={() => setOpenCreateNewCard(false)}
                title="Criar Novo Card"
                width="lg"
                keep={true}
            >
                <CreateCard
                    onSubmit={createNewCard}
                    clients={clients}
                    agencies={agencies}
                    mailings={mailings}
                    fromList={Object.keys(selectedColumn).length > 0}
                    kanban={kanban}
                    card={selectedCard}
                    setSelectedCard={setSelectedCard}
                    getSingleLead={getSingleLead}
                    tags={tags}

                />
            </CommonDialog>
            <CommonDialog
                open={openCreateNewCardWithStep}
                onClose={() => setOpenCreateNewCardWithStep(false)}
                title="Criar Novo Card"
                width="lg"
                keep={true}
            >
                <CreateCardWithStep
                    onSubmit={createNewCard}
                    clients={clients}
                    agencies={agencies}
                    mailings={mailings}
                    kanban={kanban}
                    card={selectedCard}
                    setSelectedCard={setSelectedCard}
                    getSingleLead={getSingleLead}
                    tags={tags}
                    users={users}
                    getData={() => getData({ fk_id_salles_funnel: idFunnelSelected })}
                    values={selectedCard}
                    selectedColumn={selectedColumn}
                    setOpenCreateNewCardWithStep={setOpenCreateNewCardWithStep}
                    setDeleteDialogActivity={setDeleteDialogActivity}
                    vehicles={vehicles}
                    products={products}
                    visuplac={visuplac}
                />
            </CommonDialog>

            {
                deleteDialogActivity ? (
                    <ConfirmDialog title="Deseja deletar esta atividade" cancel={() => setDeleteDialogActivity(false)} confirm={deleteActivity} />
                ) : null
            }
            {
                deleteFunnelDialog ? (
                    <ConfirmDialog title="Deseja deletar este Funil" cancel={() => setDeleteFunnelDialog(false)} confirm={deleteFunnel} />
                ) : null
            }
            {
                concludedDialog ? (
                    <ConfirmDialog title="Qual a Próxima Etapa?"
                        width='sm'
                        options={[
                            { title: 'Dar Ganho', color: 'green', onClick: () => setOpenWinCard(true) },
                            { title: 'Dar Perda', color: 'red', onClick: () => setOpenLossCard(true) },
                            {
                                title: 'Criar Nova Atividade', onClick: () => {
                                    setActivitySelected({ concludedActivity: activitySelected.id_card_activity })
                                    setOpenCreateActvity(true)
                                }
                            },

                        ]}
                    />
                ) : null
            }
            {
                openFunnelPanel ? (
                    <FunnelPanel
                        onClose={() => setOpenFunnelPanel(false)}
                        open={openFunnelPanel}
                        users={users}
                        funnels={funnels}
                        getFunnels={getFunnels}
                        idFunnelSelected={idFunnelSelected}
                        getData={getData}
                        setDeleteFunnelDialog={setDeleteFunnelDialog}
                        setIdFunnelSelected={setIdFunnelSelected}
                    />
                ) : null
            }
            <WinCard
                open={openWinCard}
                onClose={() => {
                    setOpenWinCard(false)
                    setConcludedDialog(false)
                    setOpenSeeActvity(false)
                }
                }
                card={selectedCard}
                getData={() => getData({ fk_id_salles_funnel: idFunnelSelected })}
                id_card_activity={activitySelected.id_card_activity}
            />
            <CommonDialog
                open={openCreateActivity}
                onClose={() => setOpenCreateActvity(false)}
                title='Nova Atividade'
                width='lg'
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 5,
                            type: 'text',
                            name: 'name',
                            label: 'Nome'
                        },
                        {
                            col: 3,
                            type: 'select',
                            name: 'fk_id_user',
                            label: 'Responsável',
                            options: users.map(u => ({ value: u.id_user, label: u.name }))
                        },

                        {
                            col: 4,
                            type: 'checkbox',
                            name: 'sendmail',
                            label: 'Receber email lembrando dessa atividade?'
                        },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_start',
                            label: 'Data de Início'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_start',
                            label: 'Hora de Início'
                        },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_end',
                            label: 'Data de Término'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_end',
                            label: 'Hora de Término'
                        }, {
                            col: 12,
                            type: 'textarea',
                            name: 'description',
                            label: 'Descrição'
                        }
                    ]}
                    onSubmit={saveCardAcvitivy}
                    values={activitySelected}
                    onChangeField={(f, v) => {
                        let v_ = { ...activitySelected }
                        v_[f.name] = v;
                        setActivitySelected(v_)
                    }}
                />
            </CommonDialog>
            <CommonDialog
                open={openCreateColumn}
                onClose={() => setOpenCreateColumn(false)}
                title="Criar Nova Coluna"
                width="xs"
            >
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            name: 'name',
                            label: 'Nome',
                            type: 'text',
                            required: true
                        }
                    ]}
                    onSubmit={createNewKanban}

                />
            </CommonDialog>
            <CommonDialog
                open={openCard}
                onClose={() => setOpenCard(false)}
                title="Visualizar Negócio"
                width="lg"
                fullWidth={true}
            >
                <Visualization
                    visuplac={visuplac}
                    lead={selectedCard}
                    getData={getData}
                    setOpenWinCard={setOpenWinCard}
                    setOpenLossCard={setOpenLossCard}
                    usersOptions={usersOptions}
                />
            </CommonDialog>
            {
                page == 'kanban' ? (
                    <div style={{ height: '79vh', padding: 10, backgroundColor: 'white', margin: '0px -10px' }} className={clsx('flex flex-1 overflow-x-auto overflow-y-hidden')}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="list" type="list" direction="horizontal">
                                {(provided) => (
                                    <div ref={provided.innerRef} className="flex ">
                                        {kanban.map((list, index) => (
                                            <BoardList getData={() => getData({ fk_id_salles_funnel: idFunnelSelected })}
                                                key={list.id_kanban_column}
                                                list={list}
                                                showTimeLine={showTimeLine}
                                                index={index}
                                                columnsOptions={kanban.map(k => ({ value: k.id_kanban_column, label: k.name }))}
                                                usersOptions={usersOptions}
                                                selectedTags={selectedTags}
                                                seeOnlyUserLoggedIn={seeOnlyUserLoggedIn}
                                                tags={tags}
                                                setOpenCreateNewCard={setOpenCreateNewCard}
                                                setOpenCreateNewCardWithStep={setOpenCreateNewCardWithStep}
                                                setSelectedColumn={setSelectedColumn}
                                                setSelectedCard={setSelectedCard}
                                                selectedCard={selectedCard}
                                                getSingleLead={getSingleLead}
                                                setOpenSeeActvity={setOpenSeeActvity}
                                                getCardActivities={getCardActivities}
                                                clients={clients}
                                                agencies={agencies}
                                                setOpenWinCard={setOpenWinCard}
                                                setOpenLossCard={setOpenLossCard}
                                                visuplac={visuplac}
                                                openCard={openCard}
                                                setOpenCard={setOpenCard}
                                            />
                                        ))}
                                        {provided.placeholder}

                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                ) : (
                    <div style={{ height: '79vh', padding: 10, backgroundColor: 'white', margin: '0px -10px' }} className={clsx('flex flex-1 overflow-x-auto overflow-y-hidden')}>

                        <CommonTable
                            miniVersion={true}
                            columns={columns}
                            data={dataTable}
                            noHeader={true}
                            id={'leads_table_id'}
                            onRowClick={(ev, row) => {
                            }}
                        />
                    </div>
                )
            }
        </div >
    )
}