import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core"
import moment from "moment-timezone";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import './ProposalView.css'
const ProposalFinancesView = ({ id_proposals }) => {
    const [proposal, setProposal] = useState([])

    useEffect(() => {
        axios.get(Constants.APIEndpoints.PROPOSAL + '/getproposalfinances/' + id_proposals).then(res => {
            setProposal(res.data)
        })
    }, [])

    return (
        <div>
            <div style={{ marginTop: 60 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} style={{ backgroundColor: 'white' }} >
                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>Valor da Comissão</label>
                                <p className="value">{parseFloat(proposal.comission_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>Valor Recebido</label>
                                <p className="value">{parseFloat(proposal.received_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                        </Grid> <Grid item xs={4}>
                            <div className="cardProposal">
                                <label>Imposto</label>
                                <p className="value">{proposal.taxes}%</p>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cardProposal">
                                <label>VR. Imposto Emissão de NF</label>
                                <p className="value">{parseFloat(proposal.taxes_nf || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cardProposal">
                                <label>Nº NF Representante</label>
                                <p className="value">{proposal.nf_mosaic}</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>Data de Recebimento do Veículo</label>
                                <p className="value">{proposal.dt_received_vehicle ? moment(proposal.dt_received_vehicle).format('DD/MM/YYYY') : ''}</p>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>Data de Recebimento do Mosaico</label>
                                <p className="value">{proposal.dt_received_mosaic ? moment(proposal.dt_received_mosaic).format('DD/MM/YYYY') : ''}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cardProposal">
                                <label>Observações</label>
                                <p className="value" dangerouslySetInnerHTML={{ __html: proposal.observation_finances }}></p>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="cardProposal">
                                <label>Projeto</label>
                                <p className="value">{proposal.project ? 'Sim' : 'Não'}</p>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="cardProposal">
                                <label>Incentivo Pago</label>
                                <p className="value">{proposal.incentive_paid ? 'Sim' : 'Não'}</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>NF Incentivo</label>
                                <p className="value" >{proposal.incentive_nf}</p>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div >
    )
}

export default ProposalFinancesView