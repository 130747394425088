import { motion } from "framer-motion";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import Store from 'app/utils/Store'
import moment from "moment-timezone";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
export default function LogsList(props) {
    const dispatch = useDispatch();
    const user = [];
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0)

    useEffect(() => {
        getData();
    }, []);

    const getData = (filter = {}, limit = 25, page = 0) => {
        let offset = limit * page
        axios.post(`${Constants.APIEndpoints.LOGS}/getlogs?limit=${limit}&offset=${offset}`, { ...filter }).then((res) => {
            const data = res.data.logs.map(r => ({
                dt_cad_: moment(r.dt_cad).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss'),
                action_: r.action,
                ...r
            }))
            setData(data);
            setTotalData(res.data.total)
        });
    };



    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: "id_logs",
                className: "font-medium",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: 'Usuário',
                accessor: "name",
                className: "font-medium",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: "Data",
                accessor: "dt_cad_",
                className: "font-medium",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: "Ação",
                accessor: "action_",
                className: "font-medium",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: "Rota",
                accessor: "endpoint",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: "Método",
                accessor: "method",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: "Payload",
                accessor: "payload",
                sortable: true,
                disableSortBy: true
            },

        ],
        [dispatch, user.starred]
    );


    return (
        <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
        >

            <div>
                <CommonTable
                    id="logs_list"
                    // underHeader={underHeader}
                    columns={columns}
                    totalData={totalData}
                    data={data}
                    noSearch={true}
                    icon="people"
                    pagination={getData}
                    headerTitle="LOGS"
                    onRowClick={(ev, row) => {
                        if (row) {
                            //            dispatch(openEditContactDialog(row.original));
                        }
                    }}
                />
            </div>


        </motion.div>
    );
}

