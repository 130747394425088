import React, { useState, useEffect } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { useResizeDetector } from 'react-resize-detector';

export default function TagsForm({ values, setPage, getData }) {
  const [valuesForm, setValuesForm] = useState(values)
  const { width, height, ref } = useResizeDetector();

  let fields = [
    {
      col: 5,
      type: "text",
      name: "name",
      label: "Nome",
      required: true
    },
    {
      col: 4,
      type: "multiselect",
      name: "roles",
      label: "Acesso",
      options: [
        { value: "admin", label: "Admin" },
        { value: "checking", label: "Checking" },
        { value: "comercial", label: "Comercial" },
        { value: "financeiro", label: "Financeiro" },
        { value: "mailing", label: "Mailing" },
        { value: "opec", label: "Opec" },
        { value: "subadmin", label: "Subadmin" },
        { value: "opecadmin", label: "Opec Admin" },
        { value: "executive", label: "Executivo" },
      ],
      required: true
    },
    {
      col: 3,
      type: "checkbox",
      name: "tag_loss",
      label: "Atribuir automaticamente a Lead após data de vencimento?",
    },
    {
      col: 12,
      type: "color",
      name: "color",
      label: "Cor",
    },


  ];


  const onSubmit = () => {
    axios.post(Constants.APIEndpoints.TAGS + (values.id_tags ? '/updateTag' : '/createTag'), valuesForm).then(res => {
      setPage('list')
      getData();
    }).catch(error => {
      console.log(error)
    })


  }

  return (
    <div ref={ref}>
      <CommonHeader title="Criar Tag"
        width={width}
        onBack={() => setPage('list')} />
      <CommonForm
        hasHeader={true}
        values={valuesForm}
        fields={fields}
        onChangeField={(f, v) => {
          values[f.name] = v;
          setValuesForm(values)
        }}
        onSubmit={onSubmit}
      />

    </div>
  );
}
