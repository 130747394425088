import { motion } from "framer-motion";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import VehicleForm from "./VechileForm";
import axios from 'app/utils/AxiosConfig';

import CommonView from '../../components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";
import Constants from "app/utils/Constants";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
function VehicleList(props) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});
  const [singleVehicle, setSingleVehicle] = useState([])
  const [vehicleDialog, setVehicleDialog] = useState(false)

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [vehicleSelected, setVehicleSelected] = useState([])

  const [squares, setSquares] = useState([])
  const [users, setUsers] = useState([])
  const [groups, setGroups] = useState([])

  useEffect(() => {
    axios.post(
      Constants.APIEndpoints.PROPOSAL + "/getallgeneraldata", {
      entities: ['users', 'squares', 'groups']
    })
      .then((res) => {
        setSquares(res.data.squares)
        setUsers(res.data.users)
        setGroups(res.data.groups)
      })
  }, [])


  const columns = useMemo(
    () => [

      {
        Header: "Nome Fantasia",
        accessor: "fancy_name",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Razão Social",
        accessor: "company_name",
        sortable: true,
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
        sortable: true,
      },
      {
        Header: "Contato",
        accessor: "contact",
        sortable: true,
      },
      {
        Header: "Representante",
        accessor: "sponsor",
        sortable: true,
      },
      {
        Header: "Grupo",
        accessor: "group",
        sortable: true,
      },
      {
        Header: "Carteira",
        accessor: "wallet_name",
        sortable: true,
      },
      {
        Header: "Imposto",
        accessor: "taxes",
        sortable: true,
      },
      {
        Header: "Valor de Repasse",
        accessor: "transfer_value",
        sortable: true,
      },
      {
        Header: "Comissão do Representante",
        accessor: "comission_mosaic",
        sortable: true,
      },
      {
        Header: "Prazo",
        accessor: "time",
        sortable: true,
        Cell: ({ row }) => (
          <div>
            {row.original.time ? moment(row.original.time).format('DD/MM/YYYY') : ''}
          </div>
        )
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {access.vehicle_activate ? (
              <Tooltip title={row.original.active ? 'Desativar' : 'Ativar'}>
                <IconButton
                  onClick={(ev) => {
                    changeVehicleStatus(row.original.id_vehicle, row.original.active);
                  }}
                >
                  <Icon>{row.original.active ? 'check' : 'close'}</Icon>
                </IconButton>
              </Tooltip>
            ) : null}
            {access.vehicle_read ? (
              <IconButton
                onClick={(ev) => {
                  viewVehicle(row.original);
                }}
              >
                <Icon>remove_red_eye</Icon>
              </IconButton>
            ) : null}
            {access.vehicle_update ? (

              <IconButton
                onClick={(ev) => {
                  setValues(row.original)
                  setPage('add')
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            ) : null}
            {access.vehicle_delete ? (

              <IconButton
                onClick={(ev) => {
                  setVehicleSelected(row.original)
                  setDeleteDialog(true)
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const changeVehicleStatus = (id_vehicle, status) => {
    const values = {
      id_vehicle: id_vehicle,
      active: !status
    }
    axios.post(Constants.APIEndpoints.VEHICLE + '/updateVehicle', values).then(res => {
      getData()
    })
  }

  const viewVehicle = (vehicle) => {
    setVehicleDialog(true)
    let data = [
      {
        col: 12,
        label: 'NOME FANTASIA',
        value: vehicle.fancy_name
      },
      {
        col: 12,
        label: 'RAZÃO SOCIAL',
        value: vehicle.company_name
      },
      {
        col: 4,
        label: 'CNPJ',
        value: vehicle.cnpj
      },

      {
        col: 8,
        label: 'CONTATO',
        value: vehicle.contact
      },
      {
        col: 6,
        label: 'TELEFONE',
        value: vehicle.phone
      },
      {
        col: 6,
        label: 'EMAIL',
        value: vehicle.email
      },

      {
        col: 12,
        label: 'ENDEREÇO',
        value: vehicle.address
      },
      {
        col: 6,
        label: 'PRAÇA',
        value: squares.filter(s => s.id_square == vehicle.fk_id_square)[0] ? squares.filter(s => s.id_square == vehicle.fk_id_square)[0].uf : ''
      },

      {
        col: 6,
        label: 'REPRESENTANTE',
        value: vehicle.sponsor
      },
    ]

    setSingleVehicle(data)
  }

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteVehicle = (id) => {
    const data = { id_vehicle: vehicleSelected.id_vehicle }

    axios.post(Constants.APIEndpoints.VEHICLE + "/deleteVehicle", data).then((res) => {
      getData();
      setDeleteDialog(false)
    });
  };

  const getData = () => {
    axios.get(Constants.APIEndpoints.VEHICLE + "/getAllVehicles?all=true").then((res) => {
      setData(res.data);
    });
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >
      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar esse Veículo?" cancel={() => setDeleteDialog(false)} confirm={deleteVehicle} />
      ) : null}

      <CommonDialog
        open={vehicleDialog}
        onClose={() => setVehicleDialog(false)}
        title="Ver Veiculo"
        width="xl"
        print={true}
      >

        <CommonView dialog={true} data={singleVehicle} title="Ver Veiculo" onBack={() => setPage('list')} />

      </CommonDialog>

      {page == "list" ? (
        <CommonTable
          id='vehicle_list'
          columns={columns}
          data={data}
          icon="directions_car"
          newText="Adicionar Novo Veículo"
          onAdd={access.vehicle_create ? onAdd : null}
          headerTitle="Veículos"
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <VehicleForm values={values} setPage={setPage} getData={getData} users={users} groups={groups} squares={squares} />
      )}
    </motion.div>
  );
}

export default VehicleList;
