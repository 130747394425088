import React, { useState, useEffect } from 'react'
import clsx from 'clsx';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';

import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from 'app/utils/Store'
import CommonForm from 'app/components/form/CommonForm';
import { Divider, Typography } from '@material-ui/core';
import '../../newLeads/Leads.css'
import { Input } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        width: 720,
        padding: 15
    },
}));

const SaveFiltersDash = ({
    open,
    onClose,
    onSubmit,
    users,
    teams,
    generalDashSelected,
    setGeneralDashSelected
}) => {

    const classes = useStyles();
    const fields = [
        {
            col: 12,
            type: 'text',
            name: 'name',
            label: 'Nome',
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'users',
            label: 'Usuários para compartilhar',
            options: users.map(v => ({ value: v.id_user, label: v.name })),

        },

        {
            col: 12,
            type: 'multiselect',
            name: 'teams',
            label: 'Times para compartilhar',
            options: teams.map(k => ({ value: k.id_team, label: k.name })),
        },
    ]

    return (
        <SwipeableDrawer
            classes={{ paper: clsx(classes.root) }}
            open={open}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={onClose}
            disableSwipeToOpen
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'sticky', top: 0, backgroundColor: 'white' }}>
                <Typography className='title pointer'>SALVAR</Typography>
            </div>

            <CommonForm
                fields={fields}
                onSubmit={onSubmit}
                values={generalDashSelected}
                onChangeField={(f, v) => {
                    setGeneralDashSelected(prev => ({ ...prev, [f.name]: v }))
                }}
            />

        </SwipeableDrawer>
    )
}

export default SaveFiltersDash