import React, { useState, useEffect } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { useResizeDetector } from 'react-resize-detector';
export default function ProductForm({ values, setPage, getData }) {
  const [valuesForm, setValuesForm] = useState(values)
  const [groups, setGroups] = useState([])
  const { width, height, ref } = useResizeDetector();

  useEffect(() => {
    axios
      .get(
        Constants.APIEndpoints.GROUP + "/getallgroups")
      .then((res) => {
        setGroups(res.data)
      })
  }, [])

  let fields = [
    {
      col: 12,
      type: "text",
      name: "name",
      label: "Nome",
      required: true
    },
  ];

  const onSubmit = () => {
    axios.post(Constants.APIEndpoints.GROUP + (values.id_groups ? '/updateGroup' : '/createGroup'), valuesForm).then(res => {
      setPage('list')
      getData();
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <div ref={ref}>
      <CommonHeader title="Criar Grupo"
        width={width}
        onBack={() => setPage('list')} />
      <CommonForm
        values={valuesForm}
        hasHeader={true}
        fields={fields}
        onChangeField={(f, v) => {
          values[f.name] = v;
          setValuesForm(values)
        }}
        onSubmit={onSubmit}
      />
    </div>
  );
}

