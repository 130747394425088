import React, { useState, useRef, useEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';
import RootRef from '@material-ui/core/RootRef';
import Typography from '@material-ui/core/Typography';
import Store from 'app/utils/Store'
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from 'app/utils/Constants';
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import './Leads.css'
import BoardCard from './BoardCard';
import { Input, InputLabel, Tooltip } from '@material-ui/core';
import { eventBus } from "app/utils/EventListener";

let logged_user = Store.USER

export default function BoardList({
    getData,
    column,
    index,
    setOpenCreateNewCardWithStep,
    setSelectedColumn,
    setSelectedLead,
    selectedLead,
    getSingleLead,
    showTimeLine,
    setOpenLead,
    filters,
    idFunnelSelected
}) {
    const [openColumnEdit, setOpenColumnEdit] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [leads, setLeads] = useState([])
    const [search, setSearch] = useState('')
    const [valuesFromProposals, setValuesFromProposals] = useState([])
    useEffect(() => {
        getLeads(filters, column.id_kanban_column)
    }, [filters])


    useEffect(() => {
        eventBus.on('update-column', (data) => {
            getLeads(filters, column.id_kanban_column)
        })
        eventBus.on('lead-moved', (data) => {
            if (parseInt(data.columnOrigin) == column.id_kanban_column) {
                setLeads(prev => prev.filter(lead => lead.id_leads !== parseInt(data.lead.id_leads)))
            }
            if (parseInt(data.columnDestination) == column.id_kanban_column) {
                setLeads(prev => [...prev, data.lead])
            }
        })
    }, [])

    const getLeads = (filters = {}, id_kanban_column) => {
        if (column.leads) {
            setLeads(column.leads)
            const totalValue = column.leads.reduce((sum, item) => {
                return sum + parseFloat(item.value || 0)
            }, 0)
            const totalProposals = column.leads.reduce((sum, item) => {
                return sum + parseInt(item.total || 0)
            }, 0)
            setValuesFromProposals([{
                value: totalValue,
                total: totalProposals
            }])
        } else {
            const data = {
                id_kanban_column,
                fk_id_salles_funnel: idFunnelSelected,
                ...filters
            }
            axios.post(Constants.APIEndpoints.LEADS + '/getleads', data).then(res => {
                setLeads(res.data.leads)
                setValuesFromProposals(res.data.valuesFromProposals)
            })
        }
    }

    const contentScrollEl = useRef(null);


    const editColumn = (values) => {
        values.fk_id_kanban_column = column.id_kanban_column
        axios.post(Constants.APIEndpoints.KANBAN + "/updateColumn", values).then(res => {
            setOpenColumnEdit(false)
            getData()
        })
    }

    const deleteColumn = () => {
        axios.post(Constants.APIEndpoints.KANBAN + "/deleteColumn", { fk_id_kanban_column: column.id_kanban_column }).then(res => {
            getData()
        })
    }

    const leadsFilters = leads.filter(p => JSON.stringify(p).toLowerCase().indexOf(search.toLowerCase()) > -1)

    const totalNetValueCards = (leadsFilters || []).reduce((sum, nextCard) => {
        return sum + parseFloat(nextCard.value || 0)
    }, 0)
    const totalWinNetValueCards = (leadsFilters || []).filter(c => c.win == true).reduce((sum, nextCard) => {
        return sum + parseFloat(nextCard.value || 0)
    }, 0)
    const totalInProgessNetValueCards = (leadsFilters || []).filter(c => c.win == false && c.loss == false && c.awaiting_win == false).reduce((sum, nextCard) => {
        return sum + parseFloat(nextCard.value || 0)
    }, 0)

    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <Draggable draggableId={'column' + column.id_kanban_column} index={index} type="list">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                        className='listCards'
                    >
                        <Card
                            className={clsx(
                                snapshot.isDragging ? 'shadow-lg' : 'shadow',
                                'w-256 sm:w-320 mx-8 sm:mx-12 max-h-full flex flex-col rounded-20'
                            )}
                            square
                        >
                            <div style={{ display: 'flex', borderBottom: '1px solid', backgroundColor: '#e7e7e7', maxHeight: 35 }}>

                                <Typography className="titleColumn">{column.name}</Typography>
                                <div style={{ display: 'flex' }}>
                                    {logged_user.role == 'admin' && (
                                        <IconButton
                                            onClick={(ev) => {
                                                setOpenColumnEdit(true)
                                            }}
                                            style={{ width: 10 }}
                                        >
                                            <Icon style={{ color: 'black' }}>edit</Icon>
                                        </IconButton>
                                    )}

                                    {logged_user.role == 'admin' && (
                                        <IconButton
                                            onClick={(ev) => {
                                                setDeleteDialog(true)
                                            }}
                                            style={{ width: 10 }}

                                        >
                                            <Icon style={{ color: 'black' }}>delete</Icon>
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                            <Input
                                className='inputSearch'
                                placeholder={'Pesquisar'}
                                onChange={evt => setSearch(evt.target.value)}
                            />
                            <Tooltip title={<h5>Total</h5>} placement="right">
                                <div className="cardTotalValueAndBusiness">
                                    <Icon>
                                        dashboard
                                    </Icon>
                                    <Typography>{`${totalNetValueCards.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - ${(leadsFilters || []).length} leads`}</Typography>
                                </div>
                            </Tooltip>
                            <Tooltip title={<h5>Ganhos</h5>} placement="right">
                                <div className="cardTotalWinValueAndBusiness">
                                    <Icon>
                                        check_circle_outline
                                    </Icon>
                                    <Typography>{`${totalWinNetValueCards.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - ${(leadsFilters || []).filter(c => c.win == true).length} leads`}</Typography>
                                </div>
                            </Tooltip>
                            <Tooltip title={<h5>Em Andamento</h5>} placement="right">

                                <div className="cardTotalProgressValueAndBusiness">
                                    <Icon>
                                        hourglass_empty
                                    </Icon>
                                    <Typography>{`${totalInProgessNetValueCards.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - ${(leadsFilters || []).filter(c => c.win == false && c.loss == false && c.awaiting_win == false).length} leads`}</Typography>
                                </div>
                            </Tooltip>
                            <Tooltip title={<h5>Propostas Ganhas</h5>} placement="right">

                                <div className="cardTotalProposalsLeads">
                                    <Icon>
                                        handyman
                                    </Icon>
                                    <Typography>{`${parseFloat(valuesFromProposals[0]?.value ?? 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} - ${valuesFromProposals[0]?.total ?? 0} propostas`}</Typography>
                                </div>
                            </Tooltip>
                            <RootRef rootRef={contentScrollEl}>
                                <CardContent className="flex flex-col flex-1 flex-auto h-full min-h-0 w-full p-0 overflow-auto">
                                    <Droppable droppableId={'column' + column.id_kanban_column} type="card" direction="vertical">
                                        {(_provided) => (
                                            <div ref={_provided.innerRef} className="flex flex-col w-full h-full p-8">


                                                {Array.from(leadsFilters || []).map((lead, index) => (
                                                    <BoardCard
                                                        getLeads={getLeads}
                                                        lead={lead}
                                                        column={column}
                                                        index={index}
                                                        selectedLead={selectedLead}
                                                        setSelectedLead={setSelectedLead}
                                                        getSingleLead={getSingleLead}
                                                        setOpenCreateNewCardWithStep={setOpenCreateNewCardWithStep}
                                                        setOpenLead={setOpenLead}
                                                    />
                                                ))}
                                                {_provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </CardContent>
                            </RootRef>
                            {column.id_kanban_column == 0 || showTimeLine == true ? null : (
                                <CardActions className="p-0 flex-shrink-0">
                                    <div className='divCreateNewCard' onClick={() => {
                                        setSelectedLead({})
                                        setSelectedColumn(column)
                                        setOpenCreateNewCardWithStep(true)

                                    }

                                    }>Novo card</div>
                                </CardActions>
                            )}
                        </Card>
                    </div>
                )}
            </Draggable>


            <CommonDialog
                open={openColumnEdit}
                onClose={() => setOpenColumnEdit(false)}
                title="Editar Coluna"
                width="xl"
            >
                <CommonForm
                    values={column}
                    fields={[
                        {
                            col: 12,
                            name: 'name',
                            label: 'Nome',
                            type: 'text',
                            required: true
                        }

                    ]}
                    onSubmit={editColumn}
                    onChangeField={(f, v) => {
                        column.name = v
                    }}
                />

            </CommonDialog>
            {deleteDialog ? (
                <ConfirmDialog title="Deseja deletar esta coluna (isso apagará todos os cards dentro dela)?" cancel={() => setDeleteDialog(false)} confirm={deleteColumn} />
            ) : null}

        </div>
    )
}