import Constants from "./Constants";
const DownloadFiles = (filepath, filename) => {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token');

    fetch(Constants.APIEndpoints.FILES + '/downloadfiles',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': token
            },
            body: JSON.stringify({
                filepath: filepath,
            })
        }).then(results => {
            return results.blob()
        }).then(response => {
            let data = response;
            let elm = document.createElement('a');
            elm.href = URL.createObjectURL(data);
            elm.setAttribute('download', filename);
            elm.click();
            elm.remove();
        })
}

export default DownloadFiles