import { motion } from "framer-motion";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import UserForm from "./UserForm";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import CommonView from '../../components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";
import moment from "moment-timezone";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import CommonForm from "app/components/form/CommonForm";
import { ToastContainer, toast } from 'react-toastify';

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}

function UserList(props) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});

  const [singleUser, setSingleUser] = useState([])
  const [userDialog, setUserDialog] = useState(false)


  const [deleteDialog, setDeleteDialog] = useState(false)

  const [resetPassDialog, setResetPassDialog] = useState(false)
  const [password, setPassword] = useState([])

  let [userSelected, setUserSelected] = useState([])

  const columns = useMemo(
    () => [
      {
        accessor: "avatar",
        Cell: ({ row }) => {
          return (
            <Avatar
              className="mx-8"
              alt={row.original.name}
              src={row.original.profile_pic}
            />
          );
        },
        className: "justify-center",
        width: 64,
        sortable: false,
        noSearchable: true
      },
      {
        Header: "Nome",
        accessor: "name",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Local",
        accessor: "place",
        sortable: true,
      },
      {
        Header: "Email",
        accessor: "email",
        sortable: true,
      },
      {
        Header: "Telefone",
        accessor: "phone",
        sortable: true,
      },
      {
        Header: "Regra",
        accessor: "fk_id_role",
        sortable: true,
      },
      {
        Header: "Accesso",
        accessor: "access",
        sortable: true,
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton
              onClick={(ev) => {
                sendMail(row.original.id_user)
              }}
            >
              <Icon>mail</Icon>
            </IconButton>
            {access.user_changepass ? (
              <IconButton
                onClick={(ev) => {
                  setResetPassDialog(true);
                  setUserSelected(row.original)
                }}
              >
                <Icon>security</Icon>
              </IconButton>
            ) : null}
            {access.user_read ? (

              <IconButton
                onClick={(ev) => {
                  viewUser(row.original);
                }}
              >
                <Icon>remove_red_eye</Icon>
              </IconButton>
            ) : null}
            {access.user_update ? (

              <IconButton
                onClick={(ev) => {
                  getSingleUser(row.original.id_user)
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            ) : null}

            {access.user_delete ? (
              <IconButton
                onClick={(ev) => {
                  setUserSelected(row.original)
                  setDeleteDialog(true)
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}

          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const sendMail = (id_user) => {
    axios.post(Constants.APIEndpoints.USER + '/sendemailcredentials', { id_user }).then(() => {
      toast('E-mail enviado com sucesso', { type: toast.TYPE.SUCCESS })
    })
  }
  const viewUser = (user) => {
    setUserDialog(true)
    let data = [
      {
        col: 12,
        label: 'NOME',
        value: user.name || ''
      },
      {
        col: 12,
        label: 'EMAIL',
        value: user.email
      },
      {
        col: 12,
        label: 'TELEFONE',
        value: user.phone
      },

      {
        col: 12,
        label: 'LOCAL',
        value: user.place != "undefined" ? user.place : ''
      },
      {
        col: 12,
        label: 'REGRA',
        value: user.fk_id_role
      },
      {
        col: 12,
        label: 'DATA DE NASCIMENTO',
        value: moment(user.dt_birthday).format('DD/MM/YYYY')
      },


    ]

    setSingleUser(data)
  }

  const getSingleUser = (id) => {
    axios.get(Constants.APIEndpoints.USER + '/getsingleuser/' + id).then(res => {
      setValues(res.data)
      setPage('add')
    })
  }

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteUser = (id) => {
    const data = { id_user: userSelected.id_user }
    axios.post(Constants.APIEndpoints.USER + "/deleteUser", data).then((res) => {
      setDeleteDialog(false)
      getData();
    });
  };

  const getData = () => {
    axios.get(Constants.APIEndpoints.USER + "/getAllUsers").then((res) => {
      setData(res.data[0]);
    });
  };

  const resetPassword = (password) => {
    const values = { id_user: userSelected.id_user, password: password.password }
    axios.post(Constants.APIEndpoints.USER + '/resetPassword', values).then(res => {
      setResetPassDialog(false)
    })
  }


  if (!data) {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >
      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar esse Usuário?" cancel={() => setDeleteDialog(false)} confirm={deleteUser} />
      ) : null}

      <CommonDialog
        open={userDialog}
        onClose={() => setUserDialog(false)}
        title="Ver Usuário"
        width="xl"
        print={true}
      >
        <CommonView dialog={true} data={singleUser} title="Ver Usuário" onBack={() => setPage('list')} />

      </CommonDialog>


      <CommonDialog
        open={resetPassDialog}
        onClose={() => setResetPassDialog(false)}
        title="Resetar a senha"
        width="xl"
      >
        <CommonForm
          values={password}
          fields={[
            {
              col: 12,
              type: "password",
              name: "password",
              label: "Senha",

            }
          ]}
          onChangeField={(f, v) => {
            password[f.name] = v;
            setPassword(values)
          }}
          onSubmit={resetPassword}
        />
      </CommonDialog>
      <ToastContainer
        position="bottom-right"
        autoClose={4500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      {page == "list" ? (
        <CommonTable
          id="user_list"
          columns={columns}
          data={data}
          icon="account_box"
          newText="Adicionar novo usuário"
          onAdd={access.user_create ? onAdd : null}
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <UserForm values={values} setPage={setPage} getData={getData} />
      )}
    </motion.div>
  );
}

export default UserList;
