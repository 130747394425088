
//const ROOT = `http://koi.sysdrive.com.br:3003/api/`
//const HOST = `http://koi.sysdrive.com.br:3003/`
const ROOT = `https://koi.sysdrive.com.br:3003/api/`
const HOST = `https://koi.sysdrive.com.br:3003/`

const ENDPOINTS = {
    USER: ROOT + 'user',
    VEHICLE: ROOT + 'vehicle',
    CLIENT: ROOT + 'client',
    AGENCY: ROOT + 'agency',
    MAILING: ROOT + 'mailing',
    PRODUCT: ROOT + 'product',
    SQUARE: ROOT + 'square',
    STATUS: ROOT + 'status',
    PROPOSAL: ROOT + 'proposal',
    AUTH: ROOT + 'login',
    NOTIFICATION: ROOT + 'notification',
    FILES: ROOT + 'files',
    KANBAN: ROOT + 'kanban',
    TAGS: ROOT + 'tag',
    TEAM: ROOT + 'team',
    GOALS: ROOT + 'goals',
    PARAMS: ROOT + 'params',
    REPORT: ROOT + 'report',
    DASHBOARD: ROOT + 'dashboard',
    FINANCE: ROOT + 'finance',
    HOLIDAY: ROOT + 'holiday',
    BREAKEVEN: ROOT + 'breakeven',
    STANDARD: ROOT + 'standard',
    GROUP: ROOT + 'group',
    EVENTS: ROOT + 'events',
    ACCESS: ROOT + 'access',
    LOGS: ROOT + 'logs',
    HOME: ROOT + 'home',
    SOLICITATION: ROOT + 'solicitation',
    QA: ROOT + 'qa',
    QUESTIONS: ROOT + 'questions',
    JUSTIFY: ROOT + 'justify',
    LEADS: ROOT + 'leads'
}

export default {
    APIRoot: ROOT,
    APIEndpoints: ENDPOINTS,
    HOST: HOST
}
