import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import '../configuration.css'
import Store from 'app/utils/Store';

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const Formula = ({ saveFormula, oldFormula }) => {


    const [formula, setFormula] = useState('');
    const [editMode, setEditMode] = useState(true)
    useEffect(() => {
        if (oldFormula[0] && oldFormula[0].formula) {
            setFormula(oldFormula[0].formula)
        }
    }, [oldFormula])
    const [, drop] = useDrop({
        accept: ['FIELD', 'OPERATOR', 'NUMBER'],
        drop: (item) => {
            setFormula((prevFormula) => prevFormula + ` ${item.label} `);
        },
    });

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="buttonSubmit" onClick={() => setEditMode(!editMode)}>{editMode == true ? 'Editar' : 'Bloquear'}</button>
                {access.config_params && (<button className="buttonSubmit" onClick={() => saveFormula({ formula: formula })}>Salvar</button>)}
            </div>
            <div ref={drop} className="gridFormula"
            >
                <textarea style={{
                    height: '45rem',
                    width: '80rem'
                }} disabled={editMode} value={formula} onChange={(evt) => setFormula(evt.target.value)}></textarea>
            </div></div>
    );
};

export default Formula;
