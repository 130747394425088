import React from 'react'
import { Box, Divider, Grid } from "@material-ui/core"
import moment from "moment-timezone";
import './ProposalView.css'
const ProposalInfo = ({ proposal }) => {

    const monthList = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];
    return (
        <div>
            <div style={{ marginTop: 60 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} style={{ backgroundColor: 'white' }} >
                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>CLIENTE</label>
                                <p className="value">Nome: <strong>{proposal.client_name}</strong></p>
                                <p className="value">Razão Social: <strong>{proposal.client_company_name}</strong></p>
                                <p className="value">CNPJ: <strong>{proposal.client_cnpj}</strong></p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>AGÊNCIA</label>
                                <p className="value">Nome: <strong>{proposal.agency_name}</strong></p>
                                <p className="value">Razão Social: <strong>{proposal.agency_company_name}</strong></p>
                                <p className="value">CNPJ: <strong>{proposal.agency_cnpj}</strong></p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>VEÍCULO</label>
                                <p className="value">Nome: <strong>{proposal.vehicle_name}</strong></p>
                                <p className="value">Razão Social: <strong>{proposal.vehicle_company_name}</strong></p>
                                <p className="value">CNPJ: <strong>{proposal.vehicle_cnpj}</strong></p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>NEGÓCIO</label>
                                <p className="value">N° PI/PP: <strong>{proposal.number}</strong></p>
                                <p className="value">DT. GANHO: <strong>{moment(proposal.dt_emission).format('DD/MM/YYYY')}</strong></p>
                                <p className="value">DT. CRIAÇÃO: <strong>{moment(proposal.dt_cad).format('DD/MM/YYYY')}</strong></p>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cardProposal">
                                <label>CAMPANHA</label>
                                <p className="value">{proposal.campaign}</p>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cardProposal">
                                <label>PRAÇA</label>
                                <p className="value">{proposal.square}</p>
                            </div>
                        </Grid> <Grid item xs={4}>
                            <div className="cardProposal">
                                <label>MÊS DE VEICULAÇÃO</label>
                                <p className="value">{monthList[proposal.month_placement]}</p>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cardProposal">
                                <label>PI INTERNO</label>
                                <p className="value">{proposal.id_proposals}</p>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cardProposal">
                                <label>USUÁRIO</label>
                                <p className="value">{proposal.user}</p>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cardProposal">
                                <label>RESPONSÁVEL</label>
                                <p className="value">{proposal.responsable}</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="cardProposal">
                                <label>ANOTAÇÕES</label>
                                <p className="value" dangerouslySetInnerHTML={{ __html: proposal.observation }}></p>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="cardProposal">
                                <label>NOTA FISCAL</label>
                                <p className="value">{proposal.nf}</p>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="cardProposal">
                                <label>DATA DE INSERÇÃO DA NOTA FISCAL</label>
                                <p className="value">{proposal.dt_cad_nf ? moment(proposal.dt_cad_nf).format('DD/MM/YYYY HH:mm:ss') : ''}</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cardProposal">
                                <div>
                                    <label>VALORES DO NEGÓCIO</label>

                                    <table style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ color: "black" }}>DESC. PADRÃO%</th>
                                                <th style={{ color: "black" }}>VALOR BRUTO DA APROVADO</th>
                                                <th style={{ color: "black" }}>DESC. PADRÃO DA APROVADO</th>
                                                <th style={{ color: "black" }}>VALOR LÍQUIDO DA APROVADO</th>
                                                <th style={{ color: "black" }}>VALOR BRUTO FATURADO </th>
                                                <th style={{ color: "black" }}>DESC. PADRÃO FATURADO</th>
                                                <th style={{ color: "black" }}>VALOR LÍQUIDO FATURADO</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr style={{ color: "black" }}>
                                                <td className="table_td">{proposal.standard_discount}</td>
                                                <td className="table_td">{parseFloat(proposal.gross_value_proposal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                <td className="table_td">{parseFloat(proposal.standard_discount_proposal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                <td className="table_td">{parseFloat(proposal.net_value_proposal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                <td className="table_td">{parseFloat(proposal.approved_gross_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                <td className="table_td">{parseFloat(proposal.standard_discount_approved).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                <td className="table_td">{parseFloat(proposal.net_value_approved).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div >
    )
}

export default ProposalInfo