import React from "react";
import axios from 'app/utils/AxiosConfig';
import Constants from 'app/utils/Constants';
import { eventBus } from "app/utils/EventListener";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const WinCard = ({
    open,
    onClose,
    card,
    getData,
}) => {
    const createNewBusiness = (values) => {
        axios.post(Constants.APIEndpoints.LEADS + '/finishlead', { win: true, fk_id_leads: card.id_leads }).then(res => {
            onClose()
            eventBus.emit('won-modal-card', {})
            eventBus.emit('update-column', {})
        }).catch(() => {
            toast.error("Ainda existem propostas em andamento, necessário finalizá-las primeiro");
        })
    }
    return (
        <div>

            {
                open ? (
                    <ConfirmDialog title="Deseja dar ganho?" cancel={onClose} confirm={createNewBusiness} />
                ) : null
            }

        </div>
    )
}
export default WinCard