import axios from 'axios';

const instance = axios.create({
    timeout: 150000,
});

const token = sessionStorage.getItem('token') || localStorage.getItem('token');

instance.interceptors.request.use((config) => {
    config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Access-Token': token
    }
    return config;
});

instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            sessionStorage.removeItem('user')
            localStorage.removeItem('user')
            sessionStorage.removeItem('token')
            localStorage.removeItem('token')
            window.location = '/'
        }
        return Promise.reject(error);
    }
);

export default instance;