import React, { useState, useEffect } from 'react'
import clsx from 'clsx';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';

import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from 'app/utils/Store'
import CommonForm from 'app/components/form/CommonForm';
import { Divider, Typography } from '@material-ui/core';
import '../Leads.css'
import { Input } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        width: 720,
        padding: 15
    },
}));

const ListAgenciesLeads = ({
    open,
    onClose,
    selectItem,
    clients,
    agencies,
    noCreate = false
}) => {

    const classes = useStyles();
    const [page, setPage] = useState('list')
    const [search, setSearch] = useState('')

    const saveAgency = (values) => {
        values.approve = true
        axios.post(Constants.APIEndpoints.AGENCY + '/createAgency', values).then(res => {
            setPage('list')
            selectItem(res.data.insertId, true)
        })

    }
    const fields = [
        {
            col: 12,
            type: "text",
            name: "fancy_name",
            label: "Nome Fantasia",
            required: true

        },
        {
            col: 6,
            type: "text",
            name: "company_name",
            label: "Razão Social",
            required: true
        },
        {
            col: 6,
            type: "text",
            name: "cnpj",
            label: "CNPJ",
            format: '##.###.###/#####-##',

        },

        {
            col: 4,
            type: "text",
            name: "contat",
            label: "Contato",
        },
        {
            col: 4,
            type: "text",
            name: "phone",
            label: "Telefone",
        },

        {
            col: 4,
            type: "text",
            name: "email",
            label: "Email",
        },
        {
            col: 12,
            type: "text",
            name: "address",
            label: "Endereço",
        },
        {
            col: 6,
            type: "multiselect",
            name: "fk_id_client",
            label: "Clientes",
            options: clients.map(v => {
                return {
                    value: v.id_client,
                    label: v.fancy_name
                }
            }),
        },

    ];

    const agenciesFiltered = agencies.filter(p => JSON.stringify(p).toLowerCase().indexOf(search.toLowerCase()) > -1)
    return (
        <SwipeableDrawer
            classes={{ paper: clsx(classes.root) }}
            open={open}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={onClose}
            disableSwipeToOpen
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'sticky', top: 0, backgroundColor: 'white' }}>
                <Typography className='title pointer' onClick={() => setPage('list')}> AGÊNCIAS</Typography>
                {!noCreate && (<Typography className='title pointer' onClick={() => setPage('form')}>CRIAR</Typography>)}
            </div>
            {page == 'list' ? (
                <>
                    <Input className='searchClientsInput'
                        disableUnderline={true}
                        onChange={(evt) => setSearch(evt.target.value)}
                        placeholder='Buscar' />
                    <div style={{ overflowY: 'auto', marginTop: 30 }}>
                        {agenciesFiltered.map(agency => (
                            <div className='clientItemList'
                                onClick={() => selectItem(agency.id_agency)}>
                                <Typography>{agency.fancy_name}</Typography>
                                <Typography>{agency.company_name} - {agency.cnpj}</Typography>
                                <Divider />
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <CommonForm
                    fields={fields}
                    onSubmit={saveAgency}
                />
            )}
        </SwipeableDrawer>
    )
}

export default ListAgenciesLeads