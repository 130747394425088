import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import DownloadFiles from "app/utils/DownloadFiles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
const ProposalFiles = ({
    proposal,

}) => {
    const [filesChecking, setFilesChecking] = useState([])
    const [filesMaterial, setFilesMaterial] = useState([])
    const [filesPP, setFilesPP] = useState([])


    useEffect(() => {
        getFiles()
    }, [])
    const getFiles = () => {
        const notification = toast("Buscando arquivos");
        axios
            .get(
                Constants.APIEndpoints.PROPOSAL + '/getfiles/' + proposal.id_proposals
            )
            .then((res) => {
                toast.dismiss(notification)
                setFilesChecking(res.data.file_checking)
                setFilesMaterial(res.data.file_material)
                setFilesPP(res.data.file_pp)
            })
    }

    const downFiles = (filepath, filename) => {
        DownloadFiles(filepath, filename)
    }

    return (
        <div>
            <div style={{ marginTop: 60 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} style={{ backgroundColor: 'white' }} >

                        <Grid item xs={12}>
                            <div className="cardProposal">
                                <label>ARQUIVOS CHECKING</label>
                                {filesChecking.map(file => (
                                    <div>
                                        <p className="text_value_files" onClick={() => downFiles(file.filepath, file.name)} style={{ cursor: 'pointer' }}>{file.name} - {moment(file.dt_cad).format('DD/MM/YYYY HH:mm:ss')}</p>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cardProposal">
                                <label>ARQUIVOS MATERIAL</label>
                                {filesMaterial.map(file => (
                                    <div>
                                        <p className="text_value_files" onClick={() => downFiles(file.filepath, file.name)} style={{ cursor: 'pointer' }}>{file.name} - {moment(file.dt_cad).format('DD/MM/YYYY HH:mm:ss')}</p>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cardProposal">
                                <label>ARQUIVOS PP</label>
                                {filesPP.map(file => (
                                    <div>
                                        <p className="text_value_files" onClick={() => downFiles(file.filepath, file.name)} style={{ cursor: 'pointer' }}>{file.name} - {moment(file.dt_cad).format('DD/MM/YYYY HH:mm:ss')}</p>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
        </div>
    )
}

export default ProposalFiles