import React, { useEffect, useState } from "react"
import axios from 'app/utils/AxiosConfig';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone'
import Constants from "app/utils/Constants";
import CommonTable from "app/components/table/CommonTable";
import CommonForm from "app/components/form/CommonForm";
import { ToastContainer, toast } from 'react-toastify';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Tooltip } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";

const DailyReport = () => {
    const [data, setData] = useState([])
    const [values, setValues] = useState(
        {
            type: [1],
            from: moment().startOf('M').format('YYYY-MM-DD'),
            ate: moment().format('YYYY-MM-DD'),
        }
    )
    useEffect(() => {
        getData(values)
    }, [])

    const getData = (filters = {}) => {
        axios.post(Constants.APIEndpoints.REPORT + '/dailyreport', filters).then(res => {
            setData(res.data)
        })
    }

    const exportData = () => {
        const file = `export_diário-${moment().format('DDMMYYYY_HH:mm:ss')}.pdf`
        const token = sessionStorage.getItem('token');
        const notification = toast("Gerando Relatório");
        fetch(Constants.APIEndpoints.REPORT + '/exportdailyreport',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                body: JSON.stringify(values)
            }).then(results => {
                return results.blob()
            }).then(response => {
                saveAs(response, file);
                toast.dismiss(notification)
            })
    }

    const underHeader = (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '90%' }}>
                <CommonForm
                    values={values}
                    fields={[
                        {
                            col: 4,
                            type: 'multiselect',
                            name: 'type',
                            label: 'Tipo',
                            options: [{ value: 1, label: 'VENDAS' }, { value: 2, label: 'RECEBIDO' }, { value: 3, label: 'A RECEBER' }]
                        },
                        {
                            col: 4,
                            type: 'date',
                            name: 'from',
                            label: 'De',
                        },
                        {
                            col: 4,
                            type: 'date',
                            name: 'ate',
                            label: 'Até',
                        },
                    ]}
                    onChangeField={(f, v) => {
                        let v_ = { ...values }
                        v_[f.name] = v;
                        getData(v_)
                        setValues(v_)
                    }}
                />
            </div>
            <Tooltip title={<h5>Exportar Dados</h5>} placement="right">
                <IconButton
                    onClick={(ev) => {
                        exportData()
                    }}

                >
                    <PictureAsPdfIcon />
                </IconButton>
            </Tooltip>
        </div>
    )

    const cols = [
        {
            Header: 'Dia',
            accessor: 'date',
            className: 'font-medium',
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {moment(row.original.date).format('DD/MM/YYYY')}
                </div>
            )
        },
        {
            Header: 'Tipo',
            accessor: 'type',
            className: 'font-medium',
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {row.original.type == 1 ? 'VENDAS' : row.original.type == 2 ? 'RECEBIDO' : 'A RECEBER'}
                </div>
            )
        },
        {
            Header: 'MOSAICO GERAL',
            accessor: 'mosaico_geral',
            className: 'font-medium',
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {parseFloat((row.original.mosaico_geral || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
            )
        },
        {
            Header: 'MOSAICO BSB',
            accessor: 'mosaico_bsb',
            className: 'font-medium',
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {parseFloat((row.original.mosaico_bsb || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
            )
        },
        {
            Header: 'MOSAICO SPO',
            accessor: 'mosaico_spo',
            className: 'font-medium',
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {parseFloat((row.original.mosaico_spo || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
            )
        },
        {
            Header: 'MOSAICO RIO',
            accessor: 'mosaico_rio',
            className: 'font-medium',
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {parseFloat((row.original.mosaico_rio || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
            )
        },
        {
            Header: 'MOSAICO PRAÇAS',
            accessor: 'mosaico_pracas',
            className: 'font-medium',
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {parseFloat((row.original.mosaico_pracas || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
            )
        },
        {
            Header: 'ADTARGET',
            accessor: 'adtarget',
            className: 'font-medium',
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {parseFloat((row.original.ad_target || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
            )
        },
        {
            Header: 'DIGITAIS',
            accessor: 'digitais',
            className: 'font-medium',
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {parseFloat((row.original.digitais || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
            )
        },

    ]
    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <CommonTable
                id={'id_daily_report'}
                data={data}
                columns={cols}
                underHeader={underHeader}
            />
        </div>
    )
}

export default DailyReport