import React from 'react';
import { useDrag } from 'react-dnd';
import '../configuration.css'
import Tooltip from '@mui/material/Tooltip';

const Operator = ({ label, description }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'OPERATOR',
        item: { label },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <Tooltip title={<h4>{description || ''}</h4>} placement="right-start">
            <div
                ref={drag}
                className={`operatorFormula_${isDragging ? 'dragging' : ''}`}
            >
                {label}
            </div>
        </Tooltip>
    );
};

export default Operator;
