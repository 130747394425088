import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import './Contacts.css'
import Store from "app/utils/Store";
import moment from 'moment-timezone';
let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const ContactListItem = ({
    contact,
    setRightSidebarView,
    setSingleContact
}) => {
    return (
        <>
            <ListItem
                className="px-32 py-16 listItem"
                sx={{ bgcolor: 'background.paper' }}
                onClick={() => {
                    access.mailing_read &&
                        setSingleContact(contact)

                    access.mailing_read &&
                        setRightSidebarView(true)

                }}
                style={{
                    maxHeight: 70
                }}
            >
                <ListItemAvatar>
                    <Avatar alt={contact.name} src={contact.photo} />
                </ListItemAvatar>
                <ListItemText
                    classes={{ root: 'm-0', primary: 'font-medium leading-5 truncate contactItem' }}
                    primary={contact.name}
                    secondary={
                        <>
                            <Typography
                                className="inline contactItem"
                                component="span"
                                variant="body2"
                                style={{ marginTop: -10 }}
                                color="text.secondary"
                            >
                                {contact.place} - {contact.dt_birthday ? moment(contact.dt_birthday).format('DD/MM/YYYY') : ''}
                            </Typography>
                        </>
                    }
                />
            </ListItem>
            <Divider />
        </>
    );
}

export default ContactListItem;
