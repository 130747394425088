import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Divider } from '@material-ui/core';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import moment from 'moment-timezone'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from 'app/utils/Store'
import CommonForm from 'app/components/form/CommonForm';

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        width: 720,
        padding: 15
    },
}));


function CreateWorkflow
    ({
        open,
        onClose,
        workflowSelected,
        setWorkflowSelected,
        getWorkflows,
        status

    }) {
    const classes = useStyles();

    const saveWorkflow = (values) => {
        const url = values.id_workflows ? '/updateworkflow' : '/createworkflow'
        axios.post(Constants.APIEndpoints.STATUS + url, values).then(res => {
            getWorkflows()
            onClose()
        })
    }

    if (workflowSelected?.step) {
        workflowSelected.step = workflowSelected.step.toString()
    }

    return (

        <SwipeableDrawer
            classes={{ paper: clsx(classes.root) }}
            open={open}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={onClose}
            disableSwipeToOpen
        >
            <div className="flex flex-col space-y-32">
                <CommonForm
                    values={workflowSelected}
                    onChangeField={(f, v) => {
                        const newWorkflow = { ...workflowSelected }
                        newWorkflow[f.name] = v
                        setWorkflowSelected(newWorkflow)
                    }}
                    fields={[
                        {
                            col: 12,
                            name: 'fk_id_status',
                            label: 'Status',
                            type: 'select',
                            options: status.map(st => {
                                return {
                                    value: st.id_status,
                                    label: st.name,
                                    color: 'black'
                                }
                            })
                        },
                        {
                            col: 12,
                            name: 'step',
                            label: 'Etapa',
                            type: 'number',
                            required: true
                        },
                        {
                            col: 12,
                            name: 'consider_date',
                            label: 'Data a considerar',
                            type: 'select',
                            required: true,
                            options: [
                                { label: 'Data da mudança de status', value: 1 },
                                { label: 'Data de ganho', value: 2 },
                                { label: 'Data de recebimento do veículo', value: 3 },
                                { label: 'Data de recebimento do representante', value: 4 },
                            ]
                        },
                        {
                            col: 12,
                            name: 'send_mail',
                            label: 'Enviar Email',
                            type: 'checkbox',
                        },


                    ]}
                    onSubmit={saveWorkflow}
                />
            </div>
        </SwipeableDrawer >
    );
}

export default CreateWorkflow
