import React, { useState } from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import "./index.css";
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";

const FilterColumnsByHeader = ({
    filterColuns,
    columnsSelected,
    columnsTable,
    filterColumnsHeader,
}) => {
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const onSubmitColumns = (values) => {
        filterColuns(values)
        setOpenEditDialog(false)
    }

    if (filterColumnsHeader !== undefined) {
        columnsTable = columnsTable.filter(column => column.filter == filterColumnsHeader || column.filter == undefined)
    }

    return (
        <div>
            <IconButton
                style={{ color: 'white', margin: 'auto' }}
                onClick={(ev) => {
                    setOpenEditDialog(true)
                }}
            >
                <Icon>edit</Icon>
            </IconButton>

            {openEditDialog && (
                <CommonDialog
                    open={true}
                    onClose={() => setOpenEditDialog(false)}
                    title="Selecionar colunas"
                >
                    <CommonForm
                        fields={[
                            {
                                col: 12,
                                name: 'columns',
                                type: 'listCheckbox',
                                label: 'Colunas',
                                options: columnsTable.filter(f => typeof f.Header == 'string').map(c => ({ value: c.id, label: c.Header }))
                            }
                        ]}
                        values={{ columns: columnsSelected }}
                        onSubmit={onSubmitColumns}

                    />

                </CommonDialog>
            )}
        </div>
    )
}

export default FilterColumnsByHeader