import React, { useState } from "react"
import Info from "./Info"
import ListProposalLeads from '../Proposals/ListProposalLeads';
import LeadsActivities from "./LeadsActivities";

const Visualization = ({
    visuplac,
    lead,
    getData,
    setOpenWinCard,
    setOpenLossCard,
    users,
    vehicles,
    agencies,
    getSingleLead,
    usersOptions }) => {
    const [page, setPage] = useState(0)
    return (
        <div style={{ height: 500 }}>
            <div className="btnHeaders">
                <div
                    onClick={() => setPage(0)}
                    style={{
                        backgroundColor: page == 0 ? 'rgb(223 223 223)' : '',
                        borderRadius: 10
                    }}>INFORMAÇÕES BÁSICAS</div>
                {/* <div
                    onClick={() => setPage(1)}
                    style={{
                        backgroundColor: page == 1 ? 'rgb(223 223 223)' : '',
                        borderRadius: 10

                    }}
                >ATIVIDADES</div> */}
                <div
                    onClick={() => setPage(2)}
                    style={{
                        backgroundColor: page == 2 ? 'rgb(223 223 223)' : '',
                        borderRadius: 10

                    }}
                >PROPOSTAS</div>
                <div
                    onClick={() => setPage(1)}
                    style={{
                        backgroundColor: page == 1 ? 'rgb(223 223 223)' : '',
                        borderRadius: 10

                    }}
                >ATIVIDADES</div>
            </div>
            {page == 0 ? (
                <Info
                    getData={getData}
                    visuplac={visuplac}
                    lead={lead}
                    setOpenLossCard={setOpenLossCard}
                    setOpenWinCard={setOpenWinCard} />
            ) : page == 1 ? (
                <LeadsActivities id={lead.id_leads}
                    setOpenLossCard={setOpenLossCard}
                    setOpenWinCard={setOpenWinCard}
                    usersOptions={usersOptions}
                    lead={lead}
                />
            ) : (
                <ListProposalLeads
                    lead={lead}
                    viewMode={true}
                    users={users}
                    vehicles={vehicles}
                    getSingleLead={getSingleLead}
                    agencies={agencies}

                />
            )}
        </div>
    )
}

export default Visualization