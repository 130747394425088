import React, { useEffect } from 'react'
import axios from 'app/utils/AxiosConfig'
import Constants from 'app/utils/Constants'
const Excel = ({ }) => {
    useEffect(() => {
        axios.get(Constants.APIEndpoints.REPORT + '/buildexcel').then(res => {

        })
    },)
    return (
        <div
            style={{ display: 'flex', flex: 1 }}
        >
            <iframe
                width="100%"
                height="100%"
                src="https://docs.google.com/spreadsheets/d/1q1TYAmo-jcIdaaqTmiHy3ENIMuBDcLkifkrJnPAkhJg/edit?usp=sharing"
                frameborder="0"
                allowfullscreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
            ></iframe>
        </div >
    )
}

export default Excel