import React, { useMemo, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import SquareForm from "./SquareForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import CommonView from '../../components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";


let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
export default function SquareList(props) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});
  const [singleSquare, setSingleSquare] = useState([])
  const [squareDialog, setSquareDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  let [squareSelected, setSquareSelected] = useState([])

  const columns = useMemo(
    () => [

      {
        Header: "Código UF",
        accessor: "cod_uf",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "UF",
        accessor: "uf",
        sortable: true,
      },
      {
        Header: "Unidade Federativa",
        accessor: "federative_unit",
        sortable: true,
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {access.square_read ? (
              <IconButton
                onClick={(ev) => {
                  viewSquare(row.original);
                }}
              >
                <Icon>remove_red_eye</Icon>
              </IconButton>
            ) : null}
            {access.square_update ? (

              <IconButton
                onClick={(ev) => {
                  setValues(row.original)
                  setPage('add')
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            ) : null}
            {access.square_delete ? (

              <IconButton
                onClick={(ev) => {
                  setSquareSelected(row.original)
                  setDeleteDialog(true)
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteSquare = (id) => {
    const data = { id_square: squareSelected.id_square }

    axios.post(Constants.APIEndpoints.SQUARE + "/deleteSquare", data).then((res) => {
      getData();
    });
  };


  const viewSquare = (square) => {

    setSquareDialog(true)
    let data = [
      {
        col: 12,
        label: 'CÓDIGO UF',
        value: square.cod_uf
      },
      {
        col: 12,
        label: 'UF',
        value: square.uf
      },
      {
        col: 12,
        label: 'UNIDADE FEDERATIVA',
        value: square.federative_unit
      }
    ]

    setSingleSquare(data)
  }



  const getData = () => {
    axios.get(Constants.APIEndpoints.SQUARE + "/getAllSquares").then((res) => {
      setData(res.data[0]);
    });
  };
  if (!data) {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >
      <CommonDialog
        open={squareDialog}
        onClose={() => setSquareDialog(false)}
        title="Ver Praça"
        width="xl"
        print={true}
      >

        {deleteDialog ? (
          <ConfirmDialog title="Deseja deletar essa Praça?" cancel={() => setDeleteDialog(false)} confirm={deleteSquare} />
        ) : null}
        <CommonView dialog={true} data={singleSquare} title="Ver Praça" onBack={() => setPage('list')} />

      </CommonDialog>


      {page == "list" ? (
        <CommonTable
          id="square_list"
          columns={columns}
          data={data}
          icon="people"
          newText="Adicionar Nova Praça"
          onAdd={access.square_create ? onAdd : null}
          headerTitle="Praças"
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <SquareForm values={values} setPage={setPage} getData={getData} />
      )}
    </motion.div>
  );
}

