import React, { useState, useEffect } from "react"
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
const schema = yup.object().shape({
    password: yup
        .string()
        .required('Por favor, escreva a senha.')
        .min(6, 'Senha muito curta, ela deve ter no mínimo 6 caracteres.'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'As senhas devem coincidir.')
        .required('Por favor, confirme sua senha.'),
});

const PasswordReset = ({ token }) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const { control, formState, handleSubmit, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    const { isValid, dirtyFields, errors } = formState;




    const onSubmit = () => {
        const values = {
            token,
            password: control._fields.password._f.value
        }
        axios.post(Constants.HOST + 'resetPassword', values).then(() => {
            window.location.href = '/'
        })
    }
    return (
        <div>
            <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className="mb-16"
                            type={showPassword ? 'text' : 'password'}
                            label="Insira sua nova senha"
                            autoFocus
                            error={!!errors.email}
                            helperText={errors?.email?.message}
                            variant="outlined"
                            required
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />

                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className="mb-16"
                            label="Insira a senha novamente."
                            type={showPassword ? 'text' : 'password'}
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            variant="outlined"
                            required
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
                <Button
                    variant="contained"
                    color="primary"
                    className="w-224 mx-auto mt-16"
                    aria-label="LOG IN"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    type="submit"
                >
                    Salvar e Voltar
                </Button>
            </form>

        </div>
    )
}

export default PasswordReset