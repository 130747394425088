import React from "react"
import CommonTable from "app/components/table/CommonTable"

const ListDataDashboard = ({ data, name }) => {
    const dataTable = []

    const cols = [
        {
            Header: 'Nome',
            accessor: "name",
            className: "font-medium",
        },
        {
            Header: 'Valor Bruto',
            accessor: "gross_value",
            className: "font-medium",
            Cell: ({ row }) => (
                <div>{parseFloat(row.original.gross_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
            )
        },
        {
            Header: 'Valor Líquido',
            accessor: "value_approved",
            className: "font-medium",
            Cell: ({ row }) => (
                <div>{parseFloat(row.original.value_approved).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
            )
        },
    ]

    return (
        <CommonTable
            data={data}
            columns={cols}
            miniVersion={true}
            fullWhite={true}
            noHeader={true}
            noSearch={true}
        />
    )
}

export default ListDataDashboard