import React from "react"
import { Typography } from "@mui/material"
import moment from "moment-timezone"
const Activities = ({
    activities
}) => {
    return (
        <div>
            <Typography class="text-xl font-semibold title">SUAS ATIVIDADES DE HOJE</Typography>

            {activities.map(activity => (
                <div className="eventActivity">
                    <Typography class='text-lg font-medium'>{activity.title}</Typography>
                    <Typography class='text-base'>{`De ${moment(activity.start).format('DD/MM/YYYY HH:mm')} à ${moment(activity.end).format('DD/MM/YYYY HH:mm')}`}</Typography>
                </div>
            ))}        </div>
    )
}

export default Activities