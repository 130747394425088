import React from 'react'
import { Icon, IconButton, Typography } from '@material-ui/core'
import moment from 'moment'

const ViewEventDetail = ({ event }) => {

    return (
        <div>
            <div className='infoDiv'>
                <Icon>
                    receipt
                </Icon>
                <Typography>NOME</Typography>
            </div>
            {event.name && (<div className='infoValue'>{event.name}</div>)}
            <div className='infoDiv'>
                <Icon>
                    today
                </Icon>
                <Typography>PERÍODO</Typography>
            </div>
            {event.start && (<div className='infoValue'>{moment(event.start).format('DD/MM/YYYY HH:mm:ss')} à {moment(event.end).format('DD/MM/YYYY HH:mm:ss')}</div>)}
            <div className='infoDiv'>
                <Icon>
                    description
                </Icon>
                <Typography>DESCRIÇÃO</Typography>
            </div>
            {event.description && (<div className='infoValue' dangerouslySetInnerHTML={{ __html: event.description }} />)}

        </div>
    )
}

export default ViewEventDetail