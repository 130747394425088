import React, { useEffect, useState } from 'react'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import moment from 'moment'
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { ToastContainer, toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CommonForm from "app/components/form/CommonForm"
import CommonTable from "app/components/table/CommonTable";
import CommonDialog from "app/components/dialog/CommonDialog";
import axios from 'app/utils/AxiosConfig'
import Constants from 'app/utils/Constants'
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";

const logged_user = Store.USER

const CreateCardWithStep = ({
    onSubmit,
    clients,
    agencies,
    mailings,
    kanban,
    users,
    card,
    setSelectedCard,
    getSingleLead,
    tags,
    values,
    setOpenCreateNewCardWithStep,
    selectedColumn,
    getData,
    vehicles,
    visuplac,
    products
}) => {
    const [activeStep, setActiveStep] = useState(0)
    const [openCreateActivity, setOpenCreateActvity] = useState(false)
    const [activitySelected, setActivitySelected] = useState([])
    const [cardActivities, setCardActivities] = useState([])
    const [deleteDialogActivity, setDeleteDialogActivity] = useState(false)
    const [openNewMailing, setOpenNewMailing] = useState(false)
    const [newMailing, setNewMailing] = useState([])
    const [mailingName, setMailingName] = useState(values.contact_name || '')
    const [mailingId, setMailingId] = useState(values.contact || '')
    const [filesToRemove, setFilesToRemove] = useState([])
    const [files, setFiles] = useState([])
    const [selectedVehicle, setSelectedVehicle] = useState(0)
    useEffect(() => {
        if (values?.files) {
            setFiles(values.files)
        }
        if (values && values.id_cards) {
            getCardActivities(values.id_cards)
        }
        if (values?.fk_id_vehicle) {
            setSelectedVehicle(values.fk_id_vehicle)
        }
    }, [values])

    const removeFile = (field, file) => {
        let filesRemove = filesToRemove
        filesRemove.push(file)
        setFilesToRemove(filesRemove)
        const newFiles = files.filter(v => v.filepath !== file.filepath)
        setFiles(newFiles)
        const newCard = card
        newCard.files = newFiles
        setSelectedCard(newCard)
    }

    const getCardActivities = (card = 0) => {
        const id = card == 0 ? values.id_cards : card
        axios.get(Constants.APIEndpoints.KANBAN + '/getcardactivities/' + id).then(res => {
            const activities = res.data.map(act => ({
                ...act,
                time_start: act.dt_start ? moment(act.dt_start).format('HH:mm') : '',
                dt_start: act.dt_start ? moment(act.dt_start).format('YYYY-MM-DD') : '',
                time_end: act.dt_end ? moment(act.dt_end).format('HH:mm') : '',
                sendmail: act.send_mail ? 'Sim' : 'Não',
                dt_end: act.dt_end ? moment(act.dt_end).format('YYYY-MM-DD') : ''
            }))
            setCardActivities(activities)
        })
    }

    const saveCardAcvitivy = (values) => {
        let cards = cardActivities
        if (!values.id_card_activity && !values.prev_id) {
            values.prev_id = cardActivities.length
            cards.push(values)
        }
        if (values.id_card_activity) {
            cards.filter((p) => p.id_card_activity == values.id_card_activity)
                .map((p) => {
                    Object.keys(p).map(key => {
                        p[key] = values[key]
                    })
                });
        }
        if (values.prev_id) {
            cards.filter((p) => p.prev_id == values.prev_id)
                .map((p) => {
                    Object.keys(p).map(key => {
                        p[key] = values[key]
                    })
                });
        }
        setCardActivities(cards)
        setOpenCreateActvity(false)
    }

    const deleteActivity = () => {
        axios.delete(Constants.APIEndpoints.KANBAN + '/deleteactivity/' + activitySelected.id_card_activity).then(res => {
            setDeleteDialogActivity(false)
            getCardActivities()
        })
    }

    const saveCardWithApi = (values_) => {
        values_.user_cad = logged_user.id_user
        values_.fk_id_card = values.id_cards

        if (moment(`${values.dt_start} ${values.time_start}`).isSameOrAfter(moment(`${values.dt_end} ${values.time_end}`))) {
            toast("Data de término não pode ser inferior a de início");
        } else {
            const url = values_.id_card_activity ? '/updateactivity' : '/createactivity'
            axios.post(Constants.APIEndpoints.KANBAN + url, values_).then(res => {
                getCardActivities()
                setOpenCreateActvity(false)
            })
        }
    }

    const saveLead = () => {
        let valuesLead = values
        valuesLead.user_cad = logged_user.id_user
        valuesLead.id_column = selectedColumn.id_kanban_column
        if (!valuesLead.discount_standard) {
            valuesLead.discount_standard = 20
        }

        if (!values.id_cards) {
            if (cardActivities.length == 0) {
                toast.error('É obrigatório o cadastro de uma atividade.')
                return
            }
            const url = '/createCard'
            values.activities = cardActivities
            axios.post(Constants.APIEndpoints.KANBAN + url, valuesLead).then(res => {
                setOpenCreateNewCardWithStep(false)
                setOpenCreateActvity(false)
                getData({ inProgress: true })
            })
        } else {
            const url = '/updatecard'
            valuesLead.filesToRemove = filesToRemove
            axios.post(Constants.APIEndpoints.KANBAN + url, valuesLead).then(res => {
                setOpenCreateNewCardWithStep(false)
                setOpenCreateActvity(false)
                getData({ inProgress: true })
            })
        }
    }

    const createNewMailing = (name) => {
        setOpenNewMailing(true)
        let mailing = newMailing
        mailing.name = name
        setNewMailing(mailing)
    }

    const saveMailing = (values) => {
        axios.post(Constants.APIEndpoints.MAILING + '/createMailing', values).then(res => {
            setOpenNewMailing(false)
            setMailingName(values.name)
            let v_ = { ...card }
            v_.contact = res.data.insertId;
            getSingleLead(v_.id_cards)
            setMailingId(res.data.insertId)
        })
    }

    const getMailingName = (name) => {
        setMailingName(name)
    }

    const StepIcon = ({ active }) => {
        return active ? <CheckIcon color="primary" /> : <RadioButtonUncheckedIcon />;
    };

    return (
        <>
            <Stepper activeStep={activeStep} alternativeLabel>
                {[{ name: 'Lead', value: 0 }, { name: 'Atividade', value: 1 }].map((obj, i) => (
                    <Step
                        key={obj.value}
                        style={{ cursor: 'pointer', fontSize: 30 }}
                    >
                        <StepLabel
                            StepIconComponent={StepIcon}
                            onClick={() => setActiveStep(obj.value)}
                            style={{ cursor: 'pointer' }}
                        >
                            <span style={{ fontSize: 15, fontWeight: 'bold', cursor: 'pointer' }}>{obj.name}</span>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            {deleteDialogActivity ? (
                <ConfirmDialog title="Deseja deletar esta atividade" cancel={() => setDeleteDialogActivity(false)} confirm={deleteActivity} />
            ) : null}
            {activeStep == 0 ? (
                <div>
                    <CommonForm
                        fields={[
                            {
                                col: 6,
                                name: 'subject',
                                label: 'Campanha',
                                type: 'text',
                            }, {
                                col: 6,
                                name: 'contact',
                                label: 'Contato',
                                type: 'autocomplete',
                                searchType: 'mailings',
                                fieldLabel: 'name',
                                fieldValue: 'id_mailing',
                                createNewEntity: createNewMailing,
                                getLabel: getMailingName,
                                labelDefault: mailingName,
                                idDefault: mailingId
                            }, {
                                col: 6,
                                name: 'fk_id_client',
                                label: 'Cliente',
                                type: 'select',
                                options: clients.map(c => ({ value: c.id_client, label: c.fancy_name })),
                                required: true
                            }, {
                                col: 6,
                                name: 'fk_id_agency',
                                label: 'Agência',
                                type: 'select',
                                options: agencies.map(a => ({ value: a.id_agency, label: a.fancy_name })),
                            }, {
                                col: 6,
                                name: 'dt_start',
                                label: 'Data de início',
                                type: 'date',
                            }, {
                                col: 6,
                                name: 'dt_end',
                                label: 'Data de Fechamento',
                                type: 'date',
                            }, {
                                col: visuplac ? 6 : 12,
                                name: 'place_sell',
                                label: 'Praça de Veiculação',
                                type: 'text',
                            },
                            {
                                col: 6,
                                name: 'fk_id_responsable',
                                label: 'Executivo',
                                type: 'select',
                                options: users.filter(u => u.fk_id_role == 'executive')
                                    .map(u => ({ value: u.id_user, label: u.name })),
                                visible: !!visuplac
                            },
                            {
                                col: visuplac ? 4 : 6,
                                name: 'tags_',
                                label: 'Etiquetas',
                                type: 'multiselect',
                                options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color })),
                            },
                            {
                                col: 4,
                                name: 'fk_id_vehicle',
                                label: 'Veículo',
                                type: 'select',
                                options: vehicles.map(v => ({ value: v.id_vehicle, label: v.fancy_name })),
                                visible: !!visuplac
                            }, {
                                col: 4,
                                name: 'multiproducts',
                                label: 'Produtos',
                                type: 'multiselect',
                                options: products.filter(p => p.fk_id_vehicle == selectedVehicle).map(p => {
                                    return {
                                        value: p.id_product,
                                        label: p.name
                                    }
                                }),
                                visible: !!visuplac
                            },
                            {
                                col: 6,
                                name: 'products',
                                label: 'Produtos',
                                type: 'multiselect',
                                options: vehicles.map(v => ({ value: v.id_vehicle, label: v.fancy_name })),
                                visible: !visuplac
                            },
                            {
                                col: 3,
                                name: 'gross_value',
                                label: 'Valor Tabela',
                                type: 'money',
                            },
                            {
                                col: 3,
                                name: 'discount_standard',
                                label: 'Desconto Padrão',
                                type: 'number',
                            },
                            {
                                col: 3,
                                name: 'negotiated_discount',
                                label: 'Desconto Negociado',
                                type: 'number',
                            },
                            {
                                col: 3,
                                name: 'net_value',
                                label: 'Valor Líquido',
                                type: 'money',
                            },
                            {
                                col: 12,
                                name: 'observation',
                                label: 'Observações',
                                type: 'textarea'
                            },
                            {
                                col: 12,
                                name: 'files',
                                label: 'Arquivo',
                                type: 'file'
                            }
                        ]}
                        values={{
                            ...card,
                            files: files,
                            discount_standard: values.discount_standard ? values.discount_standard : 20
                        }}
                        onChangeField={(f, v) => {
                            let v_ = { ...card }
                            v_[f.name] = v;
                            if (f.name == 'contact') {
                                setMailingId(v)
                            }
                            if (f.name == 'negotiated_discount') {
                                const net_value = card.gross_value - (card.gross_value * v / 100)
                                v_.net_value = net_value
                            }
                            if (f.name == 'gross_value') {
                                const net_value = v - (v * (card.negotiated_discount || 0) / 100)
                                v_.net_value = net_value
                            }
                            if (f.name == 'fk_id_vehicle') {
                                setSelectedVehicle(v)
                            }
                            setSelectedCard(v_)

                        }}
                        removeFile={removeFile}

                    />
                    <div
                        style={{ display: 'flex', justifyContent: 'end' }}
                    >
                        <button
                            className="buttonSubmit" onClick={() => setActiveStep(1)}>Avançar</button>,
                    </div>
                </div>
            ) : (
                <div>
                    <CommonTable
                        noSearch={true}
                        miniVersion={true}
                        data={cardActivities.map(c => {
                            return {
                                ...c,
                                dt_start_: c.dt_start ? moment(`${c.dt_start} ${c.time_start ? c.time_start : '00:00:00'}`).format('DD/MM/YYYY HH:mm') : '',
                                dt_end_: c.dt_end ? moment(`${c.dt_end} ${c.time_end ? c.time_end : '23:59:59'}`).format('DD/MM/YYYY HH:mm') : '',
                                sendmail: c.sendmail ? 'Sim' : 'Não',
                                fk_id_user_: users.find(u => u.id_user == c.fk_id_user)?.name
                            }
                        })}
                        id={'activities_step_list'}
                        columns={[
                            {
                                Header: "Nome",
                                accessor: "name",
                                className: "font-medium",
                                sortable: true,
                            },
                            {
                                Header: "Início",
                                accessor: "dt_start_",
                                className: "font-medium",
                                sortable: true,
                            },
                            {
                                Header: "Término",
                                accessor: "dt_end_",
                                sortable: true,
                            },
                            {
                                Header: "Responsável",
                                accessor: "fk_id_user_",
                                sortable: true,
                            },
                            {
                                Header: "Envio de email",
                                accessor: "sendmail",
                                sortable: true,
                            },
                            {
                                id: "action",
                                width: 128,
                                sortable: false,
                                Cell: ({ row }) => (
                                    <div className="flex items-center">
                                        <IconButton
                                            onClick={(ev) => {
                                                let rowSelected = row.original
                                                rowSelected.sendmail = row.original.sendmail
                                                rowSelected.status = 1
                                                setActivitySelected(rowSelected)
                                                setOpenCreateActvity(true)
                                            }}
                                        >
                                            <Icon>edit</Icon>
                                        </IconButton>
                                        <IconButton
                                            onClick={(ev) => {
                                                let rowSelected = row.original
                                                rowSelected.status = rowSelected.status ? rowSelected.status : 1
                                                setActivitySelected(rowSelected)
                                                setDeleteDialogActivity(true)
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </div>
                                ),
                            },
                        ]}
                        newText="Adicionar nova atividade"
                        onAdd={() => {
                            setActivitySelected({ status: 1 })
                            setOpenCreateActvity(true)
                        }
                        }
                    />
                    <div
                        style={{ display: 'flex', justifyContent: 'end' }}
                    >
                        <button
                            className="buttonSubmit" onClick={saveLead}>Salvar</button>,
                    </div>
                </div>
            )}

            <CommonDialog
                open={openCreateActivity}
                onClose={() => setOpenCreateActvity(false)}
                title='Criar Atividade'
                width='lg'
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 6,
                            type: 'text',
                            name: 'name',
                            label: 'Nome'
                        },
                        {
                            col: 4,
                            type: 'select',
                            name: 'fk_id_user',
                            label: 'Responsável',
                            options: users.map(u => ({ value: u.id_user, label: u.name }))
                        },
                        // {
                        //     col: 3,
                        //     type: 'select',
                        //     name: 'status',
                        //     label: 'Status',
                        //     options: [
                        //         { value: 1, label: 'Em Andamento' },
                        //         { value: 2, label: 'Concluída' },
                        //         { value: 3, label: 'Atrasada' },
                        //     ]
                        // },
                        // {
                        //     col: 2,
                        //     type: 'checkbox',
                        //     name: 'sendmail',
                        //     label: 'Receber email lembrando dessa atividade?'
                        // },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_start',
                            label: 'Data de Início'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_start',
                            label: 'Hora de Início'
                        },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_end',
                            label: 'Data de Término'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_end',
                            label: 'Hora de Término'
                        }, {
                            col: 12,
                            type: 'textarea',
                            name: 'description',
                            label: 'Descrição'
                        }
                    ]}
                    onSubmit={values.id_cards ? saveCardWithApi : saveCardAcvitivy}
                    values={activitySelected}
                    onChangeField={(f, v) => {
                        let v_ = { ...activitySelected }
                        v_[f.name] = v;
                        setActivitySelected(v_)
                    }}
                />
            </CommonDialog>
            <CommonDialog
                open={openNewMailing}
                onClose={() => setOpenNewMailing(false)}
                title='Criar Contato'
                width='lg'
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            type: "text",
                            name: "name",
                            label: "Nome",
                            required: true

                        },
                        {
                            col: 6,
                            type: "text",
                            name: "company_function",
                            label: "Função",
                        },
                        {
                            col: 6,
                            type: "text",
                            name: "email",
                            label: "Email",

                        },

                        {
                            col: 4,
                            type: "date",
                            name: "dt_birthday",
                            label: "Data de Nascimento",
                        },
                        {
                            col: 4,
                            type: "date",
                            name: "dt_start_company",
                            label: "Data de início na empresa",
                        },

                        {
                            col: 4,
                            type: "text",
                            name: "address",
                            label: "Endereço",
                        },
                        {
                            col: 12,
                            type: "text",
                            name: "phone",
                            label: "Telefone",
                        },
                        {
                            col: 6,
                            type: "select",
                            name: "fk_id_type",
                            label: "Tipo",
                            options: [
                                { value: 1, label: "Veículo" },
                                { value: 2, label: "Agência" },
                                { value: 3, label: "Cliente" },
                                { value: 4, label: "Interno" },
                            ],
                        },
                        {
                            col: 6,
                            label: "Local",
                            name: "place",
                            type: "text",
                            required: true
                        }
                    ]}
                    onSubmit={saveMailing}
                    values={newMailing}
                    onChangeField={(f, v) => {
                        let v_ = { ...newMailing }
                        v_[f.name] = v;
                        setNewMailing(v_)
                    }}
                />
            </CommonDialog>
        </>
    )
}

export default CreateCardWithStep