import React, { useEffect, useMemo, useState } from "react";
import CommonTable from "../../components/table/CommonTable";
import axios from 'app/utils/AxiosConfig'
import Constants from "app/utils/Constants";
import { useDispatch } from "react-redux";
import SolicitationForm from "./SolicitationForm";
import moment from "moment";
import CommonDialog from "app/components/dialog/CommonDialog";

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
const Solicitation = ({ }) => {
    const [data, setData] = useState([])
    const [page, setPage] = useState('list')
    const [selectedSolicitation, setSelectedSolicitation] = useState([])
    const [showDetail, setShowDetail] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        axios.get(Constants.APIEndpoints.SOLICITATION + '/getsolicitations').then(res => {
            setData(res.data)
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Número',
                accessor: 'number',
                className: 'front-medium',
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {row.original.id_solicitation}
                    </div>
                )
            },
            {
                Header: 'Assunto',
                accessor: 'subject',
                className: 'front-medium',
                sortable: true
            },
            {
                Header: "Data de criação",
                accessor: "dt_cad",
                className: "font-medium",
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {moment(row.original.dt_cad).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                )
            },

            {
                Header: "Usuário",
                accessor: "name",
                className: "font-medium",
                sortable: true,
            }, {
                Header: "Status",
                accessor: "status",
                className: "font-medium",
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {row.original.status == 1 ? 'Criado' : 'Resolvido'}
                    </div>
                )
            },
            {
                Header: "Tipo",
                accessor: "type",
                className: "font-medium",
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {row.original.type == 1 ? 'Report de Erro' : 'Solicitação'}
                    </div>
                )
            },

            {
                Header: "Data de Resposta",
                accessor: "dt_response",
                className: "font-medium",
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {row.original.dt_response ? moment(row.original.dt_response).format('DD/MM/YYYY HH:mm:ss') : ''}
                    </div>
                )
            }, {
                Header: "Resposta",
                accessor: "response",
                className: "font-medium",
                sortable: true,
            },
            {
                id: 'action',
                Header: 'Visualizar',
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex items-center">
                        <Tooltip title={<h5>Visualizar</h5>} placement="right">

                            <IconButton
                                onClick={(ev) => {
                                    setSelectedSolicitation(row.original)
                                    setShowDetail(true)
                                }}
                            >
                                <Icon>remove_red_eye</Icon>
                            </IconButton>
                        </Tooltip>

                    </div>
                ),
            }
        ], [dispatch])
    return (
        <div>
            <CommonDialog
                open={showDetail}
                onClose={() => {
                    setShowDetail(false)
                    setSelectedSolicitation([])
                }}
                title='Descrição'
            >
                <div dangerouslySetInnerHTML={{ __html: selectedSolicitation?.description }}>

                </div>
            </CommonDialog>
            {page == 'list' ? (
                <CommonTable
                    id="solicitation_list"
                    columns={columns}
                    data={data}
                    icon="error"
                    newText="Fazer solicitação"
                    onAdd={() => setPage('add')}
                    headerTitle="Solicitações e Correções"
                    onRowClick={(ev, row) => {
                        if (row) {
                            //            dispatch(openEditContactDialog(row.original));
                        }
                    }}
                />
            ) : (
                <SolicitationForm
                    setPage={setPage}
                    getData={getData}
                />
            )}
        </div>
    )
}

export default Solicitation