import React, { useState, useEffect } from 'react'
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import Constants from "app/utils/Constants";
import axios from 'app/utils/AxiosConfig';
import CommonTable from 'app/components/table/CommonTable';
import moment from 'moment'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { ToastContainer, toast } from 'react-toastify';

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
const CheckingReport = () => {
    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [columns, setColums] = useState([])
    const history = useHistory();

    useEffect(() => {
        getData()
    }, [])

    const getStatusData = (row, step) => {
        const dateCreated = (row.steps || []).find(s => s.step === step)
        if (step == 0) {
            if (dateCreated)
                return {
                    data: moment(dateCreated.dt_cad).format('DD/MM/YYYY HH:mm:ss'),
                    diff: ''
                }

            return {
                data: '',
                diff: ''
            }
        } else {
            let lastDateCreated = (row.steps || []).find(s => s.step === step - 1)
            if (!lastDateCreated && step > 1) {
                lastDateCreated = (row.steps || []).find(s => s.step === step - 2)
                if (!lastDateCreated && step > 2) {
                    lastDateCreated = (row.steps || []).find(s => s.step === step - 3)
                }
            }

            if (dateCreated && lastDateCreated) {
                const diff = moment(dateCreated.dt_cad).diff(moment(lastDateCreated.dt_cad), 'seconds')
                const days = parseInt(diff / 86400)
                const hours = parseInt((diff - (days * 86400)) / 3600)
                const minutes = parseInt((diff - (days * 86400 + hours * 3600)) / 60)
                return {
                    data: `${moment(dateCreated.dt_cad).format('DD/MM/YYYY HH:mm:ss')}`,
                    diff: `${days} dias, ${hours} horas e ${minutes} minutos`
                }
            }
            if (dateCreated && !lastDateCreated)
                return {
                    data: moment(dateCreated.dt_cad).format('DD/MM/YYYY HH:mm:ss'),
                    diff: ''
                }

            return {
                data: '',
                diff: ''
            }
        }
    }


    const getData = (filters = {}, limit = 25, page = 0) => {
        const offset = limit * page
        axios.post(Constants.APIEndpoints.REPORT + `/checkingreport?limit=${limit}&offset=${offset}`, { ...filters }).then(res => {
            setData(res.data.checkings)
            setTotalData(res.data.checkings[0] ? res.data.checkings[0].total : 0)
            const newColumns = res.data.columns.map((c, index) => ({
                Header: c.status,
                accessor: `step_${c.step}`,
                className: "font-medium",
                sortable: true,
                noSearchable: true,
                disableSortBy: true,
                Cell: ({ row }) => (
                    <div style={{ textAlign: 'center' }}>
                        {getStatusData(row.original, c.step).data}
                        <div>
                            {getStatusData(row.original, c.step).diff}
                        </div>
                    </div>
                )
            }))
            newColumns.unshift(
                {
                    Header: 'PI',
                    accessor: 'number',
                    className: "font-medium",
                    sortable: true,
                    disableSortBy: true
                },
                {
                    Header: 'Valor Líquido',
                    accessor: 'net_value_approved',
                    className: "font-medium",
                    sortable: true,
                    disableSortBy: true,
                    Cell: ({ row }) => (
                        <div>
                            {parseFloat((row.original.net_value_approved || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </div>
                    )
                },
                {
                    Header: 'Veículo',
                    accessor: 'vehicle',
                    className: "font-medium",
                    sortable: true,
                    disableSortBy: true
                },
                {
                    Header: 'Agência',
                    accessor: 'agency',
                    className: "font-medium",
                    sortable: true,
                    disableSortBy: true
                },
                {
                    Header: 'Cliente',
                    accessor: 'client',
                    className: "font-medium",
                    sortable: true,
                    disableSortBy: true
                },

            )
            newColumns.push({
                Header: 'Ir para o negócio',
                visible: true,
                width: 28,
                noSearchable: true,
                Cell: ({ row }) => (
                    <div className="flex justify-center">
                        <IconButton
                            onClick={(ev) => {
                                history.push({
                                    pathname: '/propostas',
                                    state: {
                                        fk_id_proposals: row.original.id_proposals,
                                        mode: 'view',
                                    },
                                });
                            }}
                        >
                            <Icon>remove_red_eye</Icon>
                        </IconButton >
                    </div>
                )
            })
            setColums(newColumns)
        })
    }

    const exportData = (values) => {
        let file = `Checking-${moment().format('DDMMYYYY_HH:mm:ss')}.xlsx`
        const token = sessionStorage.getItem('token');
        const notification = toast("Gerando Relatório");

        fetch(Constants.APIEndpoints.REPORT + '/exportcheckingreport',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                body: JSON.stringify({})
            }).then(results => {
                return results.blob()
            }).then(response => {
                saveAs(response, file);
                toast.dismiss(notification)
            })
    }

    const rightSide = (
        <div>
            <IconButton
                style={{
                    color: 'white'
                }}
                onClick={(ev) => {
                    exportData()
                }}>
                <Icon>insert_drive_file</Icon>
            </IconButton>
        </div>
    )

    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            {columns.length > 0 && (
                <CommonTable
                    id={'checking_report_list'}
                    columns={columns}
                    data={data}
                    noSearch={true}
                    pagination={getData}
                    totalData={totalData}
                    rightSide={rightSide}

                />
            )}
        </div>
    )
}

export default CheckingReport