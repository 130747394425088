import React, { useState } from "react"
import Info from "./Info"
import CardActivities from "./CardActivities"

const Visualization = ({
    visuplac,
    lead,
    getData,
    setOpenWinCard,
    setOpenLossCard,
    usersOptions }) => {
    const [page, setPage] = useState(0)
    return (
        <div style={{ height: 500 }}>
            <div className="btnHeaders">
                <div
                    onClick={() => setPage(0)}
                    style={{
                        backgroundColor: page == 0 ? 'rgb(223 223 223)' : '',
                        borderRadius: 10
                    }}>INFORMAÇÕES BÁSICAS</div>
                <div
                    onClick={() => setPage(1)}
                    style={{
                        backgroundColor: page == 1 ? 'rgb(223 223 223)' : '',
                        borderRadius: 10

                    }}
                >ATIVIDADES</div>
            </div>
            {page == 0 ? (
                <Info
                    getData={getData}
                    visuplac={visuplac}
                    lead={lead}
                    setOpenLossCard={setOpenLossCard}
                    setOpenWinCard={setOpenWinCard} />
            ) : (
                <CardActivities id={lead.id_cards}
                    setOpenLossCard={setOpenLossCard}
                    setOpenWinCard={setOpenWinCard}
                    usersOptions={usersOptions}
                    lead={lead}
                />
            )}
        </div>
    )
}

export default Visualization