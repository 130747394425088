import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "app/components/table/CommonTable";
import ProposalProductForm from "./ProposalProductForm";
import moment from "moment-timezone";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";

import CommonView from 'app/components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";


let logged_user = Store.USER
export default function ProposalProductList({
  vehicle,
  id_proposals,
  onBack,
  params
}) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});

  const [singleProduct, setSingleProduct] = useState([])
  const [productDialog, setProductDialog] = useState(false)
  const [proposalValues, setProposalValues] = useState([])
  const [deleteDialog, setDeleteDialog] = useState(false)
  const monthList = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const columns = useMemo(
    () => [
      {
        Header: "Produto",
        accessor: "product_name",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Quantidade Contratada",
        accessor: "quantity_hired",
        sortable: true,
      },
      {
        Header: "Quantidade Entregue",
        accessor: "quantity_delivered",
        sortable: true,
      },
      {
        Header: "Porcentagem entregue",
        accessor: "percent_delivered",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.percent_delivered}%
          </div>
        )
      },
      {
        Header: "Data de Início",
        accessor: "dt_start",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {moment(row.original.dt_start).format('DD/MM/YYYY')}
          </div>
        )
      },
      {
        Header: "Data de Término",
        accessor: "dt_end",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {moment(row.original.dt_end).format('DD/MM/YYYY')}
          </div>
        )
      },
      {
        Header: "Preço",
        accessor: "price",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {(row.original.price || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </div>
        )
      },
      {
        Header: "Executivo",
        accessor: "executive",
        sortable: true,
      },
      {
        Header: "Status",
        accessor: "status_name",
        sortable: true,
      },
      {
        Header: "Custos da Plataforma",
        accessor: "platform_costs",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {parseFloat(row.original.platform_costs || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </div>
        )
      },
      {
        Header: "Custos Extras",
        accessor: "extras_costs",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {parseFloat(row.original.extras_costs || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </div>
        )
      },
      {
        Header: "Porcentagem de Custos",
        accessor: "percent_costs",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.percent_costs}%
          </div>
        )
      },
      {
        Header: "Investimento",
        accessor: "investment",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {parseFloat(row.original.investment || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </div>
        )
      },

      {
        Header: "Perdas",
        accessor: "lost",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {parseFloat(row.original.lost || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </div>
        )
      },
      {
        Header: "Total Bruto",
        accessor: "gross_billed",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {parseFloat(row.original.gross_billed || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </div>
        )
      },
      {
        Header: "Total Líquido",
        accessor: "net_billed",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {parseFloat(row.original.net_billed || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </div>
        )
      },
      {
        Header: "Mês recebido",
        accessor: "month_received",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.month_received > 0 ? monthList[row.original.month_received] : ''}
          </div>
        )
      },
      {
        Header: "Observação",
        accessor: "observation",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {(row.original.observation || '').replace(/<[^>]*>?/gm, '')}
          </div>
        )
      },
      {
        Header: "Nota Fiscal",
        accessor: "nf",
        sortable: true,
      },
      {
        Header: "Status de envio",
        accessor: "delivered_status_name",
        sortable: true,
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton
              onClick={(ev) => {
                setValues(row.original)
                setPage('add')
              }}
            >
              <Icon>edit</Icon>
            </IconButton>
            <IconButton
              onClick={(ev) => {
                setSingleProduct(row.original)
                setDeleteDialog(true)
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteProduct = () => {
    axios.post(Constants.APIEndpoints.PROPOSAL + "/deleteproposalproduct/" + singleProduct.id_rel_proposal_product, { fk_id_proposal: singleProduct.fk_id_proposal || 0 }).then((res) => {
      setDeleteDialog(false)
      getData();
    });
  };


  const getData = () => {
    axios.get(Constants.APIEndpoints.PROPOSAL + "/getproposalproducts/" + id_proposals).then((res) => {
      setData(res.data);
    });
    setTimeout(() => {
      axios.get(Constants.APIEndpoints.PROPOSAL + '/getproposalvalues/' + id_proposals).then(res => {
        setProposalValues(res.data[0])
      })
    }, 500)
  };
  const underHeader = (
    <div style={{ padding: 10 }}>
      <p style={{ fontSize: 14 }}>Valor Bruto: <strong>{parseFloat(proposalValues?.approved_gross_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
      <p style={{ fontSize: 14 }}>Valor Líquido: <strong>{parseFloat(proposalValues?.net_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
    </div>
  )
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >

      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar esse Produto?" cancel={() => setDeleteDialog(false)} confirm={deleteProduct} />
      ) : null}

      <CommonDialog
        open={productDialog}
        onClose={() => {
          setProductDialog(false)
        }
        }
        title="Ver Produto"
        width="xl"
        print={true}
      >


        <CommonView dialog={true} data={singleProduct} title="Ver Pproduct" onBack={() => setPage('list')} />

      </CommonDialog>

      {page == "list" ? (
        <CommonTable
          id="product_proposal_list"
          columns={columns}
          onBack={() => onBack('list')}
          data={data}
          underHeader={underHeader}
          icon="people"
          newText="Adicionar Novo Produto"
          onAdd={onAdd}
          headerTitle="Produtos"
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : page == "view" ? null : (
        <ProposalProductForm
          params={params}
          vehicle={vehicle} id_proposals={id_proposals} values={values} setPage={setPage} getData={getData} />
      )}
    </motion.div>
  );
}

