import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Divider } from '@material-ui/core';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import moment from 'moment-timezone'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from 'app/utils/Store'
import CommonForm from 'app/components/form/CommonForm';
let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        width: 520,
        padding: 15
    },
}));


function OwnDashSidebar
    ({
        open,
        onClose,
        users,
        dashes,
        getDashes,
        selectedDash,
        idDashSelected,
        setIdDashSelected,
        loadDash,
        setSelectedDash

    }) {
    const classes = useStyles();
    const [openCreate, setOpenCreate] = useState(false)

    const monthList = [
        { value: 0, label: "Janeiro" },
        { value: 1, label: "Fevereiro" },
        { value: 2, label: "Março" },
        { value: 3, label: "Abril" },
        { value: 4, label: "Maio" },
        { value: 5, label: "Junho" },
        { value: 6, label: "Julho" },
        { value: 7, label: "Agosto" },
        { value: 8, label: "Setembro" },
        { value: 9, label: "Outubro" },
        { value: 10, label: "Novembro" },
        { value: 11, label: "Dezembro" },
    ];

    const saveDash = () => {
        axios.post(Constants.APIEndpoints.DASHBOARD + (selectedDash.id_dash ? '/updatedash' : '/savedash'), selectedDash).then(res => {
            setOpenCreate(false)
            getDashes()
        })
    }

    const editDash = (dash) => {
        setIdDashSelected(dash.id_dash)
        if (dash.dt_start) {
            dash.dt_start = moment(dash.dt_start).format('YYYY-MM-DD')
        }
        if (dash.dt_end) {
            dash.dt_end = moment(dash.dt_end).format('YYYY-MM-DD')
        }
        setSelectedDash(dash)
        setOpenCreate(true)
    }

    const deleteDash = (dash) => {
        axios.post(Constants.APIEndpoints.DASHBOARD + '/deletedash', dash).then(res => {
            getDashes()
        })
    }

    return (

        <SwipeableDrawer
            classes={{ paper: clsx(classes.root) }}
            open={open}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={onClose}
            disableSwipeToOpen

        >
            <div className="flex flex-col space-y-32">
                <Typography
                    style={{ textAlign: 'center', fontSize: 20, fontWeight: 500 }}>Suas Dashboards</Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button className='buttonChangeViewMode'
                        onClick={() => {
                            setOpenCreate(true)
                            setSelectedDash({})
                        }}
                        style={{
                            color: 'white', backgroundColor: '#252f3e', maxWidth: 150
                            , borderRadius: 30, margin: 0, marginTop: 10
                        }}>Criar Nova
                    </button>

                </div>
                <Divider
                    style={{ marginTop: 20 }}
                />
                {openCreate && (
                    <CommonForm
                        values={selectedDash}
                        onChangeField={(f, v) => {
                            const newDash = { ...selectedDash }
                            newDash[f.name] = v
                            setSelectedDash(newDash)
                        }}
                        fields={[
                            {
                                col: 12,
                                name: 'name',
                                label: 'Nome da Dash',
                                type: 'text',
                                required: true
                            },
                            {
                                col: 12,
                                type: 'checkbox',
                                label: 'Usar filtro de data flexível',
                                name: 'date_flexible'
                            },
                            {
                                col: 12,
                                type: 'select',
                                label: 'Período',
                                name: 'period',
                                visible: selectedDash?.date_flexible == true,
                                options: [
                                    { value: 1, label: 'Semana atual' },
                                    { value: 2, label: 'Mês Atual' },
                                    { value: 3, label: 'Bimestre Atual' },
                                    { value: 4, label: 'Ano Atual' },
                                    { value: 5, label: 'Personalizado' },
                                ]
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_start",
                                label: "De",
                                visible: selectedDash?.period == 5
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_end",
                                label: "Até",
                                visible: selectedDash?.period == 5
                            },
                            {
                                col: 12,
                                name: 'users',
                                label: 'Usuários',
                                type: 'multiselect',
                                options: users.map(user => ({ value: user.id_user, label: user.name }))
                            }

                        ]}
                        onSubmit={saveDash}
                    />
                )}
                {(dashes || []).map(dash => (
                    <div className="funnelDiv"
                        style={{
                            backgroundColor: dash.id_dash == idDashSelected ? '#1b2330' : 'white',
                            color: dash.id_dash == idDashSelected ? 'white' : 'black',
                            marginTop: 20
                        }}
                    >

                        <Typography
                            style={{ width: '75%', height: '80%' }}
                            onClick={() => loadDash(dash)}
                        >{dash.name}</Typography>
                        <div>
                            <IconButton>
                                <Icon
                                    onClick={() => editDash(dash)}
                                    style={{ color: dash.id_dash == idDashSelected ? 'white' : 'black' }}
                                >
                                    edit
                                </Icon>
                            </IconButton>
                            <IconButton>
                                <Icon
                                    style={{ color: dash.id_dash == idDashSelected ? 'white' : 'black' }}
                                    onClick={() => deleteDash(dash)}
                                >
                                    delete
                                </Icon>
                            </IconButton>
                        </div>
                    </div>
                ))}
            </div>

        </SwipeableDrawer >
    );
}

export default OwnDashSidebar
    ;
