import React, { useState, useEffect } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import moment from 'moment-timezone'
import { useResizeDetector } from 'react-resize-detector';

function TeamForm({ values, setPage, getData, usersOptions }) {

  const [valuesForm, setValuesForm] = useState(values)
  const { width, height, ref } = useResizeDetector();

  let fields = [
    {
      col: 12,
      type: "text",
      name: "name",
      label: "Nome",
      required: true
    },
    {
      col: 12,
      type: "select",
      name: "fk_id_leader",
      label: "Líder",
      required: true,
      options: usersOptions
    },

  ];

  const onSubmit = () => {
    axios.post(Constants.APIEndpoints.TEAM + (values.id_team ? '/updateTeam' : '/createTeam'), valuesForm).then(res => {
      setPage('list')
      getData();
    }).catch(error => {
      console.log(error)
    })


  }

  return (
    <div ref={ref}>
      <CommonHeader title="Criar Time"
        width={width}
        onBack={() => setPage('list')} />
      <CommonForm
        hasHeader={true}
        values={valuesForm}
        fields={fields}
        onChangeField={(f, v) => {
          let v_ = { ...valuesForm }
          v_[f.name] = v;
          setValuesForm(v_)
        }}
        onSubmit={onSubmit}
      />

    </div>
  );
}

export default TeamForm;
