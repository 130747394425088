import React, { useRef } from "react";
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  DialogActions,
  DialogContent,
  Icon,
  IconButton
} from "@material-ui/core";

import { useReactToPrint } from 'react-to-print';
import { motion } from "framer-motion";


export default function CommonDialog(props) {
  const { open, onClose, title, keepMounted, width, fullWidth, keep, print, fullScreen } = props
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Dialog
        aria-labelledby="settings-panel"
        aria-describedby="settings"
        open={open}
        keepMounted={keepMounted}
        onClose={keep ? () => { } : onClose}
        BackdropProps={{ invisible: true }}
        maxWidth={width}
        fullWidth={fullWidth}
        fullScreen={fullScreen}

      >
        <AppBar position="static" elevation={0} style={{ maxHeight: 100, height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Toolbar className="flex w-full">
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <Typography
                component={motion.span}
                initial={{ x: -20, y: -10 }}
                animate={{ x: 20, y: 10, transition: { delay: 0.2 } }}
                delay={300}
                className="hidden sm:flex text-12 md:text-16 mx-12 font-semibold "
              >
                {title}
              </Typography>
              <div style={{ display: 'flex', width: '5%', justifyContent: 'space-between' }}>
                {print ? (
                  <IconButton
                    onClick={handlePrint}
                    style={{ color: 'white' }}
                  >
                    <Icon>print</Icon>

                  </IconButton>
                ) : null}
                <IconButton
                  onClick={onClose}
                  style={{ color: 'white', width: 20 }}
                >
                  <Icon>close</Icon>

                </IconButton>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <DialogContent ref={componentRef}>
          {props.children}
        </DialogContent>
      </Dialog>

    </div>
  );
}
