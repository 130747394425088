import React, { memo, useEffect, useState } from 'react';
import FuseShortcuts from '@fuse/core/FuseShortcuts';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import NotificationPanelToggleButton from '../../shared-components/notificationPanel/NotificationPanelToggleButton';
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import Store from 'app/utils/Store'
import Constants from 'app/utils/Constants'
import io from 'socket.io-client';
import { ToastContainer, toast } from "react-toastify";
import { eventBus } from "app/utils/EventListener";
import axios from 'app/utils/AxiosConfig';

const socket = io(Constants.HOST);
//HEADER
//TOP BAR

const user = Store.USER
const access = user && user.access ? user.access : {}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function ToolbarLayout1(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const navbar = useSelector(({ fuse }) => fuse.navbar);
  const [totalNotifications, setTotalNotifications] = useState(0)
  const toolbarTheme = useSelector(selectToolbarTheme);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles(props);
  const Msg = ({ text, fk_id_item, onClick }) => (
    <div onClick={onClick}>
      <p>{text}</p>
    </div>
  );

  useEffect(() => {
    socket.on('lead_awaiting_win', (data) => {
      getTotalNotifications()
      const onClick = () => {
        markNotificationAsRead(data.id_notification)
        if (location.pathname === '/nleads') {
          eventBus.emit('openLead',
            {
              fk_id_card: data.fk_id_item,
              mode: 'view',
            })

        } else {
          history.push({
            pathname: '/nleads',
            state: {
              fk_id_card: data.fk_id_item,
              mode: 'view',
            },
          });
        }
      }
      if (data.fk_id_receiver === user.id_user) {
        toast(<Msg text={data.text} fk_id_item={data.fk_id_item}
          onClick={onClick}
        />, { type: data.type == 'danger' ? toast.TYPE.ERROR : data.type == 'warning' ? toast.TYPE.WARNING : toast.TYPE.SUCCESS })
      }


    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!!user) {
      getTotalNotifications()
    }
  }, [])

  const markNotificationAsRead = (id_notification) => {
    axios.post(Constants.APIEndpoints.NOTIFICATION + '/marknotificationasnotified', { id_notification }).then(res => {
      setTotalNotifications(res.data)
    })
  }

  const getTotalNotifications = () => {
    axios.get(Constants.APIEndpoints.NOTIFICATION + '/gettotalnotifications').then(res => {
      setTotalNotifications(res.data)
    })
  }

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx(classes.root, 'flex relative z-20 shadow-md', props.className)}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden mdDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                      <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                    )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            <Hidden mdDown>
              <FuseShortcuts />
            </Hidden>
          </div>
          <div>
            {access.menu_qa ? (
              <Tooltip title={<h4>Q&A</h4>}>
                <Link to='/qa' role='button'>
                  <IconButton>
                    <Icon>
                      help
                    </Icon>
                  </IconButton>
                </Link>
              </Tooltip>
            ) : null}
            <Tooltip title={<h4>Solicitações e Correções</h4>}>
              <Link to='/solicitations' role='button'>
                <IconButton>
                  <Icon>
                    error
                  </Icon>
                </IconButton>
              </Link>
            </Tooltip>
          </div>
          <div className="flex items-center px-8 h-full overflow-x-auto">
            <NotificationPanelToggleButton
              total={totalNotifications}
              clearNotifieds={() => markNotificationAsRead()}
            />

            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden mdDown>
                {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
