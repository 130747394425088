import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import { } from '@material-ui/data-grid'
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import ProductForm from "./GroupForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonView from '../../components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
function GroupList(props) {
  const dispatch = useDispatch();
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");

  const [values, setValues] = useState({});

  const [singleGroup, setSingleGroup] = useState([])
  const [groupDialog, setGroupDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [groupSelected, setGroupSelected] = useState([])



  const columns = useMemo(
    () => [

      {
        Header: "Nome",
        accessor: "name",
        className: "font-medium",
        sortable: true,
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {access.group_read ? (

              <IconButton
                onClick={(ev) => {
                  viewGroup(row.original);
                }}
              >
                <Icon>remove_red_eye</Icon>
              </IconButton>
            ) : null}
            {access.group_update ? (

              <IconButton
                onClick={(ev) => {

                  setValues(row.original)
                  setPage('form')
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            ) : null}

            {access.group_delete ? (
              <IconButton
                onClick={(ev) => {
                  setGroupSelected(row.original)
                  setDeleteDialog(true)
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const onAdd = () => {
    setValues({})
    setPage("form");
  };

  useEffect(() => {
    getData();
  }, []);


  const deleteGroup = (id) => {
    const data = { id_groups: groupSelected.id_groups }
    axios.post(Constants.APIEndpoints.GROUP + "/deleteGroup", data).then((res) => {
      getData();
      setDeleteDialog(false)
    });
  };

  const viewGroup = (product) => {
    setGroupDialog(true)
    let data = [
      {
        col: 12,
        label: 'NOME',
        value: product.name
      },
    ]

    setSingleGroup(data)
  }


  const getData = () => {
    axios.get(Constants.APIEndpoints.GROUP + "/getallgroups").then((res) => {
      setData(res.data);
    });
  };

  return (
    <motion.div
      initial={{ y: -80, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
    >
      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar esse Grupo?" cancel={() => setDeleteDialog(false)} confirm={deleteGroup} />
      ) : null}

      <CommonDialog
        open={groupDialog}
        onClose={() => setGroupDialog(false)}
        title="Ver Grupo"
        width="xl"
        print={true}
      >
        <CommonView dialog={true} data={singleGroup} title="Ver Grupo" onBack={() => setPage('list')} />
      </CommonDialog>



      {page == "list" ? (
        <CommonTable
          id="group_list"
          columns={columns}
          data={data}
          icon="people"
          newText="Adicionar Novo Grupo"
          onAdd={access.group_create ? onAdd : null}
          headerTitle="Grupos"
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <ProductForm values={values} setPage={setPage} getData={getData} />
      )}
    </motion.div>
  );
}
export default GroupList

