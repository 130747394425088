import React, { useState, Fragment, useEffect } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonDialog from "app/components/dialog/CommonDialog";
import "./Proposal.css";
import { Button } from "@material-ui/core";
import Input from 'app/components/input/Input'
import Store from 'app/utils/Store'
import moment from 'moment-timezone'
import { useResizeDetector } from 'react-resize-detector';
import { toast } from 'react-toastify';
import ProposalProductList from "./proposalProducts/ProposalProductsList";
import 'react-toastify/dist/ReactToastify.css';



export default function ProposalForm({
  values = {},
  setPage,
  getData,
  setProposalSelected,
  params,
  agencies,
  clients,
  vehicles,
  squares,
  products,
  status,
  users,
  tags,
  justifies
}) {
  const user = Store.USER
  const [monthSell, setMonthSell] = useState(isNaN(values.month_sell) ? moment().month() : values.month_sell)
  const [yearSell, setYearSell] = useState(isNaN(values.year_sell) ? moment().year() : values.year_sell)

  if (values) {
    values.dt_emission = values.dt_emission ? moment(values.dt_emission).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
    values.dt_start = values.dt_start ? moment(values.dt_start).format('YYYY-MM-DD') : ''
    values.dt_end = values.dt_end ? moment(values.dt_end).format('YYYY-MM-DD') : ''

    if (values.tags) {
      values.tags_ = values.tags.map(t => t.id_tags)
    }
    if (values.justifies) {
      values.fk_id_justify = values.justifies.map(t => t.id_justify)
    }
    if (!values.fk_id_status) {
      values.fk_id_status = 3
    }
  }


  const handlePageForm = (page) => {
    setPageForm(page)
  }

  const { width, height, ref } = useResizeDetector();

  const [valuesForm, setValuesForm] = useState(values);
  let [productsSelected, setProductsSelected] = useState([]);
  const [openModalProduct, setOpenModalProduct] = useState(false);
  const [valuesProduct, setValuesProduct] = useState([]);

  const [standardDiscount, setStandardDiscount] = useState(20)
  const [grossValueProposal, setGrossValueProposal] = useState(0)
  const [standardDiscountProposal, setStandardDiscountProposal] = useState(0)
  const [netValueProposal, setNetValueProposal] = useState(0)
  const [approvedGrossValue, setApprovedGrossValue] = useState(0)
  const [standardDiscountApproved, setStandardDiscountApproved] = useState(0)
  const [netValueApproved, setNetValueApproved] = useState(0)
  const [pageForm, setPageForm] = useState('proposals')
  const [valuesFiles, setValuesFiles] = useState([])

  const [selectedVehicle, setSelectedVehicle] = useState(0)
  const [filesToRemove, setFilesToRemove] = useState([])
  const [newProductDialog, setNewProductDialog] = useState(false)
  useEffect(() => {
    if (values.products) {
      handleProducts(values.products)
    }
    if (values.standard_discount !== undefined) {
      let proposal = values
      setGrossValueProposal(parseFloat(proposal.gross_value_proposal).toFixed(2))
      setApprovedGrossValue(parseFloat(proposal.approved_gross_value).toFixed(2))
      setNetValueApproved(parseFloat(proposal.net_value_approved).toFixed(2))
      setNetValueProposal(parseFloat(proposal.net_value_proposal).toFixed(2))
      setStandardDiscount(parseFloat(proposal.standard_discount).toFixed(2))
      setStandardDiscountApproved(parseFloat(proposal.standard_discount_approved).toFixed(2))
      setStandardDiscountProposal(parseFloat(proposal.standard_discount_proposal).toFixed(2))
    }
    if (values.fk_id_vehicle) {
      setSelectedVehicle(values.fk_id_vehicle)
    }

  }, [])
  const monthList = [
    { value: 0, label: "Janeiro" },
    { value: 1, label: "Fevereiro" },
    { value: 2, label: "Março" },
    { value: 3, label: "Abril" },
    { value: 4, label: "Maio" },
    { value: 5, label: "Junho" },
    { value: 6, label: "Julho" },
    { value: 7, label: "Agosto" },
    { value: 8, label: "Setembro" },
    { value: 9, label: "Outubro" },
    { value: 10, label: "Novembro" },
    { value: 11, label: "Dezembro" },
  ];
  let fieldsProposal = [
    {
      col: 4,
      type: "date",
      name: "dt_emission",
      label: "Data de Ganho",
      required: true,
      disabled: ['financeiro', 'opec'].includes(user.role)
    },
    {
      col: 2,
      type: "select",
      name: "month_sell",
      label: "Mês de venda",
      options: monthList,
      required: true,
      disabled: ['financeiro', 'opec'].includes(user.role)

    },
    {
      col: 2,
      type: "select",
      name: "year_sell",
      label: "Ano da venda",
      options: [
        { value: moment().year() - 1, label: moment().year() - 1 },
        { value: moment().year(), label: moment().year() },
        { value: moment().year() + 1, label: moment().year() + 1 },
        { value: moment().year() + 2, label: moment().year() + 2 },
      ],
      required: true,
      disabled: ['financeiro', 'opec'].includes(user.role)

    },
    {
      col: 4,
      type: "text",
      name: "number",
      label: "Nº PI/PP",
      required: true,
      disabled: ['financeiro', 'opec'].includes(user.role)
    },
    {
      col: 12,
      type: "text",
      name: "campaign",
      label: "Campanha",
      required: true,
      disabled: ['financeiro', 'opec'].includes(user.role)
    },
    {
      col: 6,
      type: "select",
      name: "fk_id_square",
      label: "Praça de venda",
      options: squares.map(sq => {
        return {
          value: sq.id_square,
          label: sq.uf
        }
      }),
      disabled: ['financeiro', 'opec'].includes(user.role)
    },
    {
      col: 6,
      type: "select",
      name: "fk_id_responsable",
      isClearable: true,
      label: "Usuário Responsável",
      options: users.filter(u => u.fk_id_role == 'executive').map(v => {
        return {
          value: v.id_user,
          label: v.name
        }
      }),
      disabled: ['financeiro', 'opec'].includes(user.role)
    },

    {
      col: 6,
      type: "select",
      name: "fk_id_client",
      label: "Cliente",
      options: clients.map(c => {
        return {
          value: c.id_client,
          label: c.fancy_name
        }
      }),
      disabled: ['financeiro', 'opec'].includes(user.role)
    },
    {
      col: 6,
      type: "select",
      name: "fk_id_agency",
      label: "Agência",
      options: agencies.map(ag => {
        return {
          value: ag.id_agency,
          label: ag.fancy_name
        }
      }),
      disabled: ['financeiro', 'opec'].includes(user.role)
    },
    {
      col: 4,
      type: "select",
      name: "month_placement",
      label: "Mês de Veiculação",
      options: monthList,
      required: true,
      disabled: ['financeiro', 'opec'].includes(user.role)
    },
    {
      col: 4,
      type: "select",
      name: "fk_id_vehicle",
      label: "Veículo",
      options: vehicles.map(v => {
        return {
          value: v.id_vehicle,
          label: v.fancy_name
        }
      }),
      disabled: ['financeiro', 'opec'].includes(user.role)
    },
    {
      col: 4,
      type: "text",
      name: "sponsor_vehicle",
      label: "Representante do Veículo",
      disabled: ['financeiro', 'opec'].includes(user.role)
    },
  ];

  let fieldsHeader = [
    {
      col: 4,
      type: "select",
      name: "fk_id_status",
      label: "Status",
      options: status.filter(s => s.sector == 'COMERCIAL').map(st => {
        return {
          value: st.id_status,
          label: st.name,
          color: 'black'
        }
      }),
    },
    {
      label: 'Etiquetas',
      col: ['admin', 'opecadmin'].includes(user.role) ? 4 : 6,
      name: 'tags_',
      type: 'multiselect',
      options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color }))
    },
    {
      label: 'Justificativa',
      col: ['admin', 'opecadmin'].includes(user.role) ? 4 : 6,
      name: 'fk_id_justify',
      visible: ['admin', 'opecadmin'].includes(user.role),
      type: 'multiselect',
      options: justifies.filter(j => j.type_justify == 1).map(justify => ({ value: justify.id_justify, label: justify.name, color: 'black' }))
    },
  ]

  const rightSide = (
    <div
      style={{ width: 800, display: 'flex' }}
    >
      {values && values.id_proposals && (
        <div style={{ width: 200 }}>
          <Button
            className="whitespace-nowrap"
            variant="contained"
            color="white"
            onClick={() => handlePageForm('files')}
            style={{ marginTop: 40, height: 'fit-content' }}
          >
            <span className="hidden sm:flex">Gerenciar Arquivos</span>
          </Button>
        </div>
      )}
      <div style={{ width: 600 }}>
        <CommonForm
          fields={fieldsHeader}
          values={valuesForm}
          onChangeField={(f, v) => {
            let values_ = { ...valuesForm }
            values_[f.name] = v;
            setValuesForm(values_);
          }}
        />
      </div>
    </div>
  )

  let fieldsProposal2 = [

    {
      col: 6,
      type: "file",
      name: "file_pp",
      label: "Arquivo PI/PP",
      visible: !values.id_proposals

    },
    {
      col: 6,
      type: "file",
      name: "file_material",
      label: "Arquivo Material",
      visible: !values.id_proposals

    },
    {
      col: 12,
      type: "textarea",
      name: "observation",
      label: "Anotações",

    },
    {
      col: !values.id_proposals ? 8 : 12,
      type: "file",
      name: "file_checking",
      label: "Arquivo Checking",
      visible: !values.id_proposals
    },
    {
      col: !values.id_proposals ? 4 : 8,
      type: "text",
      name: "nf",
      label: "Nº NF",
    },

  ];

  let fieldsProduct = [
    {
      col: 12,
      type: "select",
      name: "fk_id_product",
      label: "Produto",
      required: true,
      options: products.filter(p => p.fk_id_vehicle == selectedVehicle).map(p => {
        return {
          value: p.id_product,
          label: p.name
        }
      }),
    },
    {
      col: 12,
      type: "text",
      name: "objective",
      label: "Objetivo",
    },
    {
      col: 12,
      type: "money",
      name: "price",
      label: "Preço",
    },
    {
      col: 12,
      type: "number",
      name: "quantity_hired",
      label: "Quantidade contratada",
    },
    {
      col: 12,
      type: "number",
      name: "negociation",
      label: "Desconto",
    },
    {
      col: 12,
      type: "date",
      name: "dt_start",
      label: "Data Inicial",
    },
    {
      col: 12,
      type: "date",
      name: "dt_end",
      label: "Data Final",
    },
    {
      col: 12,
      type: "number",
      name: "days",
      label: "Dias",
    },
  ];

  const handleProducts = (products) => {
    products.map((product, i) => {
      let value = product.negociation > 0 ? ((product.price - product.price * product.negociation / 100) * product.quantity_hired) : (product.price * product.quantity_hired)
      product.key = i
      product.final_value = value
      product.price = (product.price || 0).toFixed(2)
    })
    setProductsSelected([...products])
  }

  const addProduct = (product) => {
    product.key = productsSelected.length
    product.final_value = product.negociation > 0 ? ((product.price - product.price * product.negociation / 100) * product.quantity_hired) : (product.price * product.quantity_hired)
    productsSelected.push(product);
    updateValues();
    setOpenModalProduct(false);
    setProductsSelected(productsSelected);
    setValuesProduct([]);
  };

  const changeProduct = (field, id_product, value) => {
    productsSelected.filter((p) => p.key == id_product).map((p) => p[field] = value);
    if (field == 'days') {
      productsSelected.filter((p) => p.key == id_product)[0].dt_end = moment(productsSelected.filter((p) => p.key == id_product)[0].dt_start).add(value - 1, 'days').format('YYYY-MM-DD')
    }
    setProductsSelected([...productsSelected]);
    calcFinalValue();
    updateValues();
  };

  const calcFinalValue = () => {
    productsSelected.map(product => {
      let value = product.negociation > 0 ? ((product.price - product.price * product.negociation / 100) * product.quantity_hired) : (product.price * product.quantity_hired)
      product.final_value = value
    })
    setProductsSelected([...productsSelected])
  }

  const updateValues = () => {

    let gross_value = productsSelected.reduce((sum, item) => {
      let price = parseFloat(item.price)
      let negociation = parseFloat(item.negociation)
      let quantity_hired = parseInt(item.quantity_hired)
      return sum + (negociation > 0 ? ((price - price * negociation / 100) * quantity_hired) : (price * quantity_hired))
    }, 0)
    let discount_proposal = (gross_value * standardDiscount / 100).toFixed(2)
    let net_proposal = (gross_value - discount_proposal).toFixed(2)
    setGrossValueProposal(gross_value)
    setApprovedGrossValue(gross_value)
    setStandardDiscountProposal(discount_proposal)
    setStandardDiscountApproved(discount_proposal)

    setNetValueApproved(net_proposal)
    setNetValueProposal(net_proposal)
  }

  const removeProduct = (id_product) => {
    let products_filter = productsSelected.filter((p) => p.key != id_product);
    products_filter.map((p, i) => p.key = i)
    productsSelected = products_filter
    setProductsSelected(productsSelected);
    updateValues();
  };

  useEffect(() => {
    let gross_value = grossValueProposal ? grossValueProposal : 0
    let standard_discount = (gross_value * standardDiscount / 100).toFixed(2)
    let net_proposal = (gross_value - standard_discount).toFixed(2)
    setStandardDiscountProposal(standard_discount)
    setNetValueProposal(net_proposal)
    let approved_value = approvedGrossValue ? approvedGrossValue : 0
    let discount_approved = (approved_value * standardDiscount / 100).toFixed(2)
    let net_approved = (approved_value - discount_approved).toFixed(2)
    setStandardDiscountApproved(discount_approved)
    setNetValueApproved(net_approved)
  }, [grossValueProposal, approvedGrossValue, standardDiscount])


  const onSubmit = async () => {
    const notification = toast("Salvando dados");
    let valuesProposal = {
      standardDiscount: standardDiscount,
      grossValueProposal: grossValueProposal,
      standardDiscountProposal: standardDiscountProposal,
      netValueProposal: netValueProposal,
      approvedGrossValue: approvedGrossValue,
      standardDiscountApproved: standardDiscountApproved,
      netValueApproved: netValueApproved
    }

    valuesForm.fk_id_user = user.id_user
    valuesForm.month_sell = monthSell
    valuesForm.year_sell = yearSell


    let values = [valuesForm, productsSelected, valuesProposal, filesToRemove]
    if (Object.keys(valuesFiles).length > 0 && valuesForm.duplicate) {
      values.push(valuesFiles)
    }

    axios
      .post(
        Constants.APIEndpoints.PROPOSAL +
        (valuesForm.id_proposals && !valuesForm.duplicate ? "/updateProposal" : "/createProposal"),
        values
      )
      .then((res) => {
        toast.update(notification, { render: "Dados salvos com sucesso", type: toast.TYPE.SUCCESS, isLoading: false });
        setTimeout(function () {
          setPage("list");
          getData();

        }, 1000)

      })
      .catch((error) => {
        const translateProposalFields = {
          "dt_emission": "Data de ganho",
          "month_sell": "Mês de venda",
          "year_sell": "Ano de venda",
          "number": "Nº PI/PP",
          "fk_id_square": "Praça",
          "fk_id_responsable": "Responsável",
          "fk_id_client": "Cliente",
          "fk_id_vehicle": "Veículo",
          "fk_id_agency": "Agência",
          "month_placement": "Mês de veiculação",
          "sponsor_vehicle": "Representante do veículo",
          "comission_value_negotiated": "VL. Comissão Negociado",
          "dt_received_vehicle": "Data de Recebimento do Veículo",
          "comission_value": "VL. Comissão",
          "incentive": "Incentivo",
          "taxes_vehicle": "Impostos do veículo",
          "taxes_nf_percent": "%Impostos NF",
          "taxes_nf": "VL. Imposto NF",
          "nf_mosaic": "Nº NF Representante",
          "nf_due_date": "Data de Vencimento da NF",
          "dt_received_mosaic": "Data de Recebimento do Representante",
          "received_value": "Valor Recebido",
          "incentive_nf": "NF Incentivo",
          "dt_received_comission_executive": "Data de Pagamento de Comissão ao Veículo",
          'nf': 'Nº NF'
        }
        if (error.response.data.message == 'field is missing') {
          toast.update(notification, { render: `O status selecionado necessita dos seguintes campos preenchidos: ${error.response.data.fields.map(field => translateProposalFields[field]).join(', ')}`, type: toast.TYPE.ERROR, isLoading: false })
        } else {
          toast.update(notification, { render: "Erro ao salvar dos dados", type: toast.TYPE.ERROR, isLoading: false });

        }

      });
  };

  const removeFile = (field, file) => {
    let files = filesToRemove
    files.push(file)
    setFilesToRemove(files)
    valuesFiles[field] = valuesFiles[field].filter(v => v.filepath != file.filepath)
    setValuesFiles({ ...valuesFiles })
  }
  useEffect(() => {
    if (pageForm == 'files' && values && values.duplicate !== true) {
      axios
        .get(
          Constants.APIEndpoints.PROPOSAL + '/getfiles/' + values.id_proposals
        )
        .then((res) => {
          setValuesFiles(res.data)
        })
    }
  }, [pageForm])

  const fieldsFiles = [
    {
      col: 6,
      type: "file",
      name: "file_pp",
      label: "Arquivo PI/PP"
    },
    {
      col: 6,
      type: "file",
      name: "file_material",
      label: "Arquivo Material",
    },
    {
      col: 6,
      type: "file",
      name: "file_checking",
      label: "Arquivo Checking",
    },

  ]

  const saveFiles = (data) => {
    if (values.duplicate) {
      setValuesFiles(data)
      handlePageForm('proposals')
    } else {
      const notification = toast("Salvando dados");
      data.user = user
      data.id_proposals = values.id_proposals
      data.files_to_remove = filesToRemove
      axios
        .post(
          Constants.APIEndpoints.PROPOSAL + '/savefiles', data
        )
        .then((res) => {
          toast.update(notification, { render: "Dados salvos com sucesso", type: toast.TYPE.SUCCESS, isLoading: false });
          handlePageForm('proposals')
        })
    }
  }

  const getSingleVehicle = (id) => {
    axios
      .get(
        Constants.APIEndpoints.VEHICLE + "/getAllVehicles?id=" + id)
      .then((res) => {
        setValuesForm(prev => ({ ...prev, sponsor_vehicle: res.data[0].sponsor || '', fk_id_vehicle: id }))
      })
  }

  if (values.fk_id_square == undefined) {
    valuesForm.fk_id_square = params.default_square
  }
  valuesForm.month_sell = monthSell
  valuesForm.year_sell = yearSell

  return (
    <div ref={ref}>
      <CommonHeader title={values.id_proposals && values.duplicate == true ? "Duplicar Negócio" : values.id_proposals && values.duplicate == false ? "Editar Negócio" : "Criar Negócio"} onBack={() => setPage("list")}
        width={width}
        rightSide={rightSide}
      />
      <CommonForm
        values={valuesForm}
        fields={fieldsProposal}
        hasHeader={true}
        onChangeField={(f, v) => {
          let values_ = { ...valuesForm }
          values_[f.name] = v;
          setValuesForm(values_);
          if (f.name == "fk_id_vehicle") {
            setProductsSelected([])
            setSelectedVehicle(v)
            getSingleVehicle(v)
          }
          if (f.name == 'dt_emission') {
            let values_ = valuesForm
            values_.dt_emission = v
            setMonthSell(moment(v).month())
            setYearSell(moment(v).year())
            setValuesForm({ ...values_ })
          }

        }}
      />
      <div>
        {user.role !== 'opec' && (
          <Button
            className="whitespace-nowrap"
            variant="contained"
            color="primary"
            onClick={() => {
              if (params.use_new_products_screen_proposals == true) {
                setNewProductDialog(true)
              } else {
                setOpenModalProduct(true)
              }
            }
            }
            style={{ marginLeft: 20 }}
          >
            <span className="hidden sm:flex">Adicionar Produto</span>
          </Button>
        )}
        <CommonDialog
          open={newProductDialog}
          onClose={() => setNewProductDialog(false)}
          title='Adicionar Produtos'
          fullWidth={true}
          width='xl'
        >
          <ProposalProductList onBack={() => setNewProductDialog(false)} vehicle={valuesForm.fk_id_vehicle} id_proposals={valuesForm.id_proposals || 0} />

        </CommonDialog>
        <CommonDialog
          open={openModalProduct}
          onClose={() => setOpenModalProduct(false)}
          title="Adicionar Novo Produto"
          width="xs"
          keep={true}
        >
          <CommonForm
            values={valuesProduct}
            fields={fieldsProduct}
            onChangeField={(f, v) => {
              let newProduct = valuesProduct
              if (f.name == 'fk_id_product') {
                let product = products.filter(p => p.id_product == v)[0]
                newProduct.objective = product.objective
                newProduct.price = parseFloat(product.value)
                newProduct.name = product.name
              }
              if (f.name == 'dt_end' && valuesProduct.dt_start) {
                newProduct.days = moment(v).diff(moment(valuesProduct.dt_start), 'days') + 1
              }
              if (f.name == 'dt_start' && valuesProduct.dt_end) {
                newProduct.days = moment(v).diff(moment(valuesProduct.dt_start), 'days') + 1
              }
              if (f.name == 'days' && valuesProduct.dt_end) {
                newProduct.dt_end = moment(valuesProduct.dt_start).add(v - 1, 'day').format('YYYY-MM-DD')
              }
              newProduct[f.name] = v;
              setValuesProduct({ ...newProduct })
            }}
            onSubmit={addProduct}
          />
        </CommonDialog>
        {params.use_new_products_screen_proposals == false ? (


          productsSelected.length > 0 && (
            <div style={{ padding: 20 }}>
              <table style={{ width: "100%" }}>
                <thead
                  style={{ backgroundColor: "var(--purple)", marginBottom: 10 }}
                >
                  <tr style={{ color: "black" }}>
                    <th style={{ color: "black" }}>Produto</th>
                    <th style={{ color: "black" }}>Objetivo</th>
                    <th style={{ color: "black" }}>Preço</th>
                    <th style={{ color: "black" }}>Qtd. Contratada</th>
                    <th style={{ color: "black" }}>Negociação </th>
                    <th style={{ color: "black" }}>Dt. Inicial</th>
                    <th style={{ color: "black" }}>Dt. Final</th>
                    <th style={{ color: "black" }}>Dias</th>
                    <th style={{ color: "black" }}>Valor Final</th>
                    <th style={{ color: "black" }}>Remover</th>
                  </tr>
                </thead>

                <tbody style={{ backgroundColor: "var(--purple)", width: "100%" }}>
                  {productsSelected.map((p, i) => (
                    <tr key={p.fk_id_product} style={{ color: "black" }}>
                      <td className="table_input">
                        <Input name="product_name" disabled={['financeiro', 'opec'].includes(user.role)} onChange={(evt) => changeProduct('name', i, evt.target.value)} value={p.name}></Input>
                      </td>
                      <td className="table_input"><Input name="objective_" disabled={['financeiro', 'opec'].includes(user.role)} onchange={(evt) => changeProduct("objective", i, evt.target.value)} value={p.objective}></Input></td>
                      <td className="table_input"><Input type="number" money={true} disabled={['financeiro', 'opec'].includes(user.role)} step="any" name="price_" onchange={(evt) => changeProduct("price", i, evt.target.value)} value={p.price}></Input></td>
                      <td className="table_input"><Input name="quantity_hired_" disabled={['financeiro', 'opec'].includes(user.role)} onchange={(evt) => changeProduct("quantity_hired", i, evt.target.value)} value={p.quantity_hired}></Input></td>
                      <td className="table_input"><Input name="negociation_" disabled={['financeiro', 'opec'].includes(user.role)} onchange={(evt) => changeProduct("negociation", i, evt.target.value)} value={p.negociation}></Input></td>
                      <td className="table_input"><Input type="date" name="dt_start_" disabled={['financeiro', 'opec'].includes(user.role)} onchange={(evt) => changeProduct("dt_start", i, evt.target.value)} value={p.dt_start}></Input></td>
                      <td className="table_input"><Input type="date" name="dt_end_" disabled={['financeiro', 'opec'].includes(user.role)} onchange={(evt) => changeProduct("dt_end", i, evt.target.value)} value={p.dt_end}></Input></td>
                      <td className="table_input"><Input type="number" name="days" disabled={['financeiro', 'opec'].includes(user.role)} onchange={(evt) => changeProduct("days", i, evt.target.value)} value={moment(p.dt_end).diff(moment(p.dt_start), 'day') + 1}></Input></td>
                      <td className="table_input"><Input type="number" name="final_value" disabled value={p.final_value.toFixed(2)}></Input></td>
                      <td>
                        {!['financeiro', 'opec'].includes(user.role) && (
                          <div className="buttonRemoveProduct">
                            <button onClick={() => removeProduct(i)}>X</button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )) : null}
        {params.use_new_products_screen_proposals == false ? (

          < div style={{ padding: 20, width: '100%' }}>
            <div style={{ display: 'inline-flex', width: '100%' }}>
              <Input disabled={['financeiro', 'opec'].includes(user.role)} label="Desconto padrão" value={standardDiscount} onchange={(evt) => setStandardDiscount(evt.target.value)} />
              <Input disabled={['financeiro', 'opec'].includes(user.role)} label="Valor Bruto Aprovado" money={true} value={grossValueProposal} onchange={(evt) => setGrossValueProposal(evt.target.value)} />
              <Input disabled={['financeiro', 'opec'].includes(user.role)} label="Desconto Padrão Aprovado" money={true} value={standardDiscountProposal} onchange={(evt) => setStandardDiscountProposal(evt.target.value)} />
              <Input disabled={['financeiro', 'opec'].includes(user.role)} label="Valor Líquido Aprovado" money={true} value={netValueProposal} onchange={(evt) => setNetValueProposal(evt.target.value)} />

            </div>
            <div style={{ display: 'inline-flex', width: '100%' }}>

              <Input hidden={true} />

              <Input disabled={['financeiro', 'opec'].includes(user.role)} label="Valor Bruto Faturado" money={true} value={approvedGrossValue} onchange={(evt) => setApprovedGrossValue(evt.target.value)} />
              <Input disabled={['financeiro', 'opec'].includes(user.role)} label="Desconto Padrão Faturado" money={true} value={standardDiscountApproved} onchange={(evt) => setStandardDiscountApproved(evt.target.value)} />
              <Input disabled={['financeiro', 'opec'].includes(user.role)} label="Valor Líquido Faturado" money={true} value={netValueApproved} onchange={(evt) => setNetValueApproved(evt.target.value)} />

            </div>

          </div>
        ) : null}
      </div>

      {
        pageForm == 'files' && (
          <CommonDialog
            open={true}
            onClose={() => handlePageForm('proposal')}
            title="Gerenciar Arquivos"
            width="md"
            keep={true}
          >
            <CommonForm
              values={valuesFiles}
              fields={fieldsFiles}
              onChangeField={(f, v) => {
              }}
              removeFile={removeFile}

              onSubmit={saveFiles}
            />
          </CommonDialog>

        )
      }
      <CommonForm
        values={valuesForm}
        fields={fieldsProposal2}
        onChangeField={(f, v) => {
          let values_ = { ...valuesForm }
          values_[f.name] = v;
          setValuesForm(values_);

        }}
        removeFile={removeFile}
        onSubmit={onSubmit}
        button={{ label: 'SALVAR' }}

      />
    </div >
  );
}
