import React, { useEffect, memo, useState } from 'react';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withReducer from 'app/store/withReducer';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import NotificationCard from './NotificationCard';

import reducer from './store';
import { closeNotificationPanel, toggleNotificationPanel } from './store/stateSlice';
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import moment from 'moment-timezone'
import Store from 'app/utils/Store'
import MailingList from 'app/main/mailings/MailingList';
import { eventBus } from "app/utils/EventListener";

let logged_user = Store.USER

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: 320,
  },
}));


function NotificationPanel(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector(({ notificationPanel }) => notificationPanel.state);
  //const notifications = useSelector(selectNotifications);
  const [notifications, setNotifications] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [mailingDialog, setMailingDialog] = useState(false)
  const [fk_id_mailing, setFk_id_mailing] = useState(0)

  useEffect(() => {
    if (!!logged_user) {
      getNotifications()
    }
  }, [])

  const getNotifications = () => {
    axios.post(Constants.APIEndpoints.NOTIFICATION + '/getNotificationsByUser', { notified: true }).then(res => {
      setNotifications(res.data)
    })
  }

  useEffect(() => {
    if (state) {
      dispatch(closeNotificationPanel());
    }
  }, [location, dispatch]);

  function handleClose() {
    dispatch(closeNotificationPanel());
  }

  function handleClick(notification) {
    if (notification.link == '/nleads') {
      if (location.pathname === '/nleads') {
        eventBus.emit('openLead',
          {
            fk_id_card: notification.fk_id_item,
            mode: 'view',
          })
        dispatch(closeNotificationPanel());

      } else {
        history.push({
          pathname: '/nleads',
          state: {
            fk_id_card: notification.fk_id_item,
            mode: 'view',
          },
        });
      }
    } else {
      if (location.pathname === '/propostas') {
        eventBus.emit('openproposals',
          {
            fk_id_proposals: notification.fk_id_item,
            mode: 'edit',
          })
        dispatch(closeNotificationPanel());

      } else {
        history.push({
          pathname: '/propostas',
          state: {
            fk_id_proposals: notification.fk_id_item,
            mode: 'edit',
          },
        });
      }
    }
  }

  function handleDismiss(id_notification) {
    axios.post(Constants.APIEndpoints.NOTIFICATION + '/markNotificationAsRead', { id_notification }).then(res => {
      getNotifications()
    })
  }

  function handleDismissAll() {
    axios.post(Constants.APIEndpoints.NOTIFICATION + '/markNotificationAsRead').then(res => {
      setNotifications([])
    })
  }

  return (
    <SwipeableDrawer
      classes={{ paper: clsx(classes.root) }}
      open={state}
      anchor="right"
      onOpen={(ev) => { }}
      onClose={(ev) => dispatch(toggleNotificationPanel())}
      disableSwipeToOpen
    >

      {mailingDialog ? (

        <MailingList fk_id_mailing={fk_id_mailing} onClose={() => setMailingDialog(false)} />
      ) : null}

      <IconButton className="m-4 absolute top-0 right-0 z-999" onClick={handleClose}>
        <Icon color="action">close</Icon>
      </IconButton>
      {notifications.length > 0 ? (
        <FuseScrollbars className="p-16">
          <div className="flex flex-col">
            <div className="flex justify-between items-end pt-36 mb-36">
              <Typography className="text-28 font-semibold leading-none">Notificações</Typography>
              <Typography
                className="text-12 underline cursor-pointer"
                color="secondary"
                onClick={handleDismissAll}
              >
                Limpar
              </Typography>
            </div>
            {notifications.map((item) => (
              <NotificationCard
                key={item.id}
                className="mb-16"
                item={item}
                onClose={handleDismiss}
                onClick={handleClick}
              />
            ))}
          </div>
        </FuseScrollbars>
      ) : (
        <div className="flex flex-1 items-center justify-center p-16">
          <Typography className="text-24 text-center" color="textSecondary">
            Sem notificações por enquanto.
          </Typography>
        </div>
      )}
    </SwipeableDrawer>
  );
}

export default withReducer('notificationPanel', reducer)(memo(NotificationPanel));
