import React, { useState, useEffect } from "react"
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
const FinancesByUsers = ({ }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        axios.post(Constants.APIEndpoints.FINANCE + '/financesbyusers', '').then(res => {
        })
    }, [])

    return (
        <div>
            dsajiodjasio
        </div>
    )
}

export default FinancesByUsers