import React from "react";
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from 'app/utils/Constants';
import { eventBus } from "app/utils/EventListener";

const WinCard = ({
    open,
    onClose,
    card,
    getData,
    id_card_activity
}) => {
    const createNewBusiness = (values) => {
        axios.post(Constants.APIEndpoints.PROPOSAL + '/createProposalFromCard', { number: values.number, ...card, id_card_activity: id_card_activity }).then(res => {
            onClose()
            getData({ inProgress: true })
            eventBus.emit('won-modal-card', {})

        })
    }
    return (
        <div>
            <CommonDialog
                open={open}
                onClose={onClose}
                title="Deseja criar um negócio a partir deste card?"
                width="xl"
            >
                <CommonForm
                    values={card}
                    fields={[
                        {
                            col: 12,
                            name: 'number',
                            label: 'Qual o número PI/PP desse negócio?',
                            type: 'text',
                            required: true
                        }
                    ]}
                    onSubmit={createNewBusiness}
                />

            </CommonDialog>

        </div>
    )
}
export default WinCard