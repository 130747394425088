import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import NotificationPanel from 'app/fuse-layouts/shared-components/notificationPanel/NotificationPanel';
import MailingPanel from 'app/main/mailings/MailingPanel';
import FunnelPanel from 'app/main/leads/FunnelPanel';
import React, { memo } from 'react';

function RightSideLayout1(props) {
  return (
    <>
      <QuickPanel />
      <NotificationPanel />
      <MailingPanel />
      <FunnelPanel />
    </>
  );
}

export default memo(RightSideLayout1);
