import React from 'react'
import { Typography } from "@mui/material"
import moment from "moment-timezone"
const Events = ({
    events
}) => {
    return (
        <div>
            <Typography class="text-xl font-semibold title">SEUS EVENTOS DE HOJE</Typography>
            {events.map(event => (
                <div className="eventActivity">
                    <Typography class='text-lg font-medium'>{event.title}</Typography>
                    <Typography class='text-base'>{`De ${moment(event.start).format('DD/MM/YYYY HH:mm')} à ${moment(event.end).format('DD/MM/YYYY HH:mm')}`}</Typography>
                </div>
            ))}
        </div>
    )
}

export default Events