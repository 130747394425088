import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import Error404Page from 'app/main/404/Error404Page';
import LoginConfig from 'app/main/login/LoginConfig'
import UsersList from 'app/main/users/UserList';
import VehicleList from 'app/main/vehicles/VehicleList';
import ClientList from 'app/main/clients/ClientList';
import AgencyList from 'app/main/agencies/AgencyList';
import MailingList from 'app/main/mailings/MailingList';
import ProductList from 'app/main/products/ProductList';
import SquareList from 'app/main/squares/SquareList';
import StatusList from 'app/main/status/StatusList';
import ProposalList from 'app/main/proposals/ProposalList';
import CheckingList from 'app/main/checking/CheckingList';
import OpecList from 'app/main/opec/OpecList';
import ContactsList from 'app/main/contacts/ContactsList';
import TeamList from 'app/main/teams/TeamList';
import TagsList from 'app/main/tags/TagsList';
import Dashboard from 'app/main/dashboard/DashboardForm'
import Home from 'app/main/home/home'
import Params from 'app/main/configuration/params';
import Report from 'app/main/reports/Report';
import FinancesByUsers from 'app/main/finances/FinancesByUsers';
import Dailies from 'app/main/finances/Dailies';
import Store from 'app/utils/Store'
import Holidays from 'app/main/configuration/holidays';
import Breakeven from 'app/main/configuration/breakeven';
import GoalsGlobal from 'app/main/goals/GoalsGlobal';
import VehiclesGoals from 'app/main/goals/VehiclesGoals';
import TeamsGloabl from 'app/main/goals/TeamsGoals';
import MonthsGoals from 'app/main/goals/MonthsGoals';
import LookerStudio from 'app/main/lookerStudio/LookerStudio';
import GroupList from 'app/main/groups/GroupList';
import Comissions from 'app/main/finances/Comissions';
import Calendar from 'app/main/calendar/Calendar';
import AccessList from 'app/main/access/AccessList';
import LogsList from 'app/main/logs/LogsList';
import Leads from 'app/main/leads/Leads';
import OwnDash from 'app/main/dashboard/OwnDash';
import Solicitation from 'app/main/solicitations/Solicitation';
import ReportByTech from 'app/main/leads/ReportByTech';
import Excel from 'app/main/excel/Excel';
import QA from 'app/main/qa/QA';
import QuestionsDefaultList from 'app/main/questionsDefault/QuestionsDefaultList';
import JustifyList from 'app/main/justify/JustifyList';
import ActivitiesPage from 'app/main/activities/ActivitiesPage';
import NewLeads from 'app/main/newLeads/NewLeads';
import CheckingReport from 'app/main/checkingReport/CheckingReport';
import DailyReport from 'app/main/reports/DailyReport';
let user = Store.USER
let loggedIn = false;

if (user && user.id_user > 0) {
  loggedIn = true;
}

const routeConfigs = [LoginConfig];
const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  {
    exact: true,
    path: '/',
    component: () => loggedIn ? (['opec', 'opecadmin'].includes(user.fk_id_role) ? <ProposalList /> : <Home />) : <Redirect to="/login" />,
  },
  {
    path: '/loading',
    exact: true,
    component: () => <FuseLoading />,
  },
  {
    path: '/dashboard',
    exact: true,
    component: () => loggedIn ? <Dashboard /> : <Redirect to="/login" />,
  },
  {
    path: '/qa',
    exact: true,
    component: () => loggedIn ? <QA /> : <Redirect to="/login" />,
  },
  {
    path: '/checkingreport',
    exact: true,
    component: () => loggedIn ? <CheckingReport /> : <Redirect to="/login" />,
  },
  {
    path: '/questionsdefault',
    exact: true,
    component: () => loggedIn ? <QuestionsDefaultList /> : <Redirect to="/login" />,
  },
  {
    path: '/justify',
    exact: true,
    component: () => loggedIn ? <JustifyList /> : <Redirect to="/login" />,
  },
  {
    path: '/dailyreport',
    exact: true,
    component: () => loggedIn ? <DailyReport /> : <Redirect to="/login" />,
  },
  {
    path: '/activities',
    exact: true,
    component: () => loggedIn ? <ActivitiesPage /> : <Redirect to="/login" />,
  },
  {
    path: '/excel',
    exact: true,
    component: () => loggedIn ? <Excel /> : <Redirect to="/login" />,
  },
  {
    path: '/solicitations',
    exact: true,
    component: () => loggedIn ? <Solicitation /> : <Redirect to="/login" />,
  },
  {
    path: '/techreport',
    exact: true,
    component: () => loggedIn ? <ReportByTech /> : <Redirect to="/login" />,
  },
  {
    path: '/owndash',
    exact: true,
    component: () => loggedIn ? <OwnDash /> : <Redirect to="/login" />,
  },
  {
    path: '/usuarios',
    exact: true,
    component: () => loggedIn ? <UsersList /> : <Redirect to="/login" />,
  },
  {
    path: '/teams',
    exact: true,
    component: () => loggedIn ? <TeamList /> : <Redirect to="/login" />,
  },
  {
    path: '/groups',
    exact: true,
    component: () => loggedIn ? <GroupList /> : <Redirect to="/login" />,
  },
  {
    path: '/leads',
    exact: true,
    component: () => loggedIn ? <Leads /> : <Redirect to="/login" />,
  },
  {
    path: '/nleads',
    exact: true,
    component: () => loggedIn ? <NewLeads /> : <Redirect to="/login" />,
  },
  {
    path: '/leadsv',
    exact: true,
    component: () => loggedIn ? <Leads visuplac={true} /> : <Redirect to="/login" />,
  },
  {
    path: '/params',
    exact: true,
    component: () => loggedIn ? <Params /> : <Redirect to="/login" />,
  }, {
    path: '/reports',
    exact: true,
    component: () => loggedIn ? <Report /> : <Redirect to="/login" />,
  },
  {
    path: '/lookerstudio',
    exact: true,
    component: () => loggedIn ? <LookerStudio /> : <Redirect to="/login" />,
  },
  {
    path: '/calendar',
    exact: true,
    component: () => loggedIn ? <Calendar /> : <Redirect to="/login" />,
  },
  {
    path: '/logs',
    exact: true,
    component: () => loggedIn ? <LogsList /> : <Redirect to="/login" />,
  },
  {
    path: '/access',
    exact: true,
    component: () => loggedIn ? <AccessList /> : <Redirect to="/login" />,
  },
  {
    path: '/financesusers',
    exact: true,
    component: () => loggedIn ? <FinancesByUsers /> : <Redirect to="/login" />,
  },
  {
    path: '/financedailyvehicle',
    exact: true,
    component: () => loggedIn ? <Dailies type={'vehicle'} /> : <Redirect to="/login" />,
  },
  {
    path: '/financedailysquare',
    exact: true,
    component: () => loggedIn ? <Dailies type={'square'} /> : <Redirect to="/login" />,
  },
  {
    path: '/financedailyteam',
    exact: true,
    component: () => loggedIn ? <Dailies type={'team'} /> : <Redirect to="/login" />,
  },
  {
    path: '/breakeven',
    exact: true,
    component: () => loggedIn ? <Breakeven /> : <Redirect to="/login" />,
  },
  {
    path: '/tags',
    exact: true,
    component: () => loggedIn ? <TagsList /> : <Redirect to="/login" />,
  },
  {
    path: '/veiculos',
    exact: true,
    component: () => loggedIn ? <VehicleList /> : <Redirect to="/login" />,
  },
  {
    path: '/goalsglobal',
    exact: true,
    component: () => loggedIn ? <GoalsGlobal /> : <Redirect to="/login" />,
  },
  {
    path: '/goalsbymonth',
    exact: true,
    component: () => loggedIn ? <MonthsGoals /> : <Redirect to="/login" />,
  },
  {
    path: '/goalsbyvehicle',
    exact: true,
    component: () => loggedIn ? <VehiclesGoals /> : <Redirect to="/login" />,
  },
  {
    path: '/goalsbyteam',
    exact: true,
    component: () => loggedIn ? <TeamsGloabl /> : <Redirect to="/login" />,
  },
  {
    path: '/contacts',
    exact: true,
    component: () => loggedIn ? <ContactsList /> : <Redirect to="/login" />,
  },
  {
    path: '/clientes',
    exact: true,
    component: () => loggedIn ? <ClientList /> : <Redirect to="/login" />,
  },

  {
    path: '/agencias',
    exact: true,
    component: () => loggedIn ? <AgencyList /> : <Redirect to="/login" />,
  },

  {
    path: '/mailings',
    exact: true,
    component: () => loggedIn ? <MailingList /> : <Redirect to="/login" />,
  },

  {
    path: '/produtos',
    exact: true,
    component: () => loggedIn ? <ProductList /> : <Redirect to="/login" />,
  },

  {
    path: '/pracas',
    exact: true,
    component: () => loggedIn ? <SquareList /> : <Redirect to="/login" />,
  },

  {
    path: '/status',
    exact: true,
    component: () => loggedIn ? <StatusList /> : <Redirect to="/login" />,
  },
  {
    path: '/holidays',
    exact: true,
    component: () => loggedIn ? <Holidays /> : <Redirect to="/login" />,
  },

  {
    path: '/propostas',
    exact: true,
    component: (state) => loggedIn ? <ProposalList state={state} /> : <Redirect to="/login" />,
  },

  {
    path: '/pipp',
    exact: true,
    component: () => loggedIn ? <OpecList /> : <Redirect to="/login" />,
  },

  {
    path: '/checkings',
    exact: true,
    component: () => loggedIn ? <CheckingList /> : <Redirect to="/login" />,
  },

  {
    path: '/comissionuser',
    exact: true,
    component: () => loggedIn ? <Comissions /> : <Redirect to="/login" />,
  },
  {
    path: '/home',
    exact: true,
    component: () => loggedIn ? <Home /> : <Redirect to="/login" />,
  },
  {
    path: '/404',
    component: () => <Error404Page />,
  },
  {
    path: '*',
    component: () => loggedIn ? <CheckingList /> : <Redirect to="/login" />,
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
