import React, { useState } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { useResizeDetector } from 'react-resize-detector';

const SolicitationForm = ({ setPage, getData }) => {
    const [valuesForm, setValuesForm] = useState({})
    const { width, height, ref } = useResizeDetector();

    const fields = [
        {
            col: 8,
            type: "text",
            name: "subject",
            label: "Assunto",
            required: true
        },
        {
            col: 4,
            type: 'select',
            name: 'type',
            label: 'Tipo',
            options: [
                { value: 1, label: 'Report de erro' },
                { value: 2, label: 'Solicitação' }
            ],
            required: true
        },
        {
            col: 12,
            type: "textarea",
            name: "description",
            label: "Descrição",
        },
    ];


    const onSubmit = () => {
        axios.post(Constants.APIEndpoints.SOLICITATION + '/createsolicitation', valuesForm).then(res => {
            setPage('list')
            getData();
        })
    }

    return (
        <div ref={ref}>
            <CommonHeader title="Fazer Solicitação"
                width={width}
                onBack={() => setPage('list')} />
            <CommonForm
                values={valuesForm}
                hasHeader={true}
                fields={fields}
                onChangeField={(f, v) => {
                    let values = valuesForm
                    values[f.name] = v;
                    setValuesForm(values)
                }}
                onSubmit={onSubmit}
            />

        </div>
    );
}

export default SolicitationForm