import React, { forwardRef, useRef, useEffect, useState, useCallback } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import clsx from "clsx";
import CommonTablePaginationActions from "./CommonTablePaginationActions";
import CommonHeader from "./CommonHeader";
import Input from "@material-ui/core/Input";
import { useResizeDetector } from 'react-resize-detector';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";

import './index.css'
import FilterColumnsByHeader from "./FilterColumnsByTable";
import { Tooltip } from "@material-ui/core";
const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} />
    </>
  );
});

const CommonTable = ({
  id,
  columns,
  data,
  onRowClick,
  onAdd,
  icon,
  newText,
  onBack,
  headerTitle,
  underHeader,
  updateValues,
  noHeader,
  miniVersion,
  fullWhite,
  totalData,
  pagination,
  noSearch,
  filterColumnsHeader,
  subTable,
  isSubTable,
  selectedLine,
  rowsClicked = [],
  headerContent,
  showEditButton,
  primaryKey = '',
  rightSide,
  noPagination
}) => {
  const [dataTable, setDataTable] = useState(data);
  const [filters, setFilters] = useState([])
  const { width, height, ref, } = useResizeDetector();
  const [columnsSelected, setColumnsSelected] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  const [sortColumn, setSortColumn] = useState({})
  //const [pageSize, setPageSize] = useState(10)
  const [openEditDialog, setOpenEditDialog] = useState(false)

  const useStyles = makeStyles({
    tableContainer: {
      maxHeight: '70vh',
    },
    tableBody: {
      maxHeight: '100%',
      overflowY: 'scroll',
    },
    tableHead: {
      position: 'sticky',
      top: 0,
      zIndex: 1
    }
  });

  const classes = useStyles();

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,

    state: { pageSize }
  } = useTable(
    {
      columns,
      data: dataTable,
      autoResetPage: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((_columns) => [
        {
          id: "selection",
          sortable: false,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              {/*
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                onClick={(ev) => ev.stopPropagation()}
              />
         */}
            </div>
          ),
        },
        ..._columns,
      ]);
    }
  );

  useEffect(() => {
    data.map(d => {
      Object.keys(d).forEach((item) => {
        if (d[item] == "undefined") {
          d[item] = ''
        }
      })
    })
    setDataTable(data)
  }, [data])

  useEffect(() => {
    const columnsSaved = localStorage.getItem(id)
    if (columnsSaved) {
      setColumnsSelected(columnsSaved.split(','))
    } else {
      const allColumns = headerGroups[0].headers.filter(h => typeof h.Header == 'string')
      setColumnsSelected(allColumns.map(c => c.id))
    }
    setPageSize(25)
  }, [])

  useEffect(() => {
    if (updateValues) {
      updateValues(dataTable)
    }
  }, [dataTable])


  const lateSearch = useCallback(
    _.debounce((filter, limit, page) => pagination(filter, limit, page), 1000),
    [],
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
    setPageIndex(newPage)
    if (pagination) {
      pagination(filters, pageSize, newPage)
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (pagination) {
      pagination(filters, parseInt(event.target.value), pageIndex)
    }
    setPageSize(Number(event.target.value));
  };

  const filteringData = (text) => {
    const newData = data.filter((obj) =>
      JSON.stringify(obj).toLowerCase().includes(text.toLowerCase())
    );
    setDataTable(newData);
  };

  const filterColumn = (column, text) => {
    filters[column] = text
    setFilters(filters)

    if (pagination) {
      lateSearch(filters, pageSize, pageIndex)
    } else {
      const newData = data.filter(function (element) {
        return Object.keys(filters).every(filter => {
          return element[filter] && element[filter].toString().toLowerCase().includes(filters[filter] && filters[filter].toLowerCase())
        })
      })

      setDataTable(newData)
    }
  }

  const sortColumns = (column, order) => {
    order = order == 'asc' ? 'desc' : 'asc'

    filters.sort = {
      column: column,
      order: order
    }
    setSortColumn({
      column: column,
      order: order
    })
    if (pagination) {
      pagination(filters, pageSize, pageIndex)
    }

  }

  const filterColuns = (values) => {
    localStorage.setItem(id, [...values.columns])
    setColumnsSelected([...values.columns])
  }


  const onSubmitColumns = (values) => {
    filterColuns(values)
    setOpenEditDialog(false)
  }


  return (
    <div ref={ref}
      style={{ overflowX: noHeader === true ? 'auto' : 'visible', minHeight: '20vh' }}

    >
      {openEditDialog && (
        <CommonDialog
          open={true}
          onClose={() => setOpenEditDialog(false)}
          title="Selecionar colunas"
        >
          <CommonForm
            fields={[
              {
                col: 12,
                name: 'columns',
                type: 'listCheckbox',
                label: 'Colunas',
                options: headerGroups[0].headers.filter(f => typeof f.Header == 'string').map(c => ({ value: c.id, label: c.Header }))
              }
            ]}
            values={{ columns: columnsSelected }}
            onSubmit={onSubmitColumns}

          />

        </CommonDialog>
      )}

      {noHeader !== true && (
        <CommonHeader
          title={headerTitle}
          rightSide={rightSide}
          headerContent={headerContent}
          filterData={filteringData}
          onAdd={onAdd}
          search={!noSearch}
          icon={icon}
          miniVersion={miniVersion}
          newText={newText}
          isList={true}
          onBack={onBack}
          width={width}
          isFromCommonTable={true}
          columnsTable={headerGroups[0].headers}
          filterColuns={filterColuns}
          filterColumnsHeader={filterColumnsHeader}
          columnsSelected={columnsSelected}
        />
      )}
      <div className="flex flex-col min-h-full sm:border-1 sm:rounded-16 overflow-hidden"
        style={{ marginTop: noHeader ? -10 : miniVersion ? 70 : 120 }}
      >
        {underHeader ?
          underHeader
          : null}
        {showEditButton ? (
          <Tooltip title={<h4>Escolher Colunas</h4>}>
            <IconButton
              style={{ justifyContent: 'start', width: 45, borderRadius: 0 }}
              onClick={(ev) => {
                setOpenEditDialog(true)
              }}
            >

              <Icon>edit</Icon>
            </IconButton></Tooltip>
        ) : null}
        <TableContainer className={classes.tableContainer}
        >
          <Table
            {...getTableProps()}
            stickyHeader
            className="simple borderless"
          // style={{ minHeight: '40vh' }}
          >

            <TableHead className={classes.tableHead}
              style={{
                backgroundColor: isSubTable ? '#252f3e' : miniVersion || fullWhite ? 'white' : '#f6f7f9',
              }}
            >

              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {isSubTable ? (
                    <td>
                      <FilterColumnsByHeader
                        columnsTable={headerGroups[0].headers}
                        filterColuns={filterColuns}
                        filterColumnsHeader={filterColumnsHeader}
                        columnsSelected={columnsSelected}
                      />
                    </td>
                  ) : null}
                  {headerGroup.headers.filter(column => column.filter == filterColumnsHeader || column.filter == undefined).filter(c => columnsSelected.includes(c.id.toString()) || c.id.toString() == 'action' || c.visible == true).map((column) => (

                    <td>
                      <TableCell
                        className="whitespace-nowrap p-4 md:p-12"
                        {...(!column.sortable
                          ? column.getHeaderProps()
                          : column.getHeaderProps(column.getSortByToggleProps()))}
                        style={{
                          height: 80,
                          fontSize: miniVersion ? 14 : 18, fontWeight: miniVersion ? 400 : 'bold', backgroundColor: isSubTable ? '#252f3e' : miniVersion || fullWhite ? 'white' : '',
                          color: isSubTable ? 'white' : ''
                        }}
                      >
                        {column.overHeader && (
                          <div className="overHeader"
                            style={{ ...column.overHeader.styles }}>
                            <p><strong>{column.overHeader.title}</strong></p>

                          </div>
                        )}
                        {column.render("Header")}
                        {column.sortable ? (
                          <TableSortLabel

                            active={sortColumn.column == column.id}
                            onClick={() => sortColumns(column.id, sortColumn.order ? sortColumn.order : 'asc')}
                            direction={sortColumn.order ? sortColumn.order : 'asc'}
                          />
                        ) : null}
                      </TableCell>
                      {!['selection', 'action'].includes(column.id) && column.noSearchable != true ? (
                        <Input
                          placeholder="Buscar"
                          className="flex flex-1 px-16 searchColumn"
                          disableUnderline
                          fullWidth
                          //  value={searchText}
                          inputProps={{
                            "aria-label": "Search",
                          }}
                          style={{ color: isSubTable ? 'white' : '' }}
                          onChange={(evt) => filterColumn(column.id, evt.target.value)}
                        />
                      ) : null}
                    </td>

                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody className={classes.tableBody}
              id='tableContainer'
            >
              {page.map((row, i) => {
                let index = rowsClicked.findIndex(r => r[primaryKey] == row.original[primaryKey])
                let customClass = ''
                if (index > -1) {
                  customClass = 'selectedCell'
                }

                prepareRow(row);
                return (
                  <React.Fragment
                  >

                    <TableRow
                      {...row.getRowProps()}
                      className={"truncate cursor-pointer cellTable " + customClass}
                      style={{ backgroundColor: fullWhite ? 'white' : '' }}
                    >
                      {isSubTable ? (
                        <td>
                        </td>
                      ) : null}
                      {row.cells.filter(c => c.column.filter == filterColumnsHeader || c.column.filter == undefined).filter(c => columnsSelected.includes(c.column.id) || c.column.id.toString() == 'action' || c.column.visible == true).map((cell) => {
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            className={clsx("p-4 md:p-12", cell.column.className)}
                            onClick={onRowClick && !['opensub', 'action'].includes(cell.column.id.toString()) ? (ev) => onRowClick(ev, row) : null}
                            style={{ textAlign: fullWhite ? 'center' : '' }}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {row.id == selectedLine ? (
                      <tr>
                        <td
                          className="subtable"
                          colSpan={row.cells.filter(c => columnsSelected.includes(c.column.id) || c.column.id.toString() == 'action' || c.column.visible == true).length}>
                          {subTable}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!noPagination && (
          <TablePagination
            component="div"
            classes={{
              root: "flex-shrink-0 border-t-1",
            }}
            rowsPerPageOptions={[
              10,
              25,
              50,
              { label: "Todos", value: totalData ?? data.length + 1 },
            ]}
            colSpan={5}
            count={totalData ?? data.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            SelectProps={{
              inputProps: { "aria-label": "Itens por página" },
              native: false,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={CommonTablePaginationActions}
          />
        )}
      </div>
    </div >
  );
};

CommonTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
};

export default CommonTable;
