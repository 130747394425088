import React, { useEffect, useState } from 'react'
import moment from 'moment'
import CommonTable from 'app/components/table/CommonTable'
import { Link } from 'react-router-dom';
import axios from 'app/utils/AxiosConfig'
import CommonForm from "app/components/form/CommonForm";
import Constants from 'app/utils/Constants'
import CommonDialog from "app/components/dialog/CommonDialog";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
const LeadsActivities = ({
    id,
    setOpenLossCard,
    setOpenWinCard,
    usersOptions,
    lead
}) => {
    const [activities, setActivities] = useState([])
    const [openCreateActivity, setOpenCreateActvity] = useState(false)
    const [activitySelected, setActivitySelected] = useState({})
    const [deleteDialogActivity, setDeleteDialogActivity] = useState(false)
    const [concludedDialog, setConcludedDialog] = useState(false)
    console.log('lead', lead, 'id', id)
    useEffect(() => {
        if (id > 0) {
            getCardActivities()
        }
    }, [id])

    const deleteActivity = () => {
        axios.delete(Constants.APIEndpoints.LEADS + '/deleteleadactivity/' + activitySelected.id_lead_activity).then(res => {
            getCardActivities()
            setDeleteDialogActivity(false)
        })
    }

    const getCardActivities = () => {
        axios.get(Constants.APIEndpoints.LEADS + '/getleadactivities/' + id).then(res => {
            setActivities(res.data)
        })
    }

    const saveCardAcvitivy = (values) => {
        values.fk_id_leads = id

        if (moment(`${values.dt_start} ${values.time_start}`).isSameOrAfter(moment(`${values.dt_end} ${values.time_end}`))) {
            toast("Data de término não pode ser inferior a de início");
        } else {
            const url = values.id_lead_activity ? '/updateleadactivity' : '/createleadactivity'
            axios.post(Constants.APIEndpoints.LEADS + url, values).then(res => {
                getCardActivities()
                setOpenCreateActvity(false)
                setConcludedDialog(false)
            })
        }
    }

    const concludedLeadActivity = () => {
        axios.post(Constants.APIEndpoints.LEADS + '/finishleadactivity', { id_lead_activity: activitySelected.id_lead_activity }).then(res => {
            setConcludedDialog(false)
            getCardActivities(id)
        })
    }
    return (
        <div>
            {
                deleteDialogActivity ? (
                    <ConfirmDialog title="Deseja deletar esta atividade" cancel={() => setDeleteDialogActivity(false)} confirm={deleteActivity} />
                ) : null
            }
            {
                concludedDialog ? (
                    <ConfirmDialog title="Deseja concluir a atividade?"
                        width='sm'
                        cancel={() => setConcludedDialog(false)}
                        confirm={() => concludedLeadActivity()}
                    />
                ) : null
            }
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <CommonDialog
                open={openCreateActivity}
                onClose={() => setOpenCreateActvity(false)}
                title='Nova Atividade'
                width='lg'
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 5,
                            type: 'text',
                            name: 'name',
                            label: 'Nome'
                        },
                        {
                            col: 3,
                            type: 'select',
                            name: 'fk_id_user',
                            label: 'Responsável',
                            options: usersOptions
                        },

                        {
                            col: 4,
                            type: 'checkbox',
                            name: 'sendmail',
                            label: 'Receber email lembrando dessa atividade?'
                        },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_start',
                            label: 'Data de Início'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_start',
                            label: 'Hora de Início'
                        },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_end',
                            label: 'Data de Término'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_end',
                            label: 'Hora de Término'
                        }, {
                            col: 12,
                            type: 'textarea',
                            name: 'description',
                            label: 'Descrição'
                        }
                    ]}
                    onSubmit={saveCardAcvitivy}
                    values={activitySelected}
                    onChangeField={(f, v) => {
                        let v_ = { ...activitySelected }
                        v_[f.name] = v;
                        setActivitySelected(v_)
                    }}
                />
            </CommonDialog>
            <div style={{ marginTop: 60 }}>
                {lead.win == false && lead.loss == false ? (
                    <Tooltip title={<h5>Adicionar nova atividade</h5>}>
                        <IconButton
                            style={{
                                color: 'white',
                                backgroundColor: 'green'
                            }}
                            onClick={() => {
                                setActivitySelected({})
                                setOpenCreateActvity(true)
                            }}
                        >
                            <Icon
                            >
                                add
                            </Icon>
                        </IconButton>
                    </Tooltip>
                ) : null}
                <Tooltip title={<h5>Ir para calendário</h5>}>
                    <Link to='/calendar' role='button'>
                        <IconButton
                            style={{
                                backgroundColor: '#515e51',
                                color: 'white',
                                margin: 10
                            }}
                        >
                            <Icon>
                                today
                            </Icon>
                        </IconButton>
                    </Link>
                </Tooltip>
            </div>
            <div>
                <CommonTable
                    id={'list_activities_lead'}
                    noHeader={true}
                    miniVersion={true}
                    data={(activities || []).map(c => {
                        return {
                            ...c,
                            dt_start_: c.dt_start ? moment(c.dt_start).format('DD/MM/YYYY HH:mm') : '',
                            dt_end_: c.dt_end ? moment(c.dt_end).format('DD/MM/YYYY HH:mm') : '',
                            sendmail: c.send_mail ? 'Sim' : 'Não'
                        }
                    })}
                    columns={[
                        {
                            Header: "Nome",
                            accessor: "name",
                            className: "font-medium",
                            sortable: true,
                            noSearchable: true,
                        },
                        {
                            Header: "Início",
                            accessor: "dt_start_",
                            className: "font-medium",
                            sortable: true, noSearchable: true,
                            noSearchable: true,
                        },
                        {
                            Header: "Término",
                            accessor: "dt_end_",
                            sortable: true,
                            noSearchable: true,
                        },
                        {
                            Header: "Responsável",
                            accessor: "fk_id_user_",
                            sortable: true,
                            noSearchable: true,
                        }, {
                            Header: "Envio de email",
                            accessor: "sendmail",
                            sortable: true,
                            noSearchable: true,
                        },
                        {
                            id: "action",
                            width: 128,
                            sortable: false,
                            Cell: ({ row }) => (
                                row.original.status !== 2 ? (

                                    <div className="flex items-center">
                                        <Tooltip title={<h5>Concluir a Atividade</h5>} placement="right">
                                            <IconButton
                                                onClick={(ev) => {
                                                    let rowSelected = row.original
                                                    setActivitySelected(rowSelected)
                                                    setConcludedDialog(true)
                                                }}
                                            >
                                                <Icon>check</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={<h5>Editar a Atividade</h5>} placement="right">
                                            <IconButton
                                                onClick={(ev) => {
                                                    let rowSelected = row.original
                                                    rowSelected.time_end = moment(row.original.dt_end).format('HH:mm')
                                                    rowSelected.dt_end = moment(row.original.dt_end).format('YYYY-MM-DD')
                                                    rowSelected.time_start = moment(row.original.dt_start).format('HH:mm')
                                                    rowSelected.dt_start = moment(row.original.dt_start).format('YYYY-MM-DD')
                                                    rowSelected.sendmail = row.original.send_mail
                                                    setActivitySelected(rowSelected)
                                                    setOpenCreateActvity(true)
                                                }}
                                            >
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={<h5>Excluir a Atividade</h5>} placement="right">
                                            <IconButton
                                                onClick={(ev) => {
                                                    const rowSelected = row.original
                                                    setActivitySelected(rowSelected)
                                                    setDeleteDialogActivity(true)
                                                }}
                                            >
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                ) : null
                            ),
                        },
                    ]}

                />
            </div>
        </div >
    )
}

export default LeadsActivities