import React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Divider } from '@material-ui/core';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import moment from 'moment-timezone'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        width: 520,
        padding: 15
    },
}));


function MailingPanel({
    open,
    onClose,
    mailing
}) {
    const classes = useStyles();

    return (

        <SwipeableDrawer
            classes={{ paper: clsx(classes.root) }}
            open={open}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={onClose}
            disableSwipeToOpen
        >


            <IconButton className="m-4 absolute top-0 right-0 z-999" onClick={() => console.log('ihoih')}>
                <Icon color="action">close</Icon>
            </IconButton>

            <div className="flex flex-col space-y-32">

                <div className="flex flex-1 items-center px-12">
                    <Typography className="mt-12 text-4xl font-bold truncate"
                        style={{ whiteSpace: 'break-spaces' }}>{mailing?.name}</Typography>
                </div>

                <Divider className="mt-16 mb-24" />
                {mailing?.address && (
                    <div className="flex items-center">
                        <FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
                        <div className="ml-24 leading-6">{mailing?.address}</div>
                    </div>
                )}
                {mailing?.phone && (
                    <div className="flex items-center">
                        <FuseSvgIcon>heroicons-outline:phone</FuseSvgIcon>
                        <div className="ml-24 leading-6">{mailing?.phone}</div>
                    </div>
                )}
                {mailing?.email && (
                    <div className="flex items-center">
                        <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
                        <div className="ml-24 leading-6">{mailing?.email}</div>
                    </div>
                )}
                <div className="flex flex-col space-y-32">
                    {mailing?.place && (
                        <div className="flex items-center">
                            <FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
                            <div className="ml-24 leading-6">{mailing?.place}</div>
                        </div>
                    )}
                    {mailing?.company_function && (
                        <div className="flex items-center">
                            <FuseSvgIcon>heroicons-outline:office-building</FuseSvgIcon>
                            <div className="ml-24 leading-6">{mailing?.company_function}</div>
                        </div>
                    )}
                    {mailing?.fk_id_type && (
                        <div className="flex items-center">
                            <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                            <div className="ml-24 leading-6">{mailing.fk_id_type == 1 ? 'Veículo' :
                                mailing.fk_id_type == 2 ? 'Agência' :
                                    mailing.fk_id_type == 3 ? 'Cliente' :
                                        'Interno'}</div>
                        </div>
                    )}
                    {mailing?.dt_birthday && (
                        <div className="flex items-center">
                            <FuseSvgIcon>heroicons-outline:cake</FuseSvgIcon>
                            <div className="ml-24 leading-6">
                                {moment(mailing?.dt_birthday).format('DD/MM/YYYY')}
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </SwipeableDrawer >
    );
}

export default MailingPanel;
