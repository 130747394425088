import CommonTable from "../../components/table/CommonTable";
import React, { useMemo, useEffect, useState } from "react";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonForm from "app/components/form/CommonForm";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from "@material-ui/core";
import CommonDialog from "app/components/dialog/CommonDialog";

import Store from 'app/utils/Store'
let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const VechilesGoals = ({ }) => {
    const dispatch = useDispatch();
    // const user = useSelector(({ contactsApp }) => contactsApp.user);
    const user = [];

    const [values, setValues] = useState([])
    const [page, setPage] = useState('list')
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [data, setData] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [percent, setPercent] = useState(0)
    const [globalGoal, setGlobalGoal] = useState(0)
    const [comissionDialog, setComissionDialog] = useState(false)
    const [addComissionDialog, setAddComissionDialog] = useState(false)
    const [comissionSelected, setComissionSelected] = useState({})
    const [comissions, setComissions] = useState([])
    const [deleteComissionDialog, setDeleteDialogComission] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: "Veículo",
                accessor: "vehicle",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Percentual",
                accessor: "percent",
                sortable: true,
            },
            {
                Header: "Valor",
                accessor: "value",
                sortable: true,
            },
            {
                id: "action",
                width: 128,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex items-center">
                        {access.goal_vehicle_create && (
                            <IconButton
                                onClick={(ev) => {
                                    setValues(row.original)
                                    setComissionDialog(true)
                                    getComissions(row.original)
                                }}
                            >
                                <Icon>add</Icon>
                            </IconButton>
                        )}
                        {access.goal_vehicle_update && (

                            <IconButton
                                onClick={(ev) => {
                                    handlePage('add', row.original)
                                }}
                            >
                                <Icon>edit</Icon>
                            </IconButton>
                        )}
                        {access.goal_vehicle_delete && (

                            <IconButton
                                onClick={(ev) => {
                                    setValues(row.original)
                                    setDeleteDialog(true)
                                }}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        )}
                    </div>
                ),
            },
        ],
        [dispatch, user.starred]
    );

    const getComissions = (data) => {
        axios.post(Constants.APIEndpoints.GOALS + '/getallcomissions', {
            type: 'vehicle', fk_id_ref: data.id_goals_vehicle
        }).then(res => {
            setComissions(res.data)
        })
    }

    useEffect(() => {
        getData()
        axios.get(Constants.APIEndpoints.VEHICLE + "/getAllVehicles").then((res) => {
            setVehicles(res.data)
        });

        axios.post(Constants.APIEndpoints.GOALS + '/getAllGoals', {
            type: 'global'
        }).then(res => {
            setGlobalGoal(res.data.find(f => f.year == moment().year()))
        })
    }, [])

    const handlePage = (page, obj) => {
        setPage(page)
        setValues(obj)
    }
    const onAdd = () => {
        handlePage('add')
    }

    const onAddComission = () => {
        setAddComissionDialog(true)
    }
    const getData = () => {
        axios.post(Constants.APIEndpoints.GOALS + '/getAllGoals', {
            type: 'vehicle'
        }).then(res => {
            const goals = res.data.map(d => {
                d.value = parseFloat(d.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                return d
            })
            setData(goals)
        })
    }

    const saveVehicleGoal = (values) => {
        values.type = 'vehicle'
        axios.post(Constants.APIEndpoints.GOALS + (values.id_goals_vehicle ? '/updateGoal' : '/createGoal'), values).then(res => {
            handlePage('list')
            getData()
        })
    }

    const deleteVehicleGoal = () => {
        axios.post(Constants.APIEndpoints.GOALS + '/deleteGoal', { id_goals_vehicle: values.id_goals_vehicle, type: 'vehicle' }).then(res => {
            setDeleteDialog(false)
            getData()
        })
    }

    const saveVehicleComission = (data) => {
        data.type = 'vehicle'
        data.fk_id_ref = values.id_goals_vehicle
        const url = data.id_comissions ? '/updatecomission' : '/createcomission'
        axios.post(Constants.APIEndpoints.GOALS + url, data).then(res => {
            getComissions(values)
            setAddComissionDialog(false)
        })
    }

    const deleteComission = () => {
        axios.delete(Constants.APIEndpoints.GOALS + '/deletecomission/' + comissionSelected.id_comissions).then(res => {
            getComissions(values)
            setDeleteDialogComission(false)
        })
    }
    console.log('comissions', comissionSelected)
    return (
        <div>
            {page == 'list' && (
                <CommonTable
                    columns={columns}
                    data={data}
                    newText="Adicionar nova Meta"
                    onAdd={access.goal_vehicle_create ? onAdd : null}
                />
            )}


            {page == 'add' && (
                <div>
                    <IconButton
                        onClick={(ev) => {
                            handlePage('list')
                        }}
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                    <CommonForm
                        fields={[
                            {
                                col: 6,
                                name: 'fk_id_vehicle',
                                label: 'Veículo',
                                type: 'select',
                                options: vehicles.map(t => ({ value: t.id_vehicle, label: t.fancy_name })),
                                required: true
                            }, {
                                col: 6,
                                name: 'percent',
                                label: 'Percentual',
                                type: 'number',
                                required: true
                            }
                        ]}
                        onChangeField={(f, v) => {
                            if (f.name == 'percent') {
                                setPercent(v)
                            }
                        }}
                        values={values}
                        onSubmit={saveVehicleGoal}
                    />
                    <div
                        style={{
                            display: 'flex',
                            padding: 20,
                            justifyContent: 'center'
                        }}
                    >
                        <Typography style={{ fontSize: 18, padding: 10 }}>Valor que a porcentagem representa:  </Typography>
                        <Typography style={{ fontSize: 18, padding: 10 }}>{parseFloat(globalGoal.value * (percent / 100)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                    </div>
                </div>
            )}

            {deleteDialog ? (
                <ConfirmDialog title="Deseja deletar essa meta do time?" cancel={() => setDeleteDialog(false)} confirm={deleteVehicleGoal} />
            ) : null}
            {deleteComissionDialog ? (
                <ConfirmDialog title="Deseja deletar essa comissão do time?" cancel={() => setDeleteDialogComission(false)} confirm={deleteComission} />
            ) : null}

            {comissionDialog == true && (
                <CommonDialog
                    open={true}
                    onClose={() => setComissionDialog(false)}
                    width='lg'
                    fullWidth={true}
                >
                    <CommonTable
                        data={comissions.map(c => {
                            return {
                                ...c,
                                until_after_: c.until_after == 'de' ? '>' : '<'
                            }
                        })}
                        noSearch={true}
                        columns={[
                            {
                                Header: "Ordem",
                                accessor: "until_after_",
                                className: "font-medium",
                                sortable: true,
                            },
                            {
                                Header: "Porcentagem da Meta",
                                accessor: "percent",
                                className: "font-medium",
                                sortable: true,
                            },
                            {
                                Header: "Comissão",
                                accessor: "comission",
                                sortable: true,
                            },
                            {
                                id: "action",
                                width: 128,
                                sortable: false,
                                Cell: ({ row }) => (
                                    <div className="flex items-center">
                                        <IconButton
                                            onClick={(ev) => {
                                                const rowSelected = row.original
                                                setComissionSelected(rowSelected)
                                                setAddComissionDialog(true)
                                            }}
                                        >
                                            <Icon>edit</Icon>
                                        </IconButton>
                                        <IconButton
                                            onClick={(ev) => {
                                                const rowSelected = row.original
                                                setComissionSelected(rowSelected)
                                                setDeleteDialogComission(true)
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </div>
                                ),
                            },
                        ]}
                        newText="Adicionar nova comissão"
                        onAdd={onAddComission}
                    />
                </CommonDialog>
            )}
            {addComissionDialog == true && (
                <CommonDialog
                    open={true}
                    onClose={() => {
                        setAddComissionDialog(false)
                        setComissionSelected({})
                    }}
                    width='lg'
                >
                    <CommonForm
                        fields={
                            [{
                                col: 4,
                                name: 'until_after',
                                label: 'Ordem',
                                type: 'select',
                                options: [{ value: 'ate', label: '<' }, { value: 'de', label: '>' }],
                                required: true
                            },
                            {
                                col: 4,
                                name: 'percent',
                                label: 'Objetivo %',
                                type: 'number',
                                required: true
                            }, {
                                col: 4,
                                name: 'comission',
                                label: 'Comissão',
                                type: 'number',
                                required: true
                            }
                            ]
                        }
                        onSubmit={saveVehicleComission}
                        values={comissionSelected}
                        onChangeField={(f, v) => {
                            if (f.name == 'until_after') {
                                setComissionSelected(oldComission => { return { ...oldComission, 'until_after': v } })
                            }
                        }}
                    />
                </CommonDialog>
            )}
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
        </div>
    )
}

export default VechilesGoals