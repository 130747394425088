import React, { useState } from 'react'
import CommonDialog from "app/components/dialog/CommonDialog";
import Button from "@material-ui/core/Button";

import './index.css'
export default function ConfirmDialog({ title, cancel, confirm, options, width, color }) {
  const [deleteDialog, setDeleteDialog] = useState(true)
  return (

    <CommonDialog
      open={deleteDialog}
      onClose={() => {
        setDeleteDialog(false)
        cancel()
      }}
      title={title}
      width={width}
      fullWidth={!!width}
    >
      {options ? (
        <div className='confirmDialogButtons'>
          {options.map(option => (
            <Button
              variant="contained"
              style={{ backgroundColor: option.color }}
              onClick={option.onClick}
            >
              <span className="hidden sm:flex">{option.title}</span>
            </Button >
          ))}
        </div>
      ) : (
        <div className='confirmDialogButtons'>
          <Button
            variant="contained"
            color="warning"
            onClick={cancel}
          >
            <span className="hidden sm:flex">Não</span>
          </Button >
          <Button
            variant="contained"
            color="primary"
            onClick={confirm}
          >
            <span className="hidden sm:flex">sim</span>
          </Button>
        </div >
      )
      }
    </CommonDialog >

  )
}