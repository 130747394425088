import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import CommonForm from "app/components/form/CommonForm";
import moment from "moment-timezone";
let logged_user = Store.USER
const Comissions = ({ }) => {
    const [data, setData] = useState([])
    const [year, setYear] = useState(moment().year())
    const [months, setMonths] = useState([])

    const [values, setValues] = useState({})
    const monthList = [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Dezembro' },
    ];
    const getData = () => {
        axios.post(Constants.APIEndpoints.FINANCE + '/comissionuser', {
            year: year,
            months: months
        }).then(res => {
            setData(res.data)
        })
    }
    let fields = [
        {
            col: 5,
            type: 'select',
            name: 'year',
            label: 'Ano',
            options: [
                { value: moment().year() - 1, label: moment().year() - 1 },
                { value: moment().year(), label: moment().year() },
            ]
        },
        {
            col: 5,
            type: 'multiselect',
            name: 'month',
            label: 'Mês',
            options: monthList
        },
        {
            col: 2,
            type: 'content',
            content: <IconButton
                aria-controls="font-size-menu"
                aria-haspopup="true"
                style={{ marginTop: 15 }}
                onClick={() => {
                    getData()
                }}
            >
                <Icon>search</Icon>
            </IconButton>
        }
    ]
    return (
        <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
        >

            <CommonForm
                values={{ months: months, year: year }}
                fields={fields}
                onChangeField={(f, v) => {
                    values[f.name] = v;
                    setValues(values);
                    if (f.name == 'year') {
                        setYear(v)
                    }
                    if (f.name == 'month') {
                        setMonths(v)
                    }
                }}
            />
            <CommonTable
                id="comissionuser_list"
                columns={[
                    {
                        Header: 'Nome',
                        accessor: 'name',
                        sortable: true
                    },

                    {
                        Header: 'Comissão',
                        accessor: 'comission',
                        sortable: true
                    },
                ]}
                noHeader={true}
                data={data.map(d => ({ ...d, comission: (d.comission || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }))}
                miniVersion={true}
                onRowClick={(ev, row) => {
                    if (row) {
                        //            dispatch(openEditContactDialog(row.original));
                    }
                }}
            />
        </motion.div>
    );
}

export default Comissions