import React from 'react'
import CommonForm from "app/components/form/CommonForm"
const CreateCard = ({
    onSubmit,
    clients,
    agencies,
    mailings,
    kanban,
    fromList,
    card,
    setSelectedCard,
    getSingleLead,
    tags
}) => {
    return (
        <CommonForm
            fields={[
                {
                    col: 6,
                    name: 'subject',
                    label: 'Título',
                    type: 'text',
                }, {
                    col: 6,
                    name: 'contact',
                    label: 'Contato',
                    type: 'select',
                    options: mailings.map(m => ({ value: m.id_mailing, label: m.name }))
                }, {
                    col: 6,
                    name: 'fk_id_client',
                    label: 'Cliente',
                    type: 'select',
                    options: clients.map(c => ({ value: c.id_client, label: c.fancy_name })),
                    required: true
                }, {
                    col: 6,
                    name: 'fk_id_agency',
                    label: 'Agência',
                    type: 'select',
                    options: agencies.map(a => ({ value: a.id_agency, label: a.fancy_name })),
                }, {
                    col: 12,
                    name: 'gross_value',
                    label: 'Valor Bruto',
                    type: 'number',
                }, {
                    col: 6,
                    name: 'dt_start',
                    label: 'Data de início',
                    type: 'date',
                }, {
                    col: 6,
                    name: 'dt_end',
                    label: 'Data de Fechamento',
                    type: 'date',
                }, {
                    col: !fromList ? 6 : 12,
                    name: 'place_sell',
                    label: 'Local de venda',
                    type: 'text',
                },
                {
                    col: 6,
                    name: 'id_column',
                    label: 'Funil de vendas',
                    type: 'select',
                    options: kanban.map(k => ({ value: k.id_kanban_column, label: k.name })),
                    required: !fromList,
                    visible: !fromList
                },
                {
                    col: 6,
                    name: 'tags_',
                    label: 'Etiquetas',
                    type: 'multiselect',
                    options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color })),

                },
                {
                    col: 12,
                    name: 'observation',
                    label: 'Observações',
                    type: 'textarea'
                },

            ]}
            onSubmit={onSubmit}
            values={card}
            onChangeField={(f, v) => {
                let v_ = { ...card }
                v_[f.name] = v;
                setSelectedCard(v_)
            }}

        />
    )
}

export default CreateCard