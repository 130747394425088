import React, { useMemo, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import CommonTable from "../../components/table/CommonTable";
import QuestionsDefaultForm from "./QuestionsDefaultForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";

const logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const QuestionsDefaultList = () => {
    const dispatch = useDispatch();
    const user = [];
    const [questions, setQuestions] = useState([]);
    const [page, setPage] = useState("list");

    const [values, setValues] = useState({});
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [questionSelected, setQuestionSelected] = useState([])




    useEffect(() => {
        getData()
    }, [])

    const columns = useMemo(
        () => [

            {
                Header: "Nome",
                accessor: "name",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Pergunta",
                accessor: "question",
                sortable: true,
            },
            {
                id: "action",
                width: 128,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex items-center">
                        {access.config_default_questions_update ? (
                            <IconButton
                                onClick={(ev) => {
                                    setValues(row.original)
                                    setPage('form')
                                }}
                            >
                                <Icon>edit</Icon>
                            </IconButton>
                        ) : null}
                        {access.config_default_questions_delete ? (
                            <IconButton
                                onClick={(ev) => {
                                    setQuestionSelected(row.original)
                                    setDeleteDialog(true)
                                }}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        ) : null}
                    </div>
                ),
            },
        ],
        [dispatch, user.starred]
    );

    const onAdd = () => {
        setValues({})
        setPage("form");
    };

    useEffect(() => {
        getData();
    }, []);


    const deleteQuestion = () => {
        axios.delete(Constants.APIEndpoints.QUESTIONS + "/deletequestion/" + questionSelected.id_default_questions).then((res) => {
            getData();
            setDeleteDialog(false)
        });
    };

    const getData = () => {
        axios
            .get(
                Constants.APIEndpoints.QUESTIONS + "/getallquestions")
            .then((res) => {
                setQuestions(res.data)
            })
    };

    return (
        <motion.div
            initial={{ y: -80, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
            {deleteDialog ? (
                <ConfirmDialog title="Deseja deletar essa Pergunta?" cancel={() => setDeleteDialog(false)} confirm={deleteQuestion} />
            ) : null}

            {page == "list" ? (
                <CommonTable
                    id="questions_list"
                    columns={columns}
                    data={questions}
                    icon="people"
                    newText="Adicionar Nova Pergunta"
                    onAdd={access.config_default_questions_create ? onAdd : null}
                    headerTitle="Perguntas"
                    onRowClick={(ev, row) => {
                        if (row) {
                            //            dispatch(openEditContactDialog(row.original));
                        }
                    }}
                />
            ) : (
                <QuestionsDefaultForm values={values} setPage={setPage} getData={getData} />
            )}
        </motion.div>
    );
}

export default QuestionsDefaultList