import CommonTable from "../../components/table/CommonTable";
import React, { useMemo, useEffect, useState } from "react";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonForm from "app/components/form/CommonForm";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";

const Breakeven = ({ }) => {
    const dispatch = useDispatch();
    const user = [];

    const [values, setValues] = useState([])
    const [page, setPage] = useState('list')
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const handlePage = (page, obj) => {
        setPage(page)
        setValues(obj)
    }
    const onAdd = () => {
        handlePage('add')
    }

    const getData = () => {
        axios.get(Constants.APIEndpoints.BREAKEVEN + '/getAllBreakevens', '').then(res => {
            const holidays = res.data.map(h => {
                h.month_ = h.month ? h.month.split(',').map(m => monthArray[m]).join(', ') : ''
                h.month = h.month ? h.month.split(',').map(m => parseInt(m)) : []
                return h
            })
            setData(holidays)
        })
    }

    const saveBreakeven = (data) => {
        axios.post(Constants.APIEndpoints.BREAKEVEN + (data.id_breakeven ? '/updateBreakeven' : '/createBreakeven'), data).then(res => {
            handlePage('list')
            getData()
        })
    }

    const deleteBreakeven = () => {
        axios.post(Constants.APIEndpoints.BREAKEVEN + '/deleteBreakeven', { id_breakeven: values.id_breakeven }).then(res => {
            setDeleteDialog(false)
            getData()

        })
    }

    const columns = useMemo(
        () => [
            {
                Header: "Valor",
                accessor: "value",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Ano",
                accessor: "year",
                sortable: true,
            },
            {
                Header: "Meses",
                accessor: "month_",
                sortable: true,
            },
            {
                id: "action",
                width: 128,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex items-center">

                        <IconButton
                            onClick={(ev) => {
                                handlePage('add', row.original)
                            }}
                        >
                            <Icon>edit</Icon>
                        </IconButton>
                        <IconButton
                            onClick={(ev) => {
                                setValues(row.original)
                                setDeleteDialog(true)
                            }}
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                    </div>
                ),
            },
        ],
        [dispatch, user.starred]
    );

    const monthList = [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Dezembro' },
    ];

    const monthArray = [
        '',
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];
    return (
        <div>
            {page == 'list' && (
                <CommonTable
                    columns={columns}
                    data={data}
                    newText="Adicionar novo Breakeven"
                    onAdd={onAdd}
                />
            )}


            {page == 'add' && (
                <div>
                    <IconButton
                        onClick={(ev) => {
                            handlePage('list')
                        }}
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                    <CommonForm
                        fields={[
                            {
                                col: 12,
                                name: 'value',
                                label: 'Valor',
                                type: 'nunber',
                                required: true
                            }, {
                                col: 6,
                                name: 'year',
                                label: 'Ano',
                                type: 'select',
                                options: [
                                    { value: moment().year() - 1, label: moment().year() - 1 },
                                    { value: moment().year(), label: moment().year() },
                                ],
                                required: true
                            },
                            {
                                col: 6,
                                type: 'multiselect',
                                name: 'month',
                                label: 'Mês',
                                required: true,
                                options: monthList
                            },
                        ]}
                        values={values}
                        onSubmit={saveBreakeven}
                    />
                </div>
            )}

            {deleteDialog ? (
                <ConfirmDialog title="Deseja deletar esse Breakeven?" cancel={() => setDeleteDialog(false)} confirm={deleteBreakeven} />
            ) : null}
        </div>
    )
}

export default Breakeven