import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import axios from 'app/utils/AxiosConfig'
import Constants from 'app/utils/Constants'

const AutocompleteComponent = ({
    field,
    isMulti,
    name,
    value,
    placeholder,
    onChange,
    isDisabled,
    errors,
    searchType,
    fieldValue,
    fieldLabel,
    createNewEntity,
    labelDefault,
    idDefault,
    getLabel
}) => {
    const dictionary =
    {
        'mailings': Constants.APIEndpoints.MAILING + '/getAllMailings?',
        'clients': Constants.APIEndpoints.CLIENT + '/getallclients?'
    }



    const [showLabel, setShowLabel] = useState('')
    const [selectedLabel, setSelectedLabel] = useState('')
    const [selectValue, setSelectedValue] = useState(0)
    const [options, setOptions] = useState([])
    const [optionSearch, setOptionSearch] = useState('')
    const saveSelect = (option) => {
        if (option.value == 0) {
            createNewEntity(optionSearch)
        } else {
            setShowLabel(option.label)
            if (getLabel) {
                getLabel(option.label)
            }
            setSelectedValue(option.value)
            onChange(option.value)
        }
    }

    useEffect(() => {
        setShowLabel(labelDefault)
        setSelectedValue(idDefault)
    }, [labelDefault, idDefault])

    const search = (text) => {
        setOptionSearch(text)
        axios.post(dictionary[searchType], { search: text }).then(res => {
            const data = res.data.map(d => ({ value: d[fieldValue], label: d[fieldLabel] }))
            data.push({ label: `Adicionar ${text}`, value: 0 })
            setOptions(data)
        })
    }


    return (
        <div>
            <Autocomplete
                inputValue={showLabel}
                onInputChange={(evt, text) => {
                    setShowLabel(text);
                }}
                options={options}
                onChange={(event, newValue) => {
                    if (newValue) {
                        saveSelect(newValue);
                    } else {
                        saveSelect({ label: '', value: 0 });
                    }
                }}
                id={name}
                freeSolo={true}
                filterOptions={(option) => option}
                getOptionLabel={(option) => option.label}
                className={`autocomplete common-select-min  autocompleteSingle `}
                renderInput={(params) => (
                    <TextField
                        error={errors[field.name]}
                        helperText={errors[field.name] ? 'Preencha o campo' : ''}
                        variant="outlined"
                        onChange={(g) => {
                            search(g.target.value);
                        }}
                        {...params}
                        value={selectValue}
                        label={selectedLabel}
                    />
                )}
            />
        </div>
    )
}

export default AutocompleteComponent
