import CommonTable from "../../components/table/CommonTable";
import React, { useMemo, useEffect, useState } from "react";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonForm from "app/components/form/CommonForm";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GoalsGlobal = ({ }) => {
    const dispatch = useDispatch();
    // const user = useSelector(({ contactsApp }) => contactsApp.user);
    const user = [];

    const [values, setValues] = useState([])
    const [page, setPage] = useState('list')
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [data, setData] = useState([])
    const columns = useMemo(
        () => [
            {
                Header: "Ano",
                accessor: "year",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Valor",
                accessor: "value",
                sortable: true,
            },
            {
                Header: "Breakeven",
                accessor: "breakeven",
                sortable: true,
            },
            {
                id: "action",
                width: 128,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex items-center">

                        <IconButton
                            onClick={(ev) => {
                                handlePage('add', row.original)
                            }}
                        >
                            <Icon>edit</Icon>
                        </IconButton>
                        <IconButton
                            onClick={(ev) => {
                                setValues(row.original)
                                setDeleteDialog(true)
                            }}
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                    </div>
                ),
            },
        ],
        [dispatch, user.starred]
    );

    useEffect(() => {
        getData()
    }, [])

    const handlePage = (page, obj) => {
        setPage(page)
        setValues(obj)
    }
    const onAdd = () => {
        handlePage('add')
    }

    const getData = () => {
        axios.post(Constants.APIEndpoints.GOALS + '/getAllGoals', {
            type: 'global'
        }).then(res => {
            setData(res.data)
        })
    }

    const saveGoalGlobal = (values) => {
        // if (data.find(f => f.year == values.year)) {
        //     toast('Já existe uma meta para esse ano', { type: toast.TYPE.ERROR })
        // } else {
        values.type = 'global'

        axios.post(Constants.APIEndpoints.GOALS + (values.id_global_goal ? '/updateGoal' : '/createGoal'), values).then(res => {
            handlePage('list')
            getData()
        })
        // }
    }

    const deteleGlobalGoal = () => {
        axios.post(Constants.APIEndpoints.GOALS + '/deleteGoal', { id_global_goal: values.id_global_goal, type: 'global' }).then(res => {
            setDeleteDialog(false)
            getData()

        })
    }

    return (
        <div>
            {page == 'list' && (
                <CommonTable
                    columns={columns}
                    data={data}
                    newText="Adicionar nova Meta"
                    onAdd={onAdd}
                    id={'list_goals_global'}
                />
            )}


            {page == 'add' && (
                <div>
                    <IconButton
                        onClick={(ev) => {
                            handlePage('list')
                        }}
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                    <CommonForm
                        fields={[
                            {
                                col: 4,
                                name: 'year',
                                label: 'Ano',
                                type: 'number',
                                required: true
                            }, {
                                col: 4,
                                name: 'value',
                                label: 'Valor',
                                type: 'number',
                                required: true
                            },
                            {
                                col: 4,
                                name: 'breakeven',
                                label: 'Breakeven',
                                type: 'number',
                                required: true
                            }
                        ]}
                        values={values}
                        onSubmit={saveGoalGlobal}
                    />
                </div>
            )}

            {deleteDialog ? (
                <ConfirmDialog title="Deseja deletar essa meta global?" cancel={() => setDeleteDialog(false)} confirm={deteleGlobalGoal} />
            ) : null}

            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
        </div>
    )
}

export default GoalsGlobal