import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import moment from 'moment-timezone';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Constants from 'app/utils/Constants';
import axios from 'app/utils/AxiosConfig';
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import WinCard from './winCard';
import './Leads.css'
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { eventBus } from 'app/utils/EventListener'
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: 'rgba(20, 0, 0, 1)',
        opacity: 1,
        fontSize: 11,
        borderRadius: 10,
        border: '1px solid'
    },
}));

export default function BoardCard({
    getData,
    card,
    selectedCard,
    index,
    list,
    usersOptions,
    columnsOptions,
    tags,
    setSelectedCard,
    getSingleLead,
    setOpenCreateNewCard,
    setOpenCreateNewCardWithStep,
    getCardActivities,
    setOpenSeeActvity,
    agencies,
    clients,
    setOpenLossCard,
    setOpenWinCard,
    visuplac,
    openCard,
    setOpenCard
}) {
    if (card && card.tags) {
        card.tags_ = card.tags.map(t => t.id_tags)
    }
    if (card) {
        card.dt_start = card.dt_start ? moment(card.dt_start).format('YYYY-MM-DD') : ''
        card.dt_end = card.dt_end ? moment(card.dt_end).format('YYYY-MM-DD') : ''
    }

    if (card && selectedCard) {
        card.files = selectedCard.files
    }
    const [openCardEdit, setOpenCardEdit] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [lossCardDialog, setLossCardDialog] = useState(false)

    useEffect(() => {
        eventBus.on('lose-modal-card', (data) => {
            setOpenCard(false)
        })
        eventBus.on('won-modal-card', (data) => {
            setOpenCard(false)
        })
    }, [])

    const editCard = (values) => {
        values.fk_id_kanban_column = list.id_kanban_column
        values.fk_id_kanban_card = card.id_kanban_card
        axios.post(Constants.APIEndpoints.KANBAN + "/updateCard", values).then(res => {
            setOpenCardEdit(false)
            getData()
        })
    }

    const deleteCard = () => {
        axios.post(Constants.APIEndpoints.KANBAN + "/deleteCard", { id_cards: card.id_cards }).then(res => {
            getData()
        })
    }

    const infoActTooltip = (activities) => {
        const data = activities.map(activity => (
            <div style={{
                display: 'contents'
            }}>
                <Typography
                    style={{
                        fontSize: 12,
                        textAlign: 'center',
                        fontWeight: 600,
                        width: '100%',
                        padding: 10
                    }}
                >{activity.name}</Typography>
                <div
                    style={{
                        display: 'flex',
                        border: '1px solid rgba(0,0,0,25%)',
                        borderRadius: 5,
                        padding: 5
                    }}>
                    <FuseSvgIcon color="action">
                        heroicons-outline:clock
                    </FuseSvgIcon>
                    <Typography className="text-12 font-semibold"
                        style={{
                            marginLeft: 10,
                            fontSize: 10,
                            fontWeight: 600,
                            textAlign: 'start',
                            width: '100%',
                            marginTop: 5
                        }}>{activity.dt_start ? moment(activity.dt_start).format('DD/MM/YYYY HH:mm') + ' à ' : ''}  {activity.dt_end ? moment(activity.dt_end).format('DD/MM/YYYY HH:mm') : ''}</Typography>
                </div>
                <Divider style={{ marginTop: 5, height: 1, backgroundColor: 'black' }} />
            </div>
        ))
        return data
    }


    const lossCard = (values) => {
        values.fk_id_card = card.id_cards
        axios.post(Constants.APIEndpoints.KANBAN + '/losscard', values).then(res => {
            setLossCardDialog(false)
            setOpenCard(false)
            getSingleLead(card.id_cards)
        })
    }
    return (
        <div>
            <Draggable draggableId={'card' + card.id_cards} index={index} type="card">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Card
                            className={clsx(
                                snapshot.isDragging ? 'shadow-lg' : 'shadow-0',
                                card.win ? 'winCard' : '',
                                card.loss ? 'lossCard' : '',
                                'w-full mb-16 rounded-8  cardKanban'
                            )}
                        >
                            <div style={{ display: 'flex', backgroundColor: '#1b2330', maxHeight: 35 }}>
                                <Typography onClick={(ev) => {
                                    getSingleLead(card.id_cards)
                                    setOpenCard(true)
                                }}
                                    className="subjectCard">{card.subject}
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <IconButton
                                        style={{ width: 10 }}
                                        onClick={(ev) => {
                                            getSingleLead(card.id_cards)
                                            setOpenCard(false)
                                            setOpenCreateNewCardWithStep(true)
                                        }}
                                    >
                                        <Icon style={{ color: 'white', fontSize: 18 }}>edit</Icon>
                                    </IconButton>
                                    <IconButton
                                        style={{ width: 10 }}
                                        onClick={(ev) => {
                                            setDeleteDialog(true)
                                        }}
                                    >
                                        <Icon style={{ color: 'white', fontSize: 18 }}>delete</Icon>
                                    </IconButton>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='cardContent' onClick={(ev) => {
                                    setOpenCard(true)
                                    getSingleLead(card.id_cards)
                                }}>
                                    <Typography >{card.name_client}
                                    </Typography>
                                    <Typography >{card.net_value ? card.net_value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                                    </Typography>
                                    <Typography >{`${card.dt_start ? moment(card.dt_start).format('DD/MM/YYYY') : 'Sem data de início'} à ${card.dt_end ? moment(card.dt_end).format('DD/MM/YYYY') : 'Sem data de término'}`}
                                    </Typography>

                                </div>
                                <div>
                                    {card.activities && card.activities.map(act => act.is_delay).every(Boolean) && (
                                        <Tooltip title={<h4>Atividade Atrasada</h4>}>
                                            <FuseSvgIcon
                                                style={{ color: '#a10404', margin: 2 }}
                                            >heroicons-outline:exclamation-circle</FuseSvgIcon>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {card.tags && card.tags.map(t => {
                                        return (
                                            <div style={{
                                                backgroundColor: t.color, height: 20, minWidth: 60, maxWidth: 'fit-content', padding: 10
                                                , margin: 4, borderRadius: 10, display: 'flex', color: 'white', fontSize: 10,
                                                justifyContent: 'center', alignItems: 'center'
                                            }}>{t.name}</div>
                                        )
                                    })}
                                </div>

                                <div>
                                    {card.activities && card.activities.length > 0 && (
                                        <LightTooltip
                                            title={infoActTooltip(card.activities)}>
                                            <FuseSvgIcon
                                                style={{ margin: 2 }}
                                            >heroicons-outline:information-circle</FuseSvgIcon>
                                        </LightTooltip>
                                    )
                                    }

                                </div>
                            </div>
                        </Card >
                    </div >
                )
                }
            </Draggable >


            <CommonDialog
                open={lossCardDialog}
                onClose={() => setLossCardDialog(false)}
                title="Perda"
                width="md"
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            name: 'loss',
                            label: 'Justificativa',
                            type: 'textarea',
                        },
                    ]}
                    onSubmit={lossCard}
                />

            </CommonDialog>

            <CommonDialog
                open={openCardEdit}
                onClose={() => setOpenCardEdit(false)}
                title="Editar Card"
                width="xl"
            >
                <CommonForm
                    values={card}
                    fields={[
                        {
                            col: 6,
                            name: 'subject',
                            label: 'Título',
                            type: 'text',
                        }, {
                            col: 6,
                            name: 'contact',
                            label: 'Contato',
                            type: 'text',
                        }, {
                            col: 6,
                            name: 'fk_id_client',
                            label: 'Cliente',
                            type: 'select',
                            options: clients.map(c => ({ value: c.id_client, label: c.fancy_name })),
                            required: true
                        }, {
                            col: 6,
                            name: 'fk_id_agency',
                            label: 'Agência',
                            type: 'select',
                            options: agencies.map(a => ({ value: a.id_agency, label: a.fancy_name })),
                        }, {
                            col: 12,
                            name: 'gross_value',
                            label: 'Valor Bruto',
                            type: 'number',
                        }, {
                            col: 6,
                            name: 'dt_start',
                            label: 'Data de início',
                            type: 'date',
                        }, {
                            col: 6,
                            name: 'dt_end',
                            label: 'Data de Fechamento',
                            type: 'date',
                        }, {
                            col: 12,
                            name: 'place_sell',
                            label: 'Local de venda',
                            type: 'text',
                        },
                        {
                            col: 6,
                            name: 'id_column',
                            label: 'Funil de vendas',
                            type: 'select',
                            options: columnsOptions,
                            required: true
                        },
                        {
                            col: 6,
                            name: 'tags_',
                            label: 'Etiquetas',
                            type: 'multiselect',
                            options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color })),

                        },
                        {
                            col: 12,
                            name: 'observation',
                            label: 'Observações',
                            type: 'textarea'
                        }
                    ]}
                    onSubmit={editCard}
                />

            </CommonDialog>
            {
                deleteDialog ? (
                    <ConfirmDialog title="Deseja deletar este card?" cancel={() => setDeleteDialog(false)} confirm={deleteCard} />
                ) : null
            }
        </div >
    )
}