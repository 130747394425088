import React, { useState } from 'react';
import moment from 'moment-timezone'
import CommonDialog from "app/components/dialog/CommonDialog";
import Constants from 'app/utils/Constants';
import axios from 'app/utils/AxiosConfig';
import CommonView from '../../components/commonView/CommonView'
import './Dashboard.css'

const ListProposalDetail = ({
    data,
    title,
}) => {
    const [proposalDialog, setProposalDialog] = useState(false)
    const [singleProposal, setSingleProposal] = useState([])
    const [singleProposal2, setSingleProposal2] = useState([])
    const [proposalSelected, setProposalSelected] = useState([])


    const openView = (id_proposals) => {
        axios.post(Constants.APIEndpoints.PROPOSAL + '/getSingleProposal', { id_proposals: id_proposals }).then(res => {
            setProposalSelected(res.data)
            viewProposal(res.data);
        })
    }

    const viewProposal = (proposal) => {

        setProposalDialog(true)
        let data = [
            {
                isMulti: true,
                col: 3,
                values: [
                    {
                        label: 'Cliente: ',
                        value: proposal.name_client
                    },
                    {
                        label: 'Razão Social: ',
                        value: proposal.company_client
                    },
                    {
                        label: 'CNPJ: ',
                        value: proposal.cnpj_client
                    }

                ]
            },
            {
                isMulti: true,
                col: 3,
                values: [
                    {
                        label: 'Agência: ',
                        value: proposal.name_agency
                    },
                    {
                        label: 'Razão Social: ',
                        value: proposal.company_agency
                    },
                    {
                        label: 'CNPJ: ',
                        value: proposal.cnpj_agency
                    }
                ]
            },
            {
                isMulti: true,
                col: 3,
                values: [
                    {
                        label: 'Veículo: ',
                        value: proposal.name_vehicle
                    },
                    {
                        label: 'Razão Social: ',
                        value: proposal.company_vehicle
                    },
                    {
                        label: 'CNPJ: ',
                        value: proposal.cnpj_vehicle
                    }
                ]
            },
            {
                isMulti: true,
                col: 3,
                values: [
                    {
                        label: 'N° PI/PP : ',
                        value: proposal.number
                    },
                    {
                        label: 'Dt. Emissão: ',
                        value: moment(proposal.dt_emission).format('DD/MM/YYYY')
                    },
                    {
                        label: 'Dt. Criação: ',
                        value: moment(proposal.dt_cad).format('DD/MM/YYYY')
                    },

                ]
            },
            {
                col: 3,
                label: 'Campanha',
                value: proposal.campaign
            },
            {
                col: 3,
                label: 'Praça',
                value: proposal.square
            },
            {
                col: 3,
                label: 'Mês',
                value: proposal.month
            },
            {
                col: 3,
                label: 'N° Proposta',
                value: proposal.id_proposals
            },

        ]

        setSingleProposal(data)
        let data2 = [
            {
                col: 6,
                label: 'Usuário',
                value: proposal.user
            },
            {
                col: 6,
                label: 'Responsável',
                value: proposal.responsable
            },
            {
                col: 12,
                label: 'Anotações',
                value: proposal.observation,
                isHTML: true

            },
            {
                col: 12,
                label: 'Arquivos Material',
                values: proposal.file_material || [],
                isFile: true,
            },
            {
                col: 12,
                label: 'Arquivos PI/PP',
                values: proposal.file_pp || [],
                isFile: true,

            }

        ]
        setSingleProposal2(data2)

    }

    return (
        <div id="last10">
            <CommonDialog
                open={proposalDialog}
                onClose={() => setProposalDialog(false)}
                title="Ver Negócio"
                width="xl"
                print={true}
            >
                <CommonView dialog={true} data={singleProposal} title="Ver Negócio" onBack={() => setProposalDialog(false)} />

                {proposalSelected.products && proposalSelected.products.length > 0 ? (
                    <div style={{ padding: 20 }}>
                        <table style={{ width: "100%" }}>
                            <thead
                                style={{ backgroundColor: "var(--purple)", marginBottom: 10 }}
                            >
                                <tr style={{ color: "black" }}>
                                    <th style={{ color: "black" }}>Produto</th>
                                    <th style={{ color: "black" }}>Objetivo</th>
                                    <th style={{ color: "black" }}>Preço</th>
                                    <th style={{ color: "black" }}>Qtd. Contratada</th>
                                    <th style={{ color: "black" }}>Negociação </th>
                                    <th style={{ color: "black" }}>Dt. Inicial</th>
                                    <th style={{ color: "black" }}>Dt. Final</th>
                                    <th style={{ color: "black" }}>Valor Final</th>
                                </tr>
                            </thead>

                            <tbody style={{ backgroundColor: "var(--purple)", width: "100%" }}>
                                {proposalSelected.products.map((p) => (
                                    <tr key={p.fk_id_product} style={{ color: "black" }}>
                                        <td className="table_td">{p.name}</td>
                                        <td className="table_td">{p.objective}</td>
                                        <td className="table_td">{parseFloat(p.price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                        <td className="table_td">{p.quantity_hired}</td>
                                        <td className="table_td">{p.negociation}</td>
                                        <td className="table_td">{moment(p.dt_start).format('DD/MM/YYYY')}</td>
                                        <td className="table_td">{moment(p.dt_end).format('DD/MM/YYYY')}</td>
                                        <td className="table_td">{(p.negociation > 0 ? ((p.price - p.price * p.negociation / 100) * p.quantity_hired) : (p.price * p.quantity_hired)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div>
                            <div style={{ width: '100%', display: 'flex', marginTop: 10 }}>
                                <label className="label_values" style={{ fontWeight: 'bold' }}>Desc. Padrão%</label>
                                <label className="label_values" style={{ fontWeight: 'bold' }}>Valor Bruto Proposta</label>
                                <label className="label_values" style={{ fontWeight: 'bold' }}>Desconto Padrão Proposta</label>
                                <label className="label_values" style={{ fontWeight: 'bold' }}>Valor Líquido Proposta</label>
                            </div>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <label className="label_values">{proposalSelected.proposal_values ? proposalSelected.proposal_values[0].standard_discount : ''}%</label>
                                <label className="label_values">{proposalSelected.proposal_values ? proposalSelected.proposal_values[0].gross_value_proposal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</label>
                                <label className="label_values">{proposalSelected.proposal_values ? proposalSelected.proposal_values[0].standard_discount_proposal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</label>
                                <label className="label_values">{proposalSelected.proposal_values ? proposalSelected.proposal_values[0].net_value_proposal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''} </label>
                            </div>
                        </div>
                        <div>
                            <div style={{ width: '100%', display: 'flex', marginTop: 10 }}>
                                <label className="label_values" style={{ fontWeight: 'bold' }}></label>
                                <label className="label_values" style={{ fontWeight: 'bold' }}>Valor Bruto Aprovado</label>
                                <label className="label_values" style={{ fontWeight: 'bold' }}>Desconto Padrão Aprovado</label>
                                <label className="label_values" style={{ fontWeight: 'bold' }}>Valor Líquido Aprovado</label>
                            </div>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <label className="label_values"></label>
                                <label className="label_values">{proposalSelected.proposal_values ? proposalSelected.proposal_values[0].approved_gross_value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</label>
                                <label className="label_values">{proposalSelected.proposal_values ? proposalSelected.proposal_values[0].standard_discount_approved.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}</label>
                                <label className="label_values">{proposalSelected.proposal_values ? proposalSelected.proposal_values[0].net_value_approved.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''} </label>
                            </div>
                        </div>
                    </div>
                ) : null}
                <CommonView dialog={true} data={singleProposal2} />

            </CommonDialog>


            <label className='title'>{title} - {(data || []).length}</label>
            <div className='render10'>
                {(data || []).map(d => (
                    <div className='renderSingle'
                        onClick={() => {
                            openView(d.id_proposals)
                        }}
                    >
                        <span>{d.campaign}</span>
                        <label>{moment(d.dt_cad).format('DD/MM/YYYY HH:mm:ss')}</label>

                    </div>
                ))}
            </div>
        </div>
    )
}

export default ListProposalDetail