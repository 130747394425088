import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import moment from "moment-timezone";
const ProposalHistoric = ({ proposal }) => {
    const [historic, setHistoric] = useState([])
    useEffect(() => {
        axios.get(Constants.APIEndpoints.PROPOSAL + '/gethistoric/' + proposal.id_proposals).then(res => {
            setHistoric(res.data)
        })
    }, [])

    return (
        <div>
            <div style={{ marginTop: 60 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} style={{ backgroundColor: 'white' }} >
                        <Grid item xs={12}>
                            <div className="cardProposal">
                                <div>
                                    <table style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ color: "black", width: '15%', textAlign: 'start', fontSize: 16 }}>Data</th>
                                                <th style={{ color: "black", width: '15%', textAlign: 'start', fontSize: 16 }}>Usuário</th>
                                                <th style={{ color: "black", width: '70%', textAlign: 'start', fontSize: 16 }}>Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {historic.map(hist => (
                                                <tr key={hist.id_proposal_historic} style={{ color: "black" }}>
                                                    <td className="table_td" style={{ textAlign: 'start' }}>{moment(hist.dt_cad).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                    <td className="table_td" style={{ textAlign: 'start' }}>{hist.user}</td>
                                                    <td className="table_td" style={{ textAlign: 'start' }}>{(hist.action || '').replace(/<[^>]*>?/gm, '')}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Grid>


                    </Grid>
                </Box>
            </div>
        </div>
    )
}

export default ProposalHistoric