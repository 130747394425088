import { motion } from "framer-motion";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import UserForm from "./TeamForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonView from '../../components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";
import moment from "moment-timezone";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import CommonForm from "app/components/form/CommonForm";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}

function TeamList(props) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [addUserDialog, setAddUserDialog] = useState(false)
  const [teamSelected, setTeamSelected] = useState([])
  const [usersOptions, setUsersOptions] = useState([])

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Líder",
        accessor: "leader",
        className: "font-medium",
        sortable: true,
      },

      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {access.team_adduser ? (
              <IconButton
                onClick={(ev) => {
                  setTeamSelected(row.original)
                  setAddUserDialog(true)
                }}
              >
                <Icon>add</Icon>
              </IconButton>
            ) : null}
            {access.team_update ? (
              <IconButton
                onClick={(ev) => {
                  setValues(row.original)
                  setPage('add')
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            ) : null}
            {access.team_delete ? (
              <IconButton
                onClick={(ev) => {
                  setTeamSelected(row.original)
                  setDeleteDialog(true)
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  useEffect(() => {
    getData();
    getUsers()
  }, []);

  const getUsers = () => {
    axios.get(Constants.APIEndpoints.USER + "/getAllUsersSimple").then((res) => {
      const users = res.data.map(user => ({ value: user.id_user, label: user.name }))
      setUsersOptions(users)
    });
  }

  const deleteTeam = (id) => {
    const data = { id_team: teamSelected.id_team }
    axios.post(Constants.APIEndpoints.TEAM + "/deleteTeam", data).then((res) => {
      setDeleteDialog(false)
      getData();
    });
  };

  const getData = () => {
    axios.get(Constants.APIEndpoints.TEAM + "/getAllTeams").then((res) => {
      const teams = res.data.map(d => ({
        ...d,
        comission: `${d.comission}%`
      }))
      setData(teams);
    });
  };

  const submitUsers = (values) => {
    values.fk_id_team = teamSelected.id_team
    axios.post(Constants.APIEndpoints.TEAM + '/addUserToTeam', values).then(res => {
      setAddUserDialog(false)
      getData()
    })
  }


  if (!data) {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >
      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar esse Time?" cancel={() => setDeleteDialog(false)} confirm={deleteTeam} />
      ) : null}

      {addUserDialog && (
        <CommonDialog
          open={true}
          onClose={() => setAddUserDialog(false)}
          title="Adicionar usuário ao time"
        >
          <CommonForm
            fields={[
              {
                col: 12,
                name: 'users',
                type: 'listCheckbox',
                label: 'Usuários',
                options: usersOptions
              }
            ]}
            values={teamSelected}
            onSubmit={submitUsers}

          />

        </CommonDialog>
      )}

      {page == "list" ? (
        <CommonTable
          id="team_list"
          columns={columns}
          data={data}
          icon="account_box"
          newText="Adicionar novo time"
          onAdd={access.team_create ? onAdd : null}
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <UserForm values={values} setPage={setPage} getData={getData} usersOptions={usersOptions} />
      )}
    </motion.div>
  );
}

export default TeamList;
