export class EventBus {
    constructor() {
        this.events = []
    }
    on(eventName, callback) {
        if (typeof callback !== "function") {
            throw new Error("EventBus 'on' method expects a callback function.");
        }

        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }

        this.events[eventName].push(callback);

        return this;
    }

    emit(eventName, ...args) {
        const callbacks = this.events[eventName];
        if (callbacks) {
            callbacks.forEach((callback) => callback(...args));
        }

        return this;
    }


}

export const eventBus = new EventBus();
