import React, { useMemo, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import TagsForm from "./TagsForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
export default function TagsList(props) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});
  const [singleTag, setSingleTag] = useState([])
  const [tagDialog, setTagDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [tagSelected, setTagSelected] = useState([])

  const columns = useMemo(
    () => [

      {
        Header: "Nome",
        accessor: "name",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: 'Acesso',
        accessor: 'role',
        className: 'font-medium',
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {(row.original.roles || []).join(', ')}
          </div>
        )
      },
      {
        Header: 'Atribui a Lead?',
        accessor: 'tag_loss',
        className: 'font-medium',
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.tag_loss ? 'Sim' : 'Não'}
          </div>
        )
      },
      {
        Header: "Cor",
        accessor: "color",
        noSearchable: true,
        Cell: ({ row }) => (
          <div className="flex items-center" style={{ backgroundColor: row.original.color, height: 40, width: 120, borderRadius: 20, margin: 'auto' }}>
          </div>
        ),
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {access.tag_update ? (
              <IconButton
                onClick={(ev) => {
                  setValues(row.original)
                  setPage('add')
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            ) : null}
            {access.tag_delete ? (
              <IconButton
                onClick={(ev) => {
                  setTagSelected(row.original)
                  setDeleteDialog(true)
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteTags = (id) => {
    const data = { id_tags: tagSelected.id_tags }

    axios.post(Constants.APIEndpoints.TAGS + "/deleteTag", data).then((res) => {
      setDeleteDialog(false)
      getData();
    });
  };



  const getData = () => {
    axios.get(Constants.APIEndpoints.TAGS + "/getAllTags").then((res) => {
      setData(res.data);
    });
  };
  if (!data) {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >


      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar essa Tag?" cancel={() => setDeleteDialog(false)} confirm={deleteTags} />
      ) : null}



      {page == "list" ? (
        <CommonTable
          id="tag_list"
          columns={columns}
          data={data}
          icon="people"
          newText="Adicionar Nova Tag"
          onAdd={access.tag_create ? onAdd : null}
          headerTitle="Etiquetas"
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <TagsForm values={values} setPage={setPage} getData={getData} />
      )}
    </motion.div>
  );
}

