import React, { useState } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { useResizeDetector } from 'react-resize-detector';
import moment from 'moment'
function VehicleForm({ values, setPage, getData, users, squares, groups }) {
  if (values && values.users_vehicle) {
    values.users = values.users_vehicle
  }
  if (values && values.time) {
    values.time = values.time ? moment(values.time).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
  }
  if (values && values.comission_mosaic) {
    values.comission_mosaic = values.comission_mosaic.toString()
  }
  if (values && values.transfer_value) {
    values.transfer_value = values.transfer_value.toString()
  }
  if (values && values.taxes) {
    values.taxes = values.taxes.toString()
  }

  const [valuesForm, setValuesForm] = useState(values)
  const { width, ref } = useResizeDetector();

  const fields = [
    {
      col: 12,
      type: "text",
      name: "fancy_name",
      label: "Nome Fantasia",
      required: true
    },
    {
      col: 6,
      type: "text",
      name: "company_name",
      label: "Razão Social",
      required: true
    },
    {
      col: 6,
      type: "text",
      name: "cnpj",
      label: "CNPJ",
      format: '##.###.###/#####-##',

    },

    {
      col: 4,
      type: "text",
      name: "contat",
      label: "Contato",
    },
    {
      col: 4,
      type: "text",
      name: "phone",
      label: "Telefone",
    },

    {
      col: 4,
      type: "text",
      name: "email",
      label: "Email",
    },
    {
      col: 8,
      type: "text",
      name: "address",
      label: "Endereço",
    },
    {
      col: 4,
      type: "select",
      name: "wallet",
      label: "Carteira",
      options: groups.map(v => {
        return {
          value: v.id_groups,
          label: v.name
        }
      }),
    },
    {
      col: 4,
      type: "select",
      name: "fk_id_square",
      label: "Praça",
      options: squares.map(v => {
        return {
          value: v.id_square,
          label: v.uf
        }
      }),

    },
    {
      col: 4,
      type: "select",
      name: "fk_id_group",
      label: "Grupo",
      options: groups.map(v => {
        return {
          value: v.id_groups,
          label: v.name
        }
      }),
    },
    {
      col: 4,
      label: "Representante",
      name: "sponsor",
      type: "text",
    },
    {
      col: 4,
      label: "Comissão do Representante",
      name: "comission_mosaic",
      type: "number",
    },
    {
      col: 4,
      label: "Impostos",
      name: "taxes",
      type: "number",
    },
    {
      col: 4,
      label: "Valor de Repasse",
      name: "transfer_value",
      type: "number",
    },
    {
      col: 12,
      label: 'Usuários',
      name: 'users',
      type: 'listCheckbox',
      options: users.map(u => ({ value: u.id_user, label: u.name }))
    },
    {
      col: 6,
      label: "Prazo",
      name: "time",
      type: "date",
    },
  ];
  const onSubmit = () => {
    axios.post(Constants.APIEndpoints.VEHICLE + (values.id_vehicle ? '/updateVehicle' : '/createVehicle'), valuesForm).then(() => {
      setPage('list')
      getData();
    }).catch(error => {
      console.log(error)
    })


  }

  return (
    <div ref={ref}>
      <CommonHeader title="Criar Veículo"
        width={width}
        onBack={() => setPage('list')} />
      <CommonForm
        values={valuesForm}
        hasHeader={true}
        fields={fields}
        onChangeField={(f, v) => {
          values[f.name] = v;
          setValuesForm(values)
        }}
        onSubmit={onSubmit}
      />

    </div>
  );
}

export default VehicleForm;
