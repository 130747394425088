import React, { useEffect, useState } from "react"
import CommonForm from "app/components/form/CommonForm"
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from "@material-ui/core";

import Field from "./formulas/Field";
import Operator from "./formulas/Operator";
import Formula from "./formulas/Formula";
import Number from "./formulas/Number";
import Store from "app/utils/Store";
let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const Params = ({

}) => {
    const [data, setData] = useState([])
    const [formula, setFormula] = useState([])
    const [squares, setSquares] = useState([])

    useEffect(() => {
        getData();
        getFormula()
        axios
            .get(
                Constants.APIEndpoints.SQUARE + "/getAllSquares")
            .then((res) => {
                setSquares(res.data[0])
            })
    }, [])

    const getData = () => {
        axios.get(Constants.APIEndpoints.PARAMS + '/getParams').then(res => {
            setData(res.data[0])
        })
    }

    const getFormula = () => {
        axios.get(Constants.APIEndpoints.USER + '/getUserFormula/0', '').then(res => {
            setFormula(res.data)
        })
    }

    const saveDateRefReports = (values) => {
        axios.post(Constants.APIEndpoints.PARAMS + '/updateParams', values).then(res => {
            getData()
        })
    }
    const saveFormulaUser = (values) => {
        axios.post(Constants.APIEndpoints.USER + '/saveFormula', values).then(res => {
        })
    }

    return (
        <div
            style={{ padding: 10 }}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1} >
                    <Grid item xs={12} className="paramsGrid">
                        <CommonForm
                            values={data}
                            fields={[{
                                col: 12,
                                type: 'select',
                                name: 'date_ref_reports',
                                label: 'Qual data dos negócios levar em consideração nos relatórios?',
                                options: [
                                    { value: 1, label: 'Mês da Venda' },
                                    { value: 2, label: 'Data de Ganho' },
                                    { value: 3, label: 'Data de Veiculação' }
                                ]
                            },
                            {
                                col: 12,
                                type: "select",
                                name: "default_square",
                                label: "Qual a praça padrão nos negócios?",
                                options: squares.map(sq => {
                                    return {
                                        value: sq.id_square,
                                        label: sq.uf
                                    }
                                }),
                            },
                            {
                                col: 3,
                                type: "checkbox",
                                name: "disable_team_proposals",
                                label: "Desabilitar time na tela de negócios",
                            },
                            ]}
                            onSubmit={access.config_params ? saveDateRefReports : null}
                            onChangeField={(f, v) => {
                                setData(prev => ({ ...prev, [f.name]: v }))

                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} className="paramsGrid">
                        <Typography>Fórmula Global de Veículos</Typography>
                        <Typography>Total de vendas do time: total_vendas_time</Typography>
                        <Typography>Total de vendas do veículo: total_vendas_veiculo</Typography>

                        <CommonForm
                            fields={[
                                {
                                    col: 12,
                                    type: 'text',
                                    name: 'formula',
                                    label: 'Fórmula'
                                },
                                {
                                    col: 2,
                                    type: 'checkbox',
                                    name: 'retro',
                                    label: 'Retroavito?'
                                },
                                {
                                    col: 10,
                                    type: 'date',
                                    name: 'start_date',
                                    label: 'A partir de:',
                                    visible: retro == true ? true : false
                                }
                            ]}
                            onChangeField={(f, v) => {
                                if (f.name == 'retro') {
                                    setRetro(v)
                                }
                            }}
                            values={data}
                            onSubmit={saveFormulaVehicle}
                        />
                    </Grid> */}
                    <Grid container spacing={1} className="paramsGrid">
                        <Grid item xs={3}>
                            <div className="sidebar">
                                <Number />
                                <Field label="Total de Vendas" />
                                <Field label="Vendas do Time" description="Metas já são incluídas no cálculo" />
                                <Field label="Vendas do Veículo" description="Metas já são incluídas no cálculo" />
                                <Field label="Vendas do Usuário" />
                                <Operator label="SE" description="Seguir a regra do Excel: SE(condição, valor1, valor2)" />
                                <Operator label="+" />
                                <Operator label="-" />
                                <Operator label="*" />
                                <Operator label="/" />
                                <Operator label=">" />
                                <Operator label="<" />
                                <Operator label="=" />
                                <Operator label="(" />
                                <Operator label=")" />
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <Formula saveFormula={saveFormulaUser} oldFormula={formula} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>


            <ToastContainer
                position="bottom-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
        </div>
    )
}

export default Params