import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ContactListItem from './ContactListItem';
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import ContactNewSidebar from './ContactNewSidebar'
import ContactViewSidebar from './ContactViewSidebar';
import { Box } from '@mui/system';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment-timezone'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import Store from "app/utils/Store";

import CommonForm from 'app/components/form/CommonForm';
let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const ContactsList = ({

}) => {
    const [rightSidebarView, setRightSidebarView] = useState(false);
    const [rightSidebarNew, setRightSidebarNew] = useState(false);
    const [singleContact, setSingleContact] = useState([])
    const [searchText, setSearchText] = useState('')
    const [contacts, setContacts] = useState([])
    const [allContacts, setAllContacts] = useState([])
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [monthSelected, setMonthSelected] = useState(0)
    const [totalContacts, setTotalContacts] = useState(0)

    let scrollParentRef = useRef()
    useEffect(() => {
        getData();
    }, [])

    const groupContactsByInitialLetter = (contacts) => {
        const groupedArray = contacts.reduce((groups, current) => {
            const firstLetter = current.name.charAt(0).toUpperCase();

            if (groups[firstLetter]) {
                groups[firstLetter].push(current);
            } else {
                groups[firstLetter] = [current];
            }

            return groups;
        }, {});
        return groupedArray
    }

    const onEdit = (mailing) => {
        setSingleContact(mailing)
        setRightSidebarView(false)
        setRightSidebarNew(true)
    }

    const onRemove = (mailing) => {
        setDeleteDialog(true)
        setSingleContact(mailing)
    }

    const deleteContact = () => {
        const data = { id_mailing: singleContact.id_mailing }
        axios.post(Constants.APIEndpoints.MAILING + "/deleteMailing", data).then((res) => {
            setRightSidebarView(false)
            setDeleteDialog(false)
            getData();
        });
    }

    const exportCards = () => {
        let file = `Usuários_${moment().format('DDMMYYYY_HH:mm:ss')}.xlsx`
        const token = sessionStorage.getItem('token');

        fetch(Constants.APIEndpoints.MAILING + '/exportMailings',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Access-Token': token

                }
            }).then(results => {
                return results.blob()
            }).then(response => {
                let data = response;
                let elm = document.createElement('a');
                elm.href = URL.createObjectURL(data);
                elm.setAttribute('download', file);
                elm.click();
                elm.remove();
            })
    }

    const getData = (limit = 75, offset = 0, filters = {}) => {
        axios.post(Constants.APIEndpoints.MAILING + `/getAllMailings?limit=${limit}&&offset=${offset}`, filters).then((res) => {
            setTotalContacts(res.data.length)
            const contactsGrouped = groupContactsByInitialLetter([...allContacts, ...res.data])
            setAllContacts(prev => [...prev, ...res.data])
            setContacts(contactsGrouped);
        });
    };

    const getDataFilter = (limit = 75, offset = 0, filters = {}) => {
        axios.post(Constants.APIEndpoints.MAILING + `/getAllMailings?limit=${limit}&&offset=${offset}`, filters).then((res) => {
            setTotalContacts(res.data.length)
            const contactsGrouped = groupContactsByInitialLetter(res.data)
            setAllContacts(res.data)
            setContacts(contactsGrouped);
        });
    };

    return (
        <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex flex-col flex-auto w-full max-h-full"
        >
            <div className="p-24 sm:p-32 w-full border-b-1"
                style={{ backgroundColor: 'white', position: 'fixed', top: 0, zIndex: 1000, marginTop: 50, width: '-webkit-fill-available' }}>
                <div className='flex' style={{ justifyContent: 'space-between' }}>
                    <div className="flex flex-col items-center sm:items-start">
                        <Typography
                            component={motion.span}
                            initial={{ x: -20 }}
                            animate={{ x: 0, transition: { delay: 0.2 } }}
                            delay={300}
                            style={{ fontSize: 14 }}
                            className="text-24 md:text-32 font-extrabold tracking-tight leading-none"
                        >
                            Contatos
                        </Typography>
                        <Typography
                            component={motion.span}
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                            delay={500}
                            style={{ fontSize: 14 }}
                            className="text-14 font-medium"
                            color="text.secondary"
                        >
                            {`${totalContacts} contatos`}
                        </Typography>
                    </div>
                    <div style={{ width: '70%', marginTop: -20 }}>
                        <CommonForm
                            fields={[{
                                col: 12,
                                type: 'select',
                                name: 'month',
                                label: 'Filtrar por mês',
                                options: [
                                    { value: 0, label: 'TODOS' },
                                    { value: 1, label: "Janeiro" },
                                    { value: 2, label: "Fevereiro" },
                                    { value: 3, label: "Março" },
                                    { value: 4, label: "Abril" },
                                    { value: 5, label: "Maio" },
                                    { value: 6, label: "Junho" },
                                    { value: 7, label: "Julho" },
                                    { value: 8, label: "Agosto" },
                                    { value: 9, label: "Setembro" },
                                    { value: 10, label: "Outubro" },
                                    { value: 11, label: "Novembro" },
                                    { value: 12, label: "Dezembro" },
                                ]
                            }]}
                            onChangeField={(f, v) => {
                                setMonthSelected(v)
                                getDataFilter(30, 0, { search: searchText, month: v })

                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center mt-16 -mx-8">
                    <Box
                        component={motion.div}
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                        className="flex flex-1 w-full sm:w-auto items-center px-16 mx-8 border-1 rounded-full"
                    >
                        <FuseSvgIcon color="action" size={20}>
                            heroicons-outline:search
                        </FuseSvgIcon>

                        <Input
                            placeholder="Buscar"
                            className="flex flex-1 px-16"
                            disableUnderline
                            style={{ height: 40, fontSize: 16 }}
                            fullWidth
                            value={searchText}
                            inputProps={{
                                'aria-label': 'Search',
                            }}
                            onChange={(ev) => {
                                setSearchText(ev.target.value)
                                getDataFilter(30, 0, { search: ev.target.value, month: monthSelected })
                            }
                            }
                        />
                    </Box>
                    {access.mailing_create && (
                        <Button
                            className="mx-8"
                            variant="contained"
                            color="secondary"
                            onClick={() => setRightSidebarNew(true)}
                        >
                            <FuseSvgIcon size={20}>heroicons-outline:plus</FuseSvgIcon>
                            <span className="mx-8">Novo</span>
                        </Button>
                    )}
                    <button className='exportButton'
                        style={{ marginBottom: 20, color: '#9c27b0' }}
                        onClick={() => exportCards()}>
                        <GetAppIcon />
                    </button>
                </div>
            </div>


            <div style={{ marginTop: 205 }}>
                {Object.entries(contacts).map(([key, group]) => {
                    return (
                        <div key={key} className="relative">
                            <Typography color="text.secondary" className="px-32 py-4 text-14 font-medium"
                                style={{ height: 30, fontSize: 20, fontWeight: 'bold' }}>
                                {key}
                            </Typography>
                            <List className="w-full m-0 p-0">
                                {group.map((item) => (
                                    <ContactListItem key={item.id} contact={item} setRightSidebarView={setRightSidebarView}
                                        setSingleContact={setSingleContact} />
                                ))}
                            </List>
                        </div>
                    )
                }
                )}

            </div>

            {rightSidebarView == true && (
                <ContactViewSidebar
                    open={rightSidebarView}
                    onClose={() => {
                        setRightSidebarView(false)
                        setSingleContact([])
                    }}
                    mailing={singleContact}
                    onEdit={onEdit}
                    onRemove={onRemove}
                />
            )}
            {rightSidebarNew == true && (
                <ContactNewSidebar
                    open={rightSidebarNew}
                    onClose={() => {
                        setRightSidebarNew(false)
                        setSingleContact([])
                    }}
                    values={singleContact}
                    getData={getData}
                />
            )}
            {deleteDialog ? (
                <ConfirmDialog title="Deseja deletar esse contato?" cancel={() => setDeleteDialog(false)} confirm={deleteContact} />
            ) : null}

        </motion.div>
    );

}

export default ContactsList