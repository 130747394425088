import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonView from '../../components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import AccessForm from "./AccessForm";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
export default function AccessList(props) {
    const dispatch = useDispatch();
    const user = [];
    const [data, setData] = useState([]);
    const [page, setPage] = useState("list");

    const [values, setValues] = useState({});

    const [singleAccess, setSingleAccess] = useState([])
    const [accessDialog, setAccessDialog] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [accessSelected, setAccessSelected] = useState([])



    const columns = useMemo(
        () => [

            {
                Header: "Nome",
                accessor: "name",
                className: "font-medium",
                sortable: true,
            },
            {
                id: "action",
                width: 128,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex items-center">
                        <IconButton
                            onClick={(ev) => {
                                viewAccess(row.original);
                            }}
                        >
                            <Icon>remove_red_eye</Icon>
                        </IconButton>

                        {!['checking', 'financeiro'].includes(logged_user.role) ? (

                            <IconButton
                                onClick={(ev) => {

                                    setValues(row.original)
                                    setPage('form')
                                }}
                            >
                                <Icon>edit</Icon>
                            </IconButton>
                        ) : null}

                        {!['checking', 'opec', 'financeiro', 'comercial', 'subadmin'].includes(logged_user.role) ? (
                            <IconButton
                                onClick={(ev) => {
                                    setAccessSelected(row.original)
                                    setDeleteDialog(true)
                                }}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        ) : null}
                    </div>
                ),
            },
        ],
        [dispatch, user.starred]
    );

    const onAdd = () => {
        setValues({})
        setPage("form");
    };

    useEffect(() => {
        getData();
    }, []);


    const deleteAccess = () => {
        axios.delete(Constants.APIEndpoints.ACCESS + "/deleteAccess/" + accessSelected.id_access).then((res) => {
            getData();
            setDeleteDialog(false)
        });
    };




    const viewAccess = (access) => {

        setAccessDialog(true)
        let data = [

        ]

        setSingleAccess(data)
    }


    const getData = () => {
        axios.get(Constants.APIEndpoints.ACCESS + "/getallaccess").then((res) => {
            setData(res.data);
        });
    };


    return (
        <motion.div
            initial={{ y: -80, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
            {deleteDialog ? (
                <ConfirmDialog title="Deseja deletar esse Accesso?" cancel={() => setDeleteDialog(false)} confirm={deleteAccess} />
            ) : null}

            <CommonDialog
                open={accessDialog}
                onClose={() => setAccessDialog(false)}
                title="Ver Acesso"
                width="xl"
                print={true}
            >
                <CommonView dialog={true} data={singleAccess} title="Ver Acesso" onBack={() => setPage('list')} />
            </CommonDialog>



            {page == "list" ? (
                <CommonTable
                    id="access_list"
                    columns={columns}
                    data={data}
                    icon="people"
                    newText="Adicionar Novo Acesso"
                    onAdd={onAdd}
                    headerTitle="Acessos"
                    onRowClick={(ev, row) => {
                        if (row) {
                            //            dispatch(openEditContactDialog(row.original));
                        }
                    }}
                />
            ) : (
                <AccessForm values={values} setPage={setPage} getData={getData} />
            )}
        </motion.div>
    );
}

