import React, { useState, useEffect } from 'react'
import { TextField, FormControlLabel, Grid, Checkbox, Divider } from '@material-ui/core';
import Input from '../input/Input';

const ListCheckbox = ({
    options,
    field,
    changeValue,
    values
}) => {
    const [itensChecked, setItensChecked] = useState([])
    const [checkAll, setCheckAll] = useState(false)
    const [optionsList, setOptionsList] = useState([])
    const [firstRender, setFirstRender] = useState(false)
    useEffect(() => {
        if (options.length > 0 && firstRender == false) {
            setOptionsList(options)
            setFirstRender(true)
        }
    }, [options, firstRender])
    const handleCheck = (checked, id) => {
        if (checked == true) {
            let options_ = itensChecked
            options_.push(id)
            setItensChecked([...options_])
        } else {
            let options_ = itensChecked.filter(i => i != id)
            setItensChecked([...options_])
        }
    }

    const handleCheckAll = () => {
        if (itensChecked.length > 0) {
            setItensChecked([])
            setCheckAll(false)
        } else {
            setItensChecked(optionsList.map(o => o.value))
            setCheckAll(true)
        }
    }
    useEffect(() => {
        if (values && values.length > 0) {
            setItensChecked(values)
        }
    }, [values])

    useEffect(() => {
        changeValue(field.name, itensChecked)
    }, [itensChecked])


    const searchOptions = (text) => {
        const optionsFilter = options.filter(o => o.label.toUpperCase().indexOf(text.toUpperCase()) > -1)
        setOptionsList(optionsFilter)
    }

    return (
        <div style={{ maxHeight: 600, overflowX: 'hidden', overflowY: 'hidden' }}>
            <div style={{ width: '100%', minWidth: 150, fontSize: 15, padding: 5, marginLeft: 5 }}>
                <Checkbox
                    checked={checkAll}
                    onChange={handleCheckAll}
                    id={0}
                />
                <label for={0}>Marcar/Desmarcar Todos - Total:</label><strong>{optionsList.length}</strong>

            </div>
            <input
                onChange={evt => searchOptions(evt.target.value)}
                placeholder='Buscar'
                style={{ borderRadius: 10, minWidth: '30%' }}
            />
            <Divider />
            <div style={{ maxHeight: 500, overflowX: 'auto' }}>

                {optionsList && optionsList.map(option => (
                    <div style={{ width: '100%', minWidth: 150, fontSize: 15, padding: 5 }}>
                        <Checkbox
                            checked={itensChecked.includes(option.value)} // recomendo colocar o ||<formato do campo -> string|array|boolean/> caso contrário esse componente pode apresentar falhas; errado: value={values[field.name]}; certo: value={values[field.name] || []}
                            onChange={(evt) => { handleCheck(evt.target.checked, option.value) }}
                            name={field.name}
                            placeholder={field.placeholder}
                            style={{ width: "5%", height: 15 }}
                            disabled={field.disabled}
                            id={option.value}
                        />
                        <label for={option.value}>{option.label}</label>
                    </div>
                ))}
            </div>
        </div>
    )

}

export default ListCheckbox