import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import JustifyForm from "./JustifyForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonView from '../../components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
export default function JustifyList(props) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});


  const [singleJustify, setsingleJustify] = useState([])
  const [justifyDialog, setJustifyDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  let [justifySelected, setJustifySelected] = useState([])

  const columns = useMemo(
    () => [

      {
        Header: "Nome",
        accessor: "name",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Tipo",
        accessor: "type",
        className: "font-medium",
        sortable: true,
        Cell: ({ row }) => (
          <div>
            {row.original.type_justify == 1 ? 'Ganho' : 'Perda'}
          </div>
        )
      },
      {
        Header: "Descrição",
        accessor: "description",
        sortable: true,
        sortable: true,
        noSearchable: true,
        Cell: ({ row }) => (
          <div>
            {(row.original.description || '').replace(/<[^>]*>?/gm, '')}
          </div>
        )
      },

      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">

            {access.justify_update ? (
              <IconButton
                onClick={(ev) => {
                  setValues(row.original)
                  setPage('add')
                }}
              >

                <Icon>edit</Icon>
              </IconButton>
            ) : null}
            {access.justify_delete ? (
              <IconButton
                onClick={(ev) => {
                  setJustifySelected(row.original)
                  setDeleteDialog(true)
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteJustify = (id) => {
    const data = { id_justify: justifySelected.id_justify }

    axios.post(Constants.APIEndpoints.JUSTIFY + "/deletejustify", data).then((res) => {
      getData();
      setDeleteDialog(false)
    });
  };


  const getData = () => {
    axios.get(Constants.APIEndpoints.JUSTIFY + "/getalljustify").then((res) => {
      setData(res.data);
    });
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >

      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar essa Justificativa?" cancel={() => setDeleteDialog(false)} confirm={deleteJustify} />
      ) : null}

      {page == "list" ? (
        <CommonTable
          id="justify_list"
          columns={columns}
          data={data}
          icon="people"
          newText="Adicionar Nova Justificativa"
          onAdd={access.justify_create ? onAdd : null}
          headerTitle="Justificativas"
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <JustifyForm values={values} setPage={setPage} getData={getData} />
      )}
    </motion.div>
  );
}

