import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react'
import FusePageSimple from '@fuse/core/FusePageSimple';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import CalendarHeader from './CalendarHeader';
import locale from '@fullcalendar/core/locales/pt-br'
import axios from 'app/utils/AxiosConfig';
import Constants from 'app/utils/Constants';
import CommonDialog from 'app/components/dialog/CommonDialog';
import CommonForm from 'app/components/form/CommonForm';
import Store from 'app/utils/Store';
import CalendarAppEventContent from './CalendarAppEventContent';
import ViewEventDetail from './ViewEventDetail';
const logged_user = Store.USER

const Calendar = ({ }) => {

    const [events, setEvents] = useState([])

    const [currentDate, setCurrentDate] = useState();
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
    const [createNewEventDialog, setCreateNewEventDialog] = useState(false)
    const [eventClicked, setEventClicked] = useState([])
    const [openEventDetail, setOpenEventDetail] = useState(false)
    const calendarRef = useRef();

    useEffect(() => {
        getEvents()
    }, [])
    const handleDateSelect = (selectInfo) => {
        const { start, end } = selectInfo;
    };

    const getEvents = () => {
        axios.post(Constants.APIEndpoints.EVENTS + '/getallevents', {
            id_user: logged_user.id_user,
            role: logged_user.fk_id_role
        }).then(res => {
            setEvents(res.data)
        })
    }

    const handleEventDrop = (eventDropInfo) => {
    };
    const handleEventClick = (clickInfo) => {
        const name = clickInfo.event.title
        const start = clickInfo.event.startStr
        const end = clickInfo.event.endStr
        const description = clickInfo.event.extendedProps.description
        const type = clickInfo.event.extendedProps.type
        setEventClicked({ name, start, end, description, type })
        setOpenEventDetail(true)
    };

    const handleDates = (rangeInfo) => {
        setCurrentDate(rangeInfo);
    };

    const handleEventAdd = (addInfo) => {
    };

    const handleEventChange = (changeInfo) => {
    };

    const handleEventRemove = (removeInfo) => { };
    function handleToggleLeftSidebar() {
        setLeftSidebarOpen(!leftSidebarOpen);
    }


    const saveEvent = (values) => {
        values.user_cad = logged_user.id_user
        axios.post(Constants.APIEndpoints.EVENTS + '/createevent', values).then(res => {
            setCreateNewEventDialog(false)
            getEvents()
        })
    }

    return (
        <div>
            <CommonDialog
                open={createNewEventDialog}
                onClose={() => setCreateNewEventDialog(false)}
                title='Novo evento'
                width='lg'
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 8,
                            type: 'text',
                            name: 'name',
                            label: 'Nome'
                        },
                        {
                            col: 4,
                            type: 'checkbox',
                            name: 'sendmail',
                            label: 'Receber email lembrando desse evento?'
                        },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_start',
                            label: 'Data de Início'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_start',
                            label: 'Hora de Início'
                        },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_end',
                            label: 'Data de Término'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_end',
                            label: 'Hora de Término'
                        }, {
                            col: 12,
                            type: 'textarea',
                            name: 'description',
                            label: 'Descrição'
                        }
                    ]}
                    onSubmit={saveEvent}
                // values={activitySelected}
                // onChangeField={(f, v) => {
                //     let v_ = { ...activitySelected }
                //     v_[f.name] = v;
                //     setActivitySelected(v_)
                // }}
                />
            </CommonDialog>
            <CommonDialog
                open={openEventDetail}
                onClose={() => setOpenEventDetail(false)}
                title={eventClicked.type == 'activity' ? 'Atividade' : 'Evento'}
                width='xs'
                fullWidth={true}
            >
                <ViewEventDetail event={eventClicked} />
            </CommonDialog>
            <CalendarHeader
                calendarRef={calendarRef}
                currentDate={currentDate}
                onToggleLeftSidebar={handleToggleLeftSidebar}
                setCreateNewEventDialog={setCreateNewEventDialog}
            />
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, /*interactionPlugin */]}
                headerToolbar={false}
                initialView="dayGridMonth"
                editable
                themeSystem="Simplex"
                selectable
                selectMirror
                dayMaxEvents
                weekends
                datesSet={handleDates}
                select={handleDateSelect}
                events={events}
                eventContent={(eventInfo) => <CalendarAppEventContent eventInfo={eventInfo} />}
                eventClick={handleEventClick}
                eventAdd={handleEventAdd}
                eventChange={handleEventChange}
                eventRemove={handleEventRemove}
                eventDrop={handleEventDrop}
                initialDate={new Date()}
                locale={locale}
                ref={calendarRef}
            />
        </div>
    )
}

export default Calendar