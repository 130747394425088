import React from 'react'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import CalendarViewMenu from './CalendarViewMenu';

function CalendarHeader(props) {
    const { calendarRef, currentDate, onToggleLeftSidebar, setCreateNewEventDialog } = props;

    const mainTheme = useSelector(selectMainTheme);
    const calendarApi = () => calendarRef.current?.getApi();
    const dispatch = useDispatch();
    return (
        <div className="flex flex-col md:flex-row w-full p-12 justify-between z-10 container"
            style={{ backgroundColor: 'white', color: 'black' }}>
            <div className="flex flex-col sm:flex-row items-center">
                <div className="flex items-center">
                    <Typography
                        style={{ fontSize: 16, fontWeight: 500 }}
                    >

                        {currentDate?.view.title}
                    </Typography>
                </div>

                <div className="flex items-center">
                    <Tooltip title={<h4>Anterior</h4>}>
                        <IconButton aria-label="Previous" onClick={() => calendarApi().prev()}>
                            <FuseSvgIcon size={20}>
                                {mainTheme.direction === 'ltr'
                                    ? 'heroicons-solid:chevron-left'
                                    : 'heroicons-solid:chevron-right'}
                            </FuseSvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<h4>Próxima</h4>}>
                        <IconButton aria-label="Next" onClick={() => calendarApi().next()}>
                            <FuseSvgIcon size={20}>
                                {mainTheme.direction === 'ltr'
                                    ? 'heroicons-solid:chevron-right'
                                    : 'heroicons-solid:chevron-left'}
                            </FuseSvgIcon>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={<h4>Hoje</h4>}>
                        <div>
                            <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.3 } }}>
                                <IconButton aria-label="today" onClick={() => calendarApi().today()} size="large">
                                    <FuseSvgIcon>heroicons-outline:calendar</FuseSvgIcon>
                                </IconButton>
                            </motion.div>
                        </div>
                    </Tooltip>
                </div>
            </div>

            <motion.div
                className="flex items-center justify-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.3 } }}
            >
                <IconButton
                    className="mx-8"
                    aria-label="add"
                    onClick={() => setCreateNewEventDialog(true)}

                >
                    <FuseSvgIcon>heroicons-outline:plus-circle</FuseSvgIcon>
                </IconButton>

                <CalendarViewMenu currentDate={currentDate} calendarApi={calendarApi} />
            </motion.div>
        </div>
    );
}

export default CalendarHeader;
