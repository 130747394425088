import React, { useState } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import moment from 'moment-timezone'
import { useResizeDetector } from 'react-resize-detector';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        width: 520,
        padding: 15
    },
}));
export default function ContactNewSidebar({
    getData,
    values,
    onClose,
    open
}) {
    const classes = useStyles();

    if (values) {
        values.dt_birthday = values.dt_birthday ? moment(values.dt_birthday).format('YYYY-MM-DD') : ''
        values.dt_start_company = values.dt_start_company ? moment(values.dt_start_company).format('YYYY-MM-DD') : ''
    }
    const { width, height, ref } = useResizeDetector();


    const [valuesForm, setValuesForm] = useState(values)
    const fields = [
        {
            col: 12,
            type: 'image',
            name: 'photo',
            src: valuesForm?.photo?.data ? valuesForm.photo.data : valuesForm?.photo ? valuesForm.photo : ''
        },
        {
            col: 12,
            type: "text",
            name: "name",
            label: "Nome",
            required: true

        },
        {
            col: 12,
            type: "text",
            name: "company_function",
            label: "Função",
        },
        {
            col: 12,
            type: "text",
            name: "email",
            label: "Email",

        },

        {
            col: 12,
            type: "date",
            name: "dt_birthday",
            label: "Data de Nascimento",
        },
        {
            col: 12,
            type: "date",
            name: "dt_start_company",
            label: "Data de início na empresa",
        },

        {
            col: 12,
            type: "text",
            name: "address",
            label: "Endereço",
        },
        {
            col: 12,
            type: "text",
            name: "phone",
            label: "Telefone",
        },
        {
            col: 12,
            type: "select",
            name: "fk_id_type",
            label: "Tipo",
            options: [
                { value: 1, label: "Veículo" },
                { value: 2, label: "Agência" },
                { value: 3, label: "Cliente" },
                { value: 4, label: "Interno" },
            ],
        },

        {
            col: 12,
            label: "Local",
            name: "place",
            type: "text",
            required: true

        }
    ];


    const onSubmit = () => {
        axios.post(Constants.APIEndpoints.MAILING + (valuesForm.id_mailing ? '/updateMailing' : '/createMailing'), valuesForm).then(res => {
            onClose()
            getData();
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <div ref={ref}>
            <SwipeableDrawer
                classes={{ paper: clsx(classes.root) }}
                open={open}
                anchor="right"
                onOpen={(ev) => { }}
                onClose={onClose}
                disableSwipeToOpen
            >
                <CommonForm
                    values={valuesForm}
                    fields={fields}
                    onChangeField={(f, v) => {
                        let v_ = { ...valuesForm }
                        v_[f.name] = v;
                        setValuesForm(v_)
                    }}
                    onSubmit={onSubmit}
                />
            </SwipeableDrawer>
        </div>
    );
}

