import React, { useState, useEffect } from 'react'
import clsx from 'clsx';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';

import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from 'app/utils/Store'
import CommonForm from 'app/components/form/CommonForm';
import { Divider, Typography } from '@material-ui/core';
import '../../newLeads/Leads.css'
import { Input } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        width: 720,
        padding: 15
    },
}));

const FiltersDash = ({
    open,
    onClose,
    onSubmit,
    clients,
    agencies,
    users,
    squares,
    status,
    tags,
    groups,
    justifies,
    vehicles,
    filters,
    teams
}) => {

    const classes = useStyles();
    const fields = [
        {
            col: 6,
            type: 'date',
            name: 'dt_start',
            label: 'Data de Início',
        },
        {
            col: 6,
            type: 'date',
            name: 'dt_end',
            label: 'Data de Término',
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'fk_id_vehicle',
            label: 'Veículo',
            options: vehicles.map(v => ({ value: v.id_vehicle, label: v.fancy_name })),
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'fk_id_user',
            label: 'Executivo',
            options: users.map(v => ({ value: v.id_user, label: v.name })),
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'fk_id_group',
            label: 'Grupos',
            options: groups.map(v => ({ value: v.id_groups, label: v.name })),
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'teams',
            label: 'Times',
            options: teams.map(k => ({ value: k.id_justify, label: k.name })),
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'fk_id_agency',
            label: 'Agências',
            options: agencies.map(v => ({ value: v.id_agency, label: v.fancy_name })),
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'fk_id_status',
            label: 'Status',
            options: status.map(v => ({ value: v.id_status, label: v.name })),
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'tags',
            label: 'Etiquetas',
            options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color })),
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'fk_id_squares',
            label: 'Praças',
            options: squares.map(k => ({ value: k.id_square, label: k.uf })),
        }, {
            col: 12,
            type: 'multiselect',
            name: 'fk_id_client',
            label: 'Clientes',
            options: clients.map(k => ({ value: k.id_client, label: k.fancy_name })),
        },
        {
            col: 12,
            type: 'multiselect',
            name: 'justifies',
            label: 'Justificativas',
            options: justifies.map(k => ({ value: k.id_justify, label: k.name })),
        },
    ]

    return (
        <SwipeableDrawer
            classes={{ paper: clsx(classes.root) }}
            open={open}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={onClose}
            disableSwipeToOpen
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'sticky', top: 0, backgroundColor: 'white' }}>
                <Typography className='title pointer'> FILTRAR</Typography>
            </div>

            <CommonForm
                values={filters}
                fields={fields}
                onSubmit={onSubmit}
            />

        </SwipeableDrawer>
    )
}

export default FiltersDash