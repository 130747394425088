import React from 'react';
import { useTheme } from '@mui/material/styles';
import _ from '@lodash';
import { Box } from '@mui/system';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import moment from 'moment';

function CalendarAppEventContent(props) {
    const { eventInfo } = props;
    const theme = useTheme();
    const label = {
        color: '#000000'
    }



    const infoTooltip = (
        <div style={{
            display: 'contents'
        }}>
            <Typography className="text-16 px-4 truncate label"
                style={{
                    fontSize: 16,
                    textAlign: 'center',
                    fontWeight: 600,
                    width: '100%'
                }}
            >{eventInfo.event.title}</Typography>
            <div
                style={{
                    display: 'flex',
                }}>
                <FuseSvgIcon className="hidden sm:inline-flex mt-16" color="action">
                    heroicons-outline:calendar
                </FuseSvgIcon>
                <Typography className="text-12 font-semibold"
                    style={{
                        marginLeft: 10,
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: 'start',
                        width: '100%',
                        marginTop: 20
                    }}>{moment(eventInfo.event.startStr).format('DD/MM/YYYY HH:mm')} à {moment(eventInfo.event.endStr).format('DD/MM/YYYY HH:mm')}</Typography>
            </div>
            <div
                style={{ marginTop: 20, fontSize: 15 }}
                dangerouslySetInnerHTML={{ __html: eventInfo.event.extendedProps.description }}></div>
        </div>
    )

    return (
        <>
            <Box
                sx={{
                    color: label && theme.palette.getContrastText(label?.color),
                }}
                className={clsx('flex items-center w-full rounded-4 px-8 py-2 h-22 text-white')}
            >
                <Typography className="text-12 font-semibold">{eventInfo.timeText}</Typography>
                <Typography className="text-12 px-4 truncate">{eventInfo.event.title}</Typography>
            </Box>
        </>
    );
}

export default CalendarAppEventContent;
