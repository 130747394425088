
import CommonForm from "app/components/form/CommonForm"
import axios from 'app/utils/AxiosConfig'
import Constants from "app/utils/Constants"
import React, { useState, useEffect } from "react"
import './QA.css'
const QA = ({ }) => {
    const [data, setData] = useState([
        { type: 'chat', value: 'Olá, estou aqui para responder perguntas sobre os dados cadastrados no nosso sistema. O que gostaria de saber?' }
    ])

    const [questions, setQuestions] = useState([])
    const [asking, setAsking] = useState(false)
    const [values, setValues] = useState({})
    useEffect(() => {
        axios
            .get(
                Constants.APIEndpoints.QUESTIONS + "/getallquestions")
            .then((res) => {
                setQuestions(res.data)
            })
    }, [])
    const makeQuestion = (values_) => {
        setAsking(true)
        setData(prev => [...prev, { type: 'user', value: values.question }])

        axios.post(Constants.APIEndpoints.QA + '/makequestion', values).then(res => {
            setData(prev => [...prev, { type: 'chat', value: res.data }])
            setAsking(false)
        })
    }


    return (
        <div>
            <div className="answer">
                {data.map(d => {
                    if (d.type == 'chat') {
                        return (
                            <div className="answerChat" dangerouslySetInnerHTML={{ __html: d.value }} />
                        )
                    } else {
                        return (
                            <div className="answerUser" dangerouslySetInnerHTML={{ __html: d.value }} />
                        )
                    }
                })}
                {asking && (
                    <div class="loading-dots">
                        <span class="dot" id="dot1">.</span>
                        <span class="dot" id="dot2">.</span>
                        <span class="dot" id="dot3">.</span>
                    </div>)}
            </div>
            <CommonForm
                fields={[
                    {
                        col: 4,
                        name: 'default_question',
                        type: 'select',
                        options: questions.map(question => ({ value: question.id_default_questions, label: question.name })),
                        label: 'Selecionar pergunta padrão'
                    },
                    {
                        col: 8,
                        type: 'text',
                        name: 'question',
                        label: 'Pergunta',
                        disabled: true
                    }
                ]}
                values={values}
                onChangeField={(f, v) => {

                    let values_ = values
                    values_.default_question = v
                    values_.question = questions.find(question => question.id_default_questions == v).question
                    setValues(values_)
                }}
                onSubmit={makeQuestion}
                button={{ label: 'Perguntar' }}
            />
        </div>
    )
}

export default QA