import React, { useEffect, useState } from "react"
import Store from 'app/utils/Store'
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import moment from 'moment-timezone'
import CommonForm from "app/components/form/CommonForm";
import { ToastContainer, toast } from 'react-toastify';
import { saveAs } from 'file-saver';
let logged_user = Store.USER

const Report = ({

}) => {
    const [agencies, setAgencies] = useState([])
    const [clients, setClients] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [status, setStatus] = useState([])
    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])
    const [squares, setSquares] = useState([])

    const [typeSelected, setTypeSelected] = useState(0)
    const [item1, setItem1] = useState(0)
    const [item2, setItem2] = useState(0)
    const [item3, setItem3] = useState(0)
    const [item4, setItem4] = useState(0)



    useEffect(() => {
        axios
            .get(
                Constants.APIEndpoints.AGENCY + "/getAllAgencies")
            .then((res) => {
                setAgencies(res.data)
            })
        axios
            .get(
                Constants.APIEndpoints.USER + "/getAllUsersSimple")
            .then((res) => {
                setUsers(res.data)
            })

        axios
            .get(
                Constants.APIEndpoints.CLIENT + "/getAllClients")
            .then((res) => {
                setClients(res.data)
            })

        axios
            .get(
                Constants.APIEndpoints.VEHICLE + "/getAllVehicles")
            .then((res) => {
                setVehicles(res.data)
            })

        axios
            .get(
                Constants.APIEndpoints.STATUS + "/getAllStatus")
            .then((res) => {
                setStatus(res.data[0])
            })

        axios.get(Constants.APIEndpoints.TEAM + '/getAllTeams').then(res => {
            setTeams(res.data)
        })

        axios.get(Constants.APIEndpoints.SQUARE + "/getAllSquares").then((res) => {
            setSquares(res.data[0]);
        });
    }, [])

    const getRoute = (type) => {
        const route = type == 1 ?
            'report/exportProposals' :
            type == 2 ?
                'kanban/exportCards' :
                type == 3 ?
                    'report/exportVehicles' :
                    type == 4 ?
                        'report/exportClients' :
                        type == 5 ?
                            'report/exportUsers' :
                            type == 6 ?
                                'report/exportStatus' :
                                type == 7 ?
                                    'report/exportAgencies' :
                                    type == 8 ?
                                        'report/exportTeams' :
                                        type == 9 ? 'report/exportProducts' : 'report/exportSquares'
        return route

    }

    const getNameFile = (type) => {
        const namefile = type == 1 ?
            'Negócios_' :
            type == 2 ?
                'Leads_' :
                type == 3 ?
                    'Veículos_' :
                    type == 4 ?
                        'Clientes_' :
                        type == 5 ?
                            'Usuários_' :
                            type == 6 ?
                                'Status_' :
                                type == 7 ?
                                    'Agências_' :
                                    type == 8 ?
                                        'Times_' :
                                        type == 9 ? 'Produtos_' : 'Praças'
        return namefile
    }

    const exportData = (values) => {
        const type = values.type
        const route = getRoute(type)
        let file = `${getNameFile(type)}-${moment().format('DDMMYYYY_HH:mm:ss')}.xlsx`
        const token = sessionStorage.getItem('token');
        const notification = toast("Gerando Relatório");

        fetch(Constants.APIRoot + route,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                body: JSON.stringify({
                    ...values,
                    user: logged_user.id_user
                })
            }).then(results => {
                return results.blob()
            }).then(response => {
                saveAs(response, file);
                toast.dismiss(notification)

            })

    }



    let fields = [
        {
            col: 3,
            type: 'select',
            name: 'type',
            label: 'Tipo',
            required: true,
            options: [
                { value: 1, label: 'Negócios' },
                { value: 2, label: 'Leads' },
                { value: 3, label: 'Veículo' },
                { value: 4, label: 'Cliente' },
                { value: 5, label: 'Usuários' },
                //{ value: 6, label: 'Status' },
                { value: 7, label: 'Agência' },
                { value: 8, label: 'Time' },
                { value: 9, label: 'Produtos' },
                //{ value: 10, label: 'Praças' },
            ]
        },
        {
            col: 3,
            name: 'fk_id_client',
            label: 'Clientes',
            visible: typeSelected == 1 || typeSelected == 7,
            type: 'multiselect',
            options: clients.map(v => ({ value: v.id_client, label: v.fancy_name }))
        },
        {
            col: 3,
            name: 'fk_id_vehicle',
            label: 'Veículos',
            visible: typeSelected == 1 || typeSelected == 5 || typeSelected == 9,
            type: 'multiselect',
            options: vehicles.map(v => ({ value: v.id_vehicle, label: v.fancy_name }))
        },
        {
            col: 3,
            name: 'fk_id_responsable',
            label: 'Executivo',
            visible: typeSelected == 1,
            type: 'multiselect',
            options: users.map(v => ({ value: v.id_user, label: v.name }))
        },
        {
            col: 3,
            name: 'fk_id_agency',
            label: 'Agência',
            visible: typeSelected == 1 || typeSelected == 4,
            type: 'multiselect',
            options: agencies.map(v => ({ value: v.id_agency, label: v.fancy_name }))
        },
        {
            col: 3,
            name: 'fk_id_square',
            label: 'Praça',
            visible: typeSelected == 1 || typeSelected == 3,
            type: 'multiselect',
            options: squares.map(v => ({ value: v.id_square, label: v.federative_unit }))
        },
        {
            col: 3,
            name: 'fk_id_status',
            label: 'Status',
            visible: typeSelected == 1,
            type: 'multiselect',
            options: status.map(v => ({ value: v.id_status, label: v.name }))
        },
        {
            col: 3,
            name: 'fk_id_team',
            label: 'Time',
            visible: typeSelected == 1,
            type: 'multiselect',
            options: teams.map(v => ({ value: v.id_team, label: v.name }))
        },


        {
            col: 3,
            type: 'date',
            label: 'De',
            name: 'dt_start',
            visible: typeSelected !== 8
        },
        {
            col: 3,
            type: 'date',
            label: 'Até',
            name: 'dt_end',
            visible: typeSelected !== 8
        }
    ]

    const button = {
        label: 'EXPORTAR',
    }

    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <div style={{ marginTop: 60 }}>
                <CommonForm
                    fields={fields}
                    onChangeField={(f, v) => {
                        if (f.name == 'type') {
                            setTypeSelected(v)
                        }
                        if (f.name == 'item1') {
                            setItem1(v)
                        }
                        if (f.name == 'item2') {
                            setItem2(v)
                        }
                        if (f.name == 'item3') {
                            setItem3(v)
                        }
                        if (f.name == 'item4') {
                            setItem4(v)
                        }
                    }}
                    values={{ type: typeSelected }}
                    onSubmit={exportData}
                    button={button}
                />
            </div>
        </div>)
}

export default Report