import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon"
import Constants from "app/utils/Constants"
import Store from "app/utils/Store"
import axios from "app/utils/AxiosConfig"
import React, { Fragment, useEffect, useState } from "react"
import './home.css'
import { Box, Typography } from "@material-ui/core"
import Grid from "@mui/material/Grid";
import Events from "./Events"
import Activities from "./Activities"

const user = Store.USER
const Home = () => {
    const [logoUser, setLogoUser] = useState('')
    const [events, setEvents] = useState([])
    const [activities, setActivities] = useState([])

    useEffect(() => {
        axios.get(Constants.APIEndpoints.HOME + '/getlogouser').then(res => {
            setLogoUser(res.data)
        })
        getEvents()
    }, [])

    const changeLogo = (logo) => {
        axios.post(Constants.APIEndpoints.HOME + '/savelogouser', JSON.stringify(logo)).then(res => {
            setLogoUser(res.data)
        })
    }


    const getEvents = () => {
        axios.get(Constants.APIEndpoints.HOME + '/gethomedata').then(res => {
            setEvents(res.data.events)
            setActivities(res.data.activities)
        })
    }

    return (
        <div>
            <div className="imageLogoHome"
                style={{
                    backgroundImage: `url('${logoUser}')`,
                }}
            >
                <Fragment>
                    <label for={'imagePicker'}
                        className="imageLabel"
                    >

                        <FuseSvgIcon className="text-white">heroicons-outline:camera</FuseSvgIcon>
                        <input
                            type='file'
                            accept="image/*"
                            onChange={(evt) => {
                                const file = evt.target.files[0]
                                let reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                    file.data = reader.result;
                                    file.filename = file.name;
                                    file.filetype = file.type;
                                    changeLogo(file);
                                }

                            }}
                            id={'imagePicker'}
                            name={'logo'}
                            placeholder={'Logo'}
                            style={{ width: "100%", display: 'none' }}
                        />
                    </label>
                </Fragment>
            </div>
            <div className="nameHomeWelcome">
                <Typography>{`Olá, ${user.name}`}</Typography>
            </div>
            <Box sx={{ flexGrow: 1 }} style={{ width: '80%', margin: 'auto' }}>
                <Grid container spacing={6} >
                    <Grid item xs={6}>
                        <div className="gridHome"
                            style={{ height: 450, overflowY: 'auto' }}>
                            <Events events={events} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="gridHome"
                            style={{ height: 450, overflowY: 'auto' }}>
                            <Activities activities={activities} />
                        </div>
                    </Grid>
                </Grid>
            </Box>

        </div>
    )
}

export default Home