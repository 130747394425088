import React, { useEffect, useState } from 'react'
import '../Leads.css'
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'
import DownloadFiles from 'app/utils/DownloadFiles'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import CommonDialog from 'app/components/dialog/CommonDialog'
import axios from 'app/utils/AxiosConfig';
import Constants from 'app/utils/Constants';
import { eventBus } from 'app/utils/EventListener'
import CommonForm from 'app/components/form/CommonForm'

const Info = ({ visuplac, lead, setOpenWinCard, getData, setOpenLossCard }) => {
    const [winCardDialog, setWinCardDialog] = useState(false)
    const [lossCardDialog, setLossCardDialog] = useState(false)
    const [justifies, setJustifies] = useState([])

    useEffect(() => {
        axios
            .get(
                Constants.APIEndpoints.JUSTIFY + "/getalljustify")
            .then((res) => {
                setJustifies(res.data)
            })
    }, [])

    const winLead = () => {
        axios.post(Constants.APIEndpoints.KANBAN + '/winlead', lead).then(res => {
            getData({ inProgress: true })
            eventBus.emit('won-modal-card', {})
        })
    }
    const lossCard = (values) => {
        values.fk_id_card = lead.id_cards
        axios.post(Constants.APIEndpoints.KANBAN + '/losscard', values).then(res => {
            eventBus.emit('lose-modal-card', {})
            setLossCardDialog(false)
            getData({ inProgress: true })
        })
    }

    return (
        <div style={{ padding: 10 }}>
            {
                winCardDialog ? (
                    <ConfirmDialog title="Deseja dar ganho?" cancel={() => setWinCardDialog(false)} confirm={winLead} />
                ) : null
            }

            <CommonDialog
                open={lossCardDialog}
                fullWidth={true}
                maxWidth={'xl'}
                onClose={() => setLossCardDialog(false)}>
                <CommonForm
                    fields={[
                        {
                            label: 'Justificativa',
                            col: 12,
                            name: 'justify',
                            type: 'textarea',
                            //   options: justifies.filter(j => j.type_justify == 2).map(justify => ({ value: justify.id_justify, label: justify.name }))
                        },
                    ]}
                    onSubmit={lossCard}
                />
            </CommonDialog>

            {lead.win == false && lead.loss == false ? (
                <div>
                    <Tooltip title={<h5>Dar Ganho</h5>}>
                        <IconButton onClick={() => {
                            if (visuplac) {
                                setWinCardDialog(true)
                            } else {
                                setOpenWinCard(true)
                            }
                        }
                        }>
                            <Icon style={{ color: 'green' }}>
                                check
                            </Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<h5>Dar Perda</h5>}>
                        <IconButton onClick={() => {
                            if (visuplac) {
                                setLossCardDialog(true)
                            } else {
                                setOpenLossCard(true)
                            }
                        }}>
                            <Icon style={{ color: 'red' }}>
                                close
                            </Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            ) : null}
            <div className='infoDiv'>
                <Icon>
                    receipt
                </Icon>
                <Typography>CAMPANHA</Typography>
            </div>
            {lead.subject && (<div className='infoValue'>{lead.subject}</div>)}
            <div className='infoDiv'>
                <Icon>
                    people
                </Icon>
                <Typography>CLIENTE</Typography>
            </div>
            {lead.name_client && (<div className='infoValue'>{lead.name_client}</div>)}

            <div className='infoDiv'>
                <Icon>
                    contact_page
                </Icon>
                <Typography>CONTATO</Typography>
            </div>
            {lead.contact_name && (<div className='infoValue'>{lead.contact_name}</div>)}

            <div className='infoDiv'>
                <Icon>
                    person_outline
                </Icon>
                <Typography>USUÁRIO QUE CRIOU</Typography>
            </div>
            {lead.name_user && (<div className='infoValue'>{lead.name_user}</div>)}

            <div className='infoDiv'>
                <Icon>
                    person_outline
                </Icon>
                <Typography>EXECUTIVO</Typography>
            </div>
            {lead.responsable && (<div className='infoValue'>{lead.responsable}</div>)}

            <div className='infoDiv'>
                <Icon>
                    money
                </Icon>
                <Typography>VALOR</Typography>
            </div>
            {lead.net_value && (<div className='infoValue'>{(lead.net_value || '').toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>)}
            <div className='infoDiv'>
                <Icon>
                    calendar_today
                </Icon>
                <Typography>DATA DE CADASTRO</Typography>
            </div>
            <div className='infoValue'>{moment(lead.dt_cad).format('DD/MM/YYYY HH:mm:ss')}</div>

            <div className='infoDiv'>
                <Icon>
                    calendar_today
                </Icon>
                <Typography>PERÍODO</Typography>
            </div>
            {lead.dt_start && (<div className='infoValue'>{moment(lead.dt_start).format('DD/MM/YYYY')} - {lead.dt_end ? moment(lead.dt_end).format('DD/MM/YYYY') : ''}</div>)}

            <div className='infoDiv'>
                <Icon>
                    bookmark
                </Icon>
                <Typography>ETIQUETAS</Typography>
            </div>
            <div className='flex'>
                {(lead.tags || []).map(tag => (
                    <div style={{
                        backgroundColor: tag.color, height: 20, width: 80
                        , margin: 4, borderRadius: 10, display: 'flex', color: 'white', fontSize: 10,
                        justifyContent: 'center', alignItems: 'center'
                    }}>{tag.name}</div>
                ))}
            </div>
            {lead.vehicle_name && (
                <div>
                    <div className='infoDiv'>
                        <Icon>
                            description
                        </Icon>
                        <Typography>VEÍCULO</Typography>
                    </div>
                    <div className='infoValue'>{lead.vehicle_name}</div>
                </div>
            )}
            {lead.multiproducts_name && lead.multiproducts_name.length > 0 && (
                <div>
                    <div className='infoDiv'>
                        <Icon>
                            description
                        </Icon>
                        <Typography>PRODUTOS</Typography>
                    </div>
                    <div className='infoValue'>{lead.multiproducts_name.join(', ')}</div>
                </div>
            )}
            {lead.description && (
                <div className='infoDiv'>
                    <Icon>
                        description
                    </Icon>
                    <Typography>OBSERVAÇÃO</Typography>
                </div>)}
            <div dangerouslySetInnerHTML={{ __html: lead.description }} />
            <div className='infoDiv'>
                <Icon>
                    attach_file
                </Icon>
                <Typography>ARQUIVOS</Typography>
            </div>
            {lead.files && (
                <div className='infoValue'>
                    {Array.from(lead.files).map(file => (
                        <div style={{ cursor: 'pointer', padding: 5 }} onClick={() => DownloadFiles(file.filepath, file.name)}>{file.name} - {file.dt_cad}</div>
                    ))}
                </div>
            )
            }
            {lead.justify && (
                <div className='infoDiv'>
                    <Icon>
                        description
                    </Icon>
                    <Typography>JUSTIFICATIVA</Typography>
                </div>)}
            <div dangerouslySetInnerHTML={{ __html: lead.justify }} />
            {lead.fk_id_justify && (
                <div className='infoDiv'>
                    <Icon>
                        description
                    </Icon>
                    <Typography>JUSTIFICATIVA</Typography>
                </div>)}
            <div dangerouslySetInnerHTML={{ __html: lead.fk_id_justify }} />
        </div>
    )
}

export default Info