import React, { useEffect, useMemo, useState } from "react";
import axios from 'app/utils/AxiosConfig'
import Constants from "app/utils/Constants"
import moment from "moment";
import { useDispatch } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import CommonForm from "app/components/form/CommonForm";
import { Icon, IconButton } from "@material-ui/core";

const ReportByTech = ({ }) => {
    const [data, setData] = useState([])
    const [dtBegin, setDtBegin] = useState(undefined)
    const [dtEnd, setDtEnd] = useState(undefined)
    const dispatch = useDispatch()


    useEffect(() => {
        getData()
    }, [])


    const columns = useMemo(
        () => [
            {
                Header: 'Usuário',
                accessor: 'name',
                className: 'front-medium',
                sortable: true,
            },
            {
                Header: 'Total',
                accessor: 'total',
                className: 'front-medium',
                sortable: true
            },
            {
                Header: "Ganhos",
                accessor: "win",
                className: "font-medium",
                sortable: true,
            },

            {
                Header: "Perdas",
                accessor: "loss",
                className: "font-medium",
                sortable: true,
            }, {
                Header: "Em andamento",
                accessor: "in_progress",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Atrasadas",
                accessor: "delay",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Porcentagem de Ganho",
                accessor: "win_percent",
                className: "font-medium",
                sortable: true,
                Cell: ({ row }) => (
                    <div>
                        {row.original.win == 0 ? '0%' : `${parseFloat((row.original.win / row.original.total) * 100).toFixed(2)}%`}
                    </div>
                )
            },
            // {
            //     Header: "Tempo médio para vencer",
            //     accessor: "win_time",
            //     className: "font-medium",
            //     sortable: true,

            // },

        ], [dispatch])

    const getData = (values = {}) => {
        values.dt_end = dtEnd
        values.dt_begin = dtBegin
        axios.post(Constants.APIEndpoints.REPORT + '/activitiesbytechs', values).then(res => {
            setData(res.data)
        })
    }
    const underHeader = (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <CommonForm
                fields={[
                    {
                        col: 6,
                        type: 'date',
                        name: 'dt_begin',
                        label: 'De'
                    },
                    {
                        col: 6,
                        type: 'date',
                        name: 'dt_end',
                        label: 'Até'
                    },
                ]}
                onChangeField={(f, v) => {
                    if (f.name == 'dt_begin') {
                        setDtBegin(v)
                    }
                    if (f.name == 'dt_end') {
                        setDtEnd(v)
                    }
                }}
            />
            <IconButton
                onClick={() => getData()}>
                <Icon>
                    search
                </Icon>
            </IconButton>
        </div>
    )
    return (
        <div>
            <CommonTable
                id="report_tech_list"
                columns={columns}
                data={data}
                underHeader={underHeader}
                icon="error"
                onRowClick={(ev, row) => {
                    if (row) {
                        //            dispatch(openEditContactDialog(row.original));
                    }
                }}
            />
        </div>
    )
}

export default ReportByTech