import CommonForm from "app/components/form/CommonForm";
import React, { useState, useEffect } from 'react'
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import './Dashboard.css'
import moment from "moment-timezone";
import Chart from 'react-apexcharts';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListProposalDetail from "./ListProposalDetail";
import CommonTable from "app/components/table/CommonTable";
import FiltersDash from "./sidebars/FiltersDash";
export default function DashboardDetail() {

    const [data, setData] = useState([])
    const [values, setValues] = useState([]);

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-01'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [selectedVehicle, setSelectedVehicle] = useState([])
    const [selectedUser, setSelectedUser] = useState([])

    const [vehicles, setVehicles] = useState([])
    const [users, setUsers] = useState([])
    const [groups, setGroups] = useState([])
    const [agencies, setAgencies] = useState([])
    const [squares, setSquares] = useState([])
    const [status, setStatus] = useState([])
    const [tags, setTags] = useState([])
    const [clients, setClients] = useState([])
    const [justifies, setJustifies] = useState([])

    const [isExpanded, setIsExpanded] = useState(false);
    const [openFiltersSidebar, setOpenFiltersSidebar] = useState(false)
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        getData()
        axios.post(
            Constants.APIEndpoints.PROPOSAL + "/getallgeneraldata", {
            entities: ['agencies', 'users', 'clients', 'vehicles', 'squares', 'status', 'tags', 'groups', 'justifies']
        })
            .then((res) => {
                setAgencies(res.data.agencies)
                setUsers(res.data.users)
                setClients(res.data.clients)
                setVehicles(res.data.vehicles)
                setSquares(res.data.squares)
                setStatus(res.data.status)
                setTags(res.data.tags);
                setGroups(res.data.groups)
                setJustifies(res.data.justifies)
            })

    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            getData()
        }, 60000)

        return () => { clearInterval(interval) }
    }, [data])

    const getData = (values) => {
        axios.post(Constants.APIEndpoints.DASHBOARD + '/getdashboarddetail', values).then(res => {
            setData(res.data)
            setOpenFiltersSidebar(false)
        })
    }



    const getValueProposalFromAttribute = (data, attribute) => {
        const value = (data || []).reduce((current, next) => {
            let value = next[attribute] != null ? parseFloat(next[attribute]) : 0
            return current + value
        }, 0)
        return value
    }


    const underHeaderByClient = (
        <div style={{ padding: 10 }}>
            <p style={{ fontSize: 14 }}>Valor Bruto: <strong>{(getValueProposalFromAttribute(data.groupByClient, 'approved_gross_value')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
            <p style={{ fontSize: 14 }}>Valor Líquido: <strong>{(getValueProposalFromAttribute(data.groupByClient, 'net_value_approved')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
        </div>
    )

    const underHeaderByVehicle = (
        <div style={{ padding: 10 }}>
            <p style={{ fontSize: 14 }}>Valor Bruto: <strong>{(getValueProposalFromAttribute(data.groupByVehicle, 'approved_gross_value')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
            <p style={{ fontSize: 14 }}>Valor Líquido: <strong>{(getValueProposalFromAttribute(data.groupByVehicle, 'net_value_approved')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
        </div>
    )

    const underHeaderByAgency = (
        <div style={{ padding: 10 }}>
            <p style={{ fontSize: 14 }}>Valor Bruto: <strong>{(getValueProposalFromAttribute(data.groupByAgency, 'approved_gross_value')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
            <p style={{ fontSize: 14 }}>Valor Líquido: <strong>{(getValueProposalFromAttribute(data.groupByAgency, 'net_value_approved')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
        </div>
    )

    const underHeaderByResponsable = (
        <div style={{ padding: 10 }}>
            <p style={{ fontSize: 14 }}>Valor Bruto: <strong>{(getValueProposalFromAttribute(data.groupByResponsable, 'approved_gross_value')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
            <p style={{ fontSize: 14 }}>Valor Líquido: <strong>{(getValueProposalFromAttribute(data.groupByResponsable, 'net_value_approved')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
        </div>
    )

    const underHeaderByStatus = (
        <div style={{ padding: 10 }}>
            <p style={{ fontSize: 14 }}>Valor Bruto: <strong>{(getValueProposalFromAttribute(data.groupByStatus, 'approved_gross_value')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
            <p style={{ fontSize: 14 }}>Valor Líquido: <strong>{(getValueProposalFromAttribute(data.groupByStatus, 'net_value_approved')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></p>
        </div>
    )
    return (
        <div>
            <div className={`floating-button-container ${isExpanded ? 'expanded' : ''}`}>
                <button className="toggle-button" onClick={toggleExpand}>
                    {isExpanded ? '✕' : '☰'}
                </button>

                {isExpanded && (
                    <div className="button-group">
                        <button className="action-button" onClick={() => setOpenFiltersSidebar(true)}>Filtros</button>
                        <button className="action-button">Salvar</button>
                        <button className="action-button">Carregar</button>
                    </div>
                )}
            </div>
            <FiltersDash
                open={openFiltersSidebar}
                onClose={() => setOpenFiltersSidebar(false)}
                clients={clients}
                agencies={agencies}
                users={users}
                squares={squares}
                status={status}
                tags={tags}
                groups={groups}
                justifies={justifies}
                vehicles={vehicles}
                onSubmit={getData}
            />
            < div style={{ marginTop: 60 }}>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} >
                        <Grid item xs={6}>
                            <div className="cardDash"
                                style={{ minHeight: 300 }}>
                                <ListProposalDetail data={data.allProposals} title='NEGÓCIOS DO PERÍODO' />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="cardDash"
                                style={{ minHeight: 300 }}>
                                <ListProposalDetail data={data.proposalsToday} title='NEGÓCIOS DE HOJE' />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cardDash">
                                <span className="title">Por Veículo</span>
                                <div style={{ height: 10 }}></div>
                                {data.groupByVehicle && (
                                    <CommonTable
                                        noHeader={true}
                                        underHeader={underHeaderByVehicle}
                                        miniVersion={true}
                                        id={"by_vehicle_dash_list"}
                                        columns={[
                                            {
                                                Header: 'Veículo',
                                                accessor: 'vehicle',
                                                sortable: true
                                            },

                                            {
                                                Header: 'Valor Bruto',
                                                accessor: 'gross_value_approved',
                                                sortable: true
                                            },
                                            {
                                                Header: 'Valor Líquido',
                                                accessor: 'net_value_approved',
                                                sortable: true
                                            },

                                        ]}
                                        data={(data.groupByVehicle || []).map(d => ({
                                            vehicle: d.vehicle,
                                            campaign: d.campaign,
                                            gross_value_approved: parseFloat(d.approved_gross_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                            net_value_approved: parseFloat(d.net_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                        }))}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cardDash">
                                <span className="title">Por Executivo</span>
                                <div style={{ height: 10 }}></div>
                                {data.groupByResponsable && (
                                    <CommonTable
                                        noHeader={true}
                                        underHeader={underHeaderByResponsable}
                                        miniVersion={true}
                                        id={"by_executive_dash_list"}
                                        columns={[
                                            {
                                                Header: 'Executivo',
                                                accessor: 'responsable',
                                                sortable: true
                                            },
                                            {
                                                Header: 'Valor Bruto',
                                                accessor: 'gross_value_approved',
                                                sortable: true
                                            },
                                            {
                                                Header: 'Valor Líquido',
                                                accessor: 'net_value_approved',
                                                sortable: true
                                            },

                                        ]}
                                        data={(data.groupByResponsable || []).map(d => ({
                                            responsable: d.responsable,
                                            campaign: d.campaign,
                                            gross_value_approved: parseFloat(d.approved_gross_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                            net_value_approved: parseFloat(d.net_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                        }))}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cardDash">
                                <span className="title">Por Cliente</span>
                                <div style={{ height: 10 }}></div>
                                {data.groupByClient && (
                                    <CommonTable
                                        noHeader={true}
                                        underHeader={underHeaderByClient}
                                        miniVersion={true}
                                        id={"by_client_dash_list"}
                                        columns={[
                                            {
                                                Header: 'Client',
                                                accessor: 'client',
                                                sortable: true
                                            },
                                            {
                                                Header: 'Valor Bruto',
                                                accessor: 'gross_value_approved',
                                                sortable: true
                                            },
                                            {
                                                Header: 'Valor Líquido',
                                                accessor: 'net_value_approved',
                                                sortable: true
                                            },

                                        ]}
                                        data={(data.groupByClient || []).map(d => ({
                                            client: d.client,
                                            campaign: d.campaign,
                                            gross_value_approved: parseFloat(d.approved_gross_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                            net_value_approved: parseFloat(d.net_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                        }))}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cardDash">
                                <span className="title">Por Agência</span>
                                <div style={{ height: 10 }}></div>
                                {data.groupByAgency && (
                                    <CommonTable
                                        noHeader={true}
                                        miniVersion={true}
                                        id={"by_agency_dash_list"}
                                        underHeader={underHeaderByAgency}
                                        columns={[
                                            {
                                                Header: 'Agência',
                                                accessor: 'agency',
                                                sortable: true
                                            },
                                            {
                                                Header: 'Valor Bruto',
                                                accessor: 'gross_value_approved',
                                                sortable: true
                                            },
                                            {
                                                Header: 'Valor Líquido',
                                                accessor: 'net_value_approved',
                                                sortable: true
                                            },

                                        ]}
                                        data={(data.groupByAgency || []).map(d => ({
                                            agency: d.agency,
                                            campaign: d.campaign,
                                            gross_value_approved: parseFloat(d.approved_gross_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                            net_value_approved: parseFloat(d.net_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                        }))}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cardDash">
                                <span className="title">Por Status</span>
                                <div style={{ height: 10 }}></div>
                                {data.groupByStatus && (
                                    <CommonTable
                                        noHeader={true}
                                        underHeader={underHeaderByStatus}
                                        miniVersion={true}
                                        id={"by_status_dash_list"}
                                        columns={[
                                            {
                                                Header: 'Status',
                                                accessor: 'status',
                                                sortable: true
                                            },
                                            {
                                                Header: 'Valor Bruto',
                                                accessor: 'gross_value_approved',
                                                sortable: true
                                            },
                                            {
                                                Header: 'Valor Líquido',
                                                accessor: 'net_value_approved',
                                                sortable: true
                                            },

                                        ]}
                                        data={(data.groupByStatus || []).map(d => ({
                                            status: d.status,
                                            campaign: d.campaign,
                                            gross_value_approved: parseFloat(d.approved_gross_value || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                            net_value_approved: parseFloat(d.net_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                        }))}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div >
        </div >
    )
}