import React, { useState } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { useResizeDetector } from 'react-resize-detector';

export default function StatusForm({ values, setPage, getData }) {
  const [valuesForm, setValuesForm] = useState(values)
  const { width, height, ref } = useResizeDetector();

  const translateProposalFields = {
    "dt_emission": "Data de ganho",
    "month_sell": "Mês de venda",
    "year_sell": "Ano de venda",
    "number": "Nº PI/PP",
    "fk_id_square": "Praça",
    "fk_id_responsable": "Responsável",
    "fk_id_client": "Cliente",
    "fk_id_vehicle": "Veículo",
    "fk_id_agency": "Agência",
    "month_placement": "Mês de veiculação",
    "sponsor_vehicle": "Representante do veículo",
    "comission_value_negotiated": "VL. Comissão Negociado",
    "dt_received_vehicle": "Data de Recebimento do Veículo",
    "comission_value": "VL. Comissão",
    "incentive": "Incentivo",
    "taxes_vehicle": "Impostos do veículo",
    "taxes_nf_percent": "%Impostos NF",
    "taxes_nf": "VL. Imposto NF",
    "nf_mosaic": "Nº NF Representante",
    "nf_due_date": "Data de Vencimento da NF",
    "dt_received_mosaic": "Data de Recebimento do Representante",
    "received_value": "Valor Recebido",
    "incentive_nf": "NF Incentivo",
    "dt_received_comission_executive": "Data de Pagamento de Comissão ao Veículo",
    'nf': 'Nº NF'
  }

  const fields = [
    {
      col: 12,
      type: "text",
      name: "name",
      label: "Nome",
      required: true

    },
    {
      col: 6,
      type: "select",
      name: "sector",
      label: "Setor",
      options: [{ value: 'COMERCIAL', label: 'COMERCIAL' }, { value: 'FINANCEIRO', label: 'FINANCEIRO' }],
      required: true
    },
    {
      col: 6,
      type: "multiselect",
      name: "fk_id_role",
      label: "Acesso",
      options: [
        { value: "admin", label: "Admin" },
        { value: "checking", label: "Checking" },
        { value: "comercial", label: "Comercial" },
        { value: "financeiro", label: "Financeiro" },
        { value: "mailing", label: "Mailing" },
        { value: "opec", label: "Opec" },
        { value: "subadmin", label: "Subadmin" },
        { value: "opecadmin", label: "Opec Admin" },
        { value: "executive", label: "Executivo" },
      ],
      required: true
    },

    {
      col: 12,
      type: "multiselect",
      name: "fieldsProposals",
      label: "Campos das propostas que devem estar preenchidos para usar esse status",
      options: Object.keys(translateProposalFields).map(obj => ({ value: obj, label: translateProposalFields[obj] })),
    }
  ];


  const onSubmit = () => {
    axios.post(Constants.APIEndpoints.STATUS + (values.id_status ? '/updateStatus' : '/createStatus'), valuesForm).then(res => {
      setPage('list')
      getData();
    }).catch(error => {
      console.log(error)
    })


  }

  return (
    <div ref={ref}>
      <CommonHeader
        title="Criar Status"
        width={width}
        onBack={() => setPage('list')} />
      <CommonForm
        hasHeader={true}
        values={valuesForm}
        fields={fields}
        onChangeField={(f, v) => {
          values[f.name] = v;
          setValuesForm(values)
        }}
        onSubmit={onSubmit}
      />

    </div>
  );
}

