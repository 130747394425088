import React, { useState } from 'react';
import { useDrag } from 'react-dnd';

import '../configuration.css'
const Number = ({ }) => {
    const [label, setLabel] = useState(0)
    const [{ isDragging }, drag] = useDrag({
        type: 'NUMBER',
        item: { label },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            className={`numberFormula_${isDragging ? 'dragging' : ''}`}
        >
            <input
                value={label}
                onChange={(evt) => setLabel(evt.target.value)}
                type='number'
            />
        </div>
    );
};

export default Number;
