import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import React, { memo } from 'react';

function RightSideLayout2() {
  return (
    <>
      <QuickPanel />
    </>
  );
}

export default memo(RightSideLayout2);
