import React from 'react';
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from '@mui/lab';
import Typography from '@mui/material/Typography';
import moment from 'moment'
import Icon from '@material-ui/core/Icon';
import './Activities.css'
import { Link } from 'react-router-dom';

const SingleActivity = ({ item, last, visuplac }) => {
    return (
        <>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot
                        color="primary"
                        style={{ backgroundColor: '#1b2330', marginTop: 10 }}
                        className="w-40 h-40 p-0  mt-0 flex items-center justify-center"
                    >
                        <Icon>{item.type == 1 ? 'handyman' : 'lightbulb'}</Icon>
                    </TimelineDot>

                    {!last && <TimelineConnector />}
                </TimelineSeparator>

                <TimelineContent className="contentActivity">
                    <Typography className="titleActivity">
                        {item.activity}
                    </Typography>
                    <Typography className="labelActivity" >
                        {moment(item.dt_cad).format('DD/MM/YYYY HH:mm:ss')}
                    </Typography>
                    <div style={{ display: 'flex' }}>
                        {item.type == 1 ? (
                            <div>
                                <Link to={{
                                    pathname: '/propostas',
                                    state: { fk_id_proposals: item.fk_id_item, mode: 'view' }
                                }} >
                                    <p className='linkActivity'>Visualizar</p>
                                </Link>
                                <Link to={{
                                    pathname: '/propostas',
                                    state: { fk_id_proposals: item.fk_id_item, mode: 'edit' }
                                }} >
                                    <p className='linkActivity'>Editar</p>
                                </Link>
                            </div>
                        ) : (
                            <div>
                                <Link to={{
                                    pathname: visuplac ? '/leadsv' : '/leads',
                                    state: { fk_id_card: item.fk_id_item, mode: 'view' }
                                }} >
                                    <p className='linkActivity'>Visualizar</p>
                                </Link>
                                <Link to={{
                                    pathname: visuplac ? '/leadsv' : '/leads',
                                    state: { fk_id_card: item.fk_id_item, mode: 'edit' }
                                }} >
                                    <p className='linkActivity'>Editar</p>
                                </Link>
                            </div>

                        )}

                    </div>
                </TimelineContent>
            </TimelineItem >
        </>

    );
}

export default SingleActivity;
