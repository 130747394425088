import React from "react";
import NumberFormat from 'react-number-format';
import "./Input.css";

export default function Input({ label, value, onchange, hidden, money, disabled, type }) {

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  return (
    <div style={{ width: '100%' }} >
      {label && (
        <label className="label">{label}</label>)}

      {money ? (

        <NumberFormat
          value={value}
          decimalScale={2}
          hidden={hidden}
          className="input"
          disabled={disabled}
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix="R$ "
          fixedDecimalScale={true}
          isNumericString={true}
          onValueChange={(values) => {
            onchange({
              target: {
                value: values.value,
              },
            });
          }}
        />


      ) : (
        <input type={type} disabled={disabled} hidden={hidden} className="input" value={value} onChange={onchange} />

      )}
    </div>
  );
}
