import React, { useState, useEffect } from 'react'
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment-timezone';
import { Typography } from '@material-ui/core';
import axios from 'app/utils/AxiosConfig'
import Constants from 'app/utils/Constants'
import CommonForm from "app/components/form/CommonForm";
import './Dashboard.css'
const Comparatives = ({

}) => {

    const [agencies, setAgencies] = useState([])
    const [clients, setClients] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [status, setStatus] = useState([])
    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])

    const [typeSelected, setTypeSelected] = useState(0)
    const [year, setYear] = useState(moment().year())
    const [item1, setItem1] = useState(0)
    const [item2, setItem2] = useState(0)
    const [item3, setItem3] = useState(0)
    const [item4, setItem4] = useState(0)


    const [data, setData] = useState([])
    useEffect(() => {
        axios
            .get(
                Constants.APIEndpoints.AGENCY + "/getAllAgencies")
            .then((res) => {
                setAgencies(res.data)
            })
        axios
            .get(
                Constants.APIEndpoints.USER + "/getAllUsersSimple")
            .then((res) => {
                setUsers(res.data)
            })

        axios
            .get(
                Constants.APIEndpoints.CLIENT + "/getAllClients")
            .then((res) => {
                setClients(res.data)
            })

        axios
            .get(
                Constants.APIEndpoints.VEHICLE + "/getAllVehicles")
            .then((res) => {
                setVehicles(res.data)
            })

        axios
            .get(
                Constants.APIEndpoints.STATUS + "/getAllStatus")
            .then((res) => {
                setStatus(res.data[0])
            })

        axios.get(Constants.APIEndpoints.TEAM + '/getAllTeams').then(res => {
            setTeams(res.data)
        })

    }, [])

    const getData = () => {
        const values = {
            type: typeSelected,
            year: year,
            item1: item1,
            item2: item2,
            item3: item3,
            item4: item4
        }

        axios.post(Constants.APIEndpoints.DASHBOARD + '/getdashboardcomparative',
            values).then(res => {
                setData(res.data)
            })
    }


    const options = typeSelected == 1 ?
        vehicles.map(v => ({ value: v.id_vehicle, label: v.fancy_name })) :
        typeSelected == 2 ?
            clients.map(v => ({ value: v.id_client, label: v.fancy_name })) :
            typeSelected == 3 ?
                users.map(v => ({ value: v.id_user, label: v.name })) :
                typeSelected == 4 ?
                    status.map(v => ({ value: v.id_status, label: v.name })) :
                    typeSelected == 5 ?
                        agencies.map(v => ({ value: v.id_agency, label: v.fancy_name })) :
                        typeSelected == 6 ?
                            teams.map(v => ({ value: v.id_team, label: v.name })) :
                            []


    const label = typeSelected == 1 ?
        'Veículo' :
        typeSelected == 2 ?
            'Cliente' :
            typeSelected == 3 ?
                'Executivo' :
                typeSelected == 4 ?
                    'Status' :
                    typeSelected == 5 ?
                        'Agência' :
                        typeSelected == 6 ?
                            'Time' :
                            'Item'
    const fields = [{
        col: 2,
        type: 'select',
        name: 'type',
        label: 'Tipo',
        required: true,
        options: [
            { value: 1, label: 'Veículo' },
            { value: 2, label: 'Cliente' },
            { value: 3, label: 'Executivo' },
            { value: 4, label: 'Status' },
            { value: 5, label: 'Agência' },
            { value: 6, label: 'Time' },
        ]
    },
    {
        col: 1,
        type: 'select',
        name: 'year',
        label: 'Ano',
        required: true,
        options: [
            { value: moment().year() - 3, label: moment().year() - 3 },
            { value: moment().year() - 2, label: moment().year() - 2 },
            { value: moment().year() - 1, label: moment().year() - 1 },
            { value: moment().year(), label: moment().year() },
        ]
    },
    {
        col: 2,
        type: 'select',
        name: 'item1',
        label: `${label} 1`,
        options: options
    },
    {
        col: 2,
        type: 'select',
        name: 'item2',
        label: `${label} 2`,
        options: options
    },
    {
        col: 2,
        type: 'select',
        name: 'item3',
        label: `${label} 3`,
        options: options
    },
    {
        col: 2,
        type: 'select',
        name: 'item4',
        label: `${label} 4`,
        options: options
    },
    {
        col: 1,
        type: 'content',
        content: <IconButton
            aria-controls="font-size-menu"
            aria-haspopup="true"
            style={{ marginTop: 15 }}
            onClick={() => {
                getData()
            }}
        >
            <Icon>search</Icon>
        </IconButton>
    }
    ]

    const monthList = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];


    return (
        <div style={{ maxHeight: '90vh', minHeight: '60vh', overflowX: 'scroll' }}>
            <div style={{
                marginTop: 60,
                position: 'sticky', top: 60, zIndex: 1200, backgroundColor: 'rgb(250 250 250)'
            }}>
                <CommonForm
                    fields={fields}
                    values={{ year: year, item1: item1, item2: item2, item3: item3, item4: item4 }}
                    onChangeField={(f, v) => {
                        if (f.name == 'type') {
                            setTypeSelected(v)
                        }
                        if (f.name == 'year') {
                            setYear(v)
                        }
                        if (f.name == 'item1') {
                            setItem1(v)
                        }
                        if (f.name == 'item2') {
                            setItem2(v)
                        }
                        if (f.name == 'item3') {
                            setItem3(v)
                        }
                        if (f.name == 'item4') {
                            setItem4(v)
                        }
                    }}
                />
            </div>
            {Object.keys(data).length > 0 && (
                <Box sx={{ flexGrow: 1 }}
                    style={{ padding: 20, backgroundColor: 'white', margin: 20, borderRadius: 10 }}
                >
                    <Typography className='title'>Valores Brutos</Typography>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(index => (
                        <Grid container spacing={0} margin={2} >
                            <Grid item xs={3}>
                                {monthList[index]}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item1[index].gross_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item2[index].gross_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item3[index].gross_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item4[index].gross_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                        </Grid>
                    ))}
                    <Typography className='title'>Valores Líquidos</Typography>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(index => (
                        <Grid container spacing={0} margin={2} >
                            <Grid item xs={3}>
                                {monthList[index]}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item1[index].value_approved).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item2[index].value_approved).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item3[index].value_approved).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item4[index].value_approved).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                        </Grid>
                    ))}

                    <Typography className='title'>Média Líquida de Negócios</Typography>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(index => (
                        <Grid container spacing={0} margin={2} >
                            <Grid item xs={3}>
                                {monthList[index]}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item1[index].total == 0 ? 0 : (data.item1[index].value_approved / data.item1[index].total)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item2[index].total == 0 ? 0 : (data.item2[index].value_approved / data.item2[index].total)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item3[index].total == 0 ? 0 : (data.item3[index].value_approved / data.item3[index].total)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={2}>
                                {parseFloat(data.item4[index].total == 0 ? 0 : (data.item4[index].value_approved / data.item4[index].total)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                        </Grid>
                    ))}
                    <Typography className='title'>Quantidade de Negócios</Typography>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(index => (
                        <Grid container spacing={0} margin={2} >
                            <Grid item xs={3}>
                                {monthList[index]}
                            </Grid>
                            <Grid item xs={2}>
                                {data.item1[index].total}
                            </Grid>
                            <Grid item xs={2}>
                                {data.item2[index].total}
                            </Grid>
                            <Grid item xs={2}>
                                {data.item3[index].total}
                            </Grid>
                            <Grid item xs={2}>
                                {data.item4[index].total}
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            )}
        </div >
    )
}
export default Comparatives