import React, { useState } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import moment from 'moment-timezone'
import { useResizeDetector } from 'react-resize-detector';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Icon, IconButton, Typography } from "@material-ui/core";
import Store from 'app/utils/Store'
import './Leads.css'

const user = Store.USER
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        width: 520,
        padding: 15
    },
}));
const FunnelPanel = ({
    open,
    getFunnels,
    getData,
    values,
    onClose,
    users,
    funnels,
    idFunnelSelected,
    setIdFunnelSelected,
    setDeleteFunnelDialog
}) => {
    const classes = useStyles();

    const { width, height, ref } = useResizeDetector();
    const [openCreate, setOpenCreate] = useState(false)

    const [valuesForm, setValuesForm] = useState(values)
    const fields = [
        {
            col: 12,
            type: "text",
            name: "name",
            label: "Nome",
            required: true

        },

        {
            col: 12,
            label: "Usuários",
            name: "users",
            type: "multiselect",
            options: (users || []).map(u => ({ value: u.id_user, label: u.name })),
            required: true
        },
        {
            col: 12,
            label: "Restritivo",
            tooltip: 'Se marcado, os usuários só verão os cards que eles criaram, a não ser que ele seja o líder do funil',
            name: "isRestritive",
            type: "checkbox",
        },
        {
            col: 12,
            label: "Líderes",
            name: "leaders",
            type: "multiselect",
            options: (users || []).map(u => ({ value: u.id_user, label: u.name })),
            required: valuesForm?.isRestritive == true,
            visible: valuesForm?.isRestritive == true

        },
    ];


    const onSubmit = () => {
        axios.post(Constants.APIEndpoints.KANBAN + (valuesForm.id_salles_funnel ? '/updatefunnel' : '/createfunnel'), valuesForm).then(res => {
            getFunnels();
            setOpenCreate(false)
        })
    }

    const editFunnel = (funnel) => {
        funnel.isRestritive = funnel.is_restritive
        setValuesForm(funnel)
        setOpenCreate(true)
    }

    const deleteFunnel = (funnel) => {
        setIdFunnelSelected(funnel.id_salles_funnel)
        setDeleteFunnelDialog(true)
    }

    return (
        <div ref={ref}>
            <SwipeableDrawer
                classes={{ paper: clsx(classes.root) }}
                open={open}
                anchor="right"
                onOpen={(ev) => { }}
                onClose={onClose}
                disableSwipeToOpen
            >
                <Typography
                    style={{ textAlign: 'center', fontSize: 20, fontWeight: 500 }}>SEUS FUNIS DE VENDA</Typography>
                {user && user.access.crm_create_funnel ? (
                    <button className='buttonChangeViewMode'
                        onClick={() => setOpenCreate(true)}
                        style={{
                            color: 'white', backgroundColor: '#252f3e', maxWidth: 150
                            , borderRadius: 30, margin: 0, marginTop: 10
                        }}>Criar Novo
                    </button>
                ) : null}
                <Divider
                    style={{ marginTop: 20 }}
                />
                {openCreate ? (
                    <CommonForm
                        values={valuesForm}
                        fields={fields}
                        onChangeField={(f, v) => {
                            let v_ = { ...valuesForm }
                            v_[f.name] = v;
                            setValuesForm(v_)
                        }}
                        onSubmit={onSubmit}
                    />) : null}

                {(funnels || []).map(funnel => (
                    <div className="funnelDiv"
                        style={{
                            backgroundColor: funnel.id_salles_funnel == idFunnelSelected ? '#1b2330' : 'white',
                            color: funnel.id_salles_funnel == idFunnelSelected ? 'white' : 'black'
                        }}

                    >

                        <Typography
                            style={{ width: '75%', height: '80%' }}
                            onClick={() => getData({ inProgress: true, fk_id_salles_funnel: funnel.id_salles_funnel })}
                        >{funnel.name}</Typography>
                        <div>
                            {(funnel.user_cad == user.id_user || user.role == 'admin') && funnel.id_salles_funnel > 0 ? (
                                <IconButton>
                                    <Icon
                                        onClick={() => editFunnel(funnel)}
                                        style={{ color: funnel.id_salles_funnel == idFunnelSelected ? 'white' : 'black' }}
                                    >
                                        edit
                                    </Icon>
                                </IconButton>
                            ) : null}
                            {(funnel.user_cad == user.id_user || user.role == 'admin') && funnel.id_salles_funnel > 0 ? (
                                <IconButton>
                                    <Icon
                                        style={{ color: funnel.id_salles_funnel == idFunnelSelected ? 'white' : 'black' }}
                                        onClick={() => deleteFunnel(funnel)}
                                    >
                                        delete
                                    </Icon>
                                </IconButton>
                            ) : null}
                        </div>
                    </div>
                ))}
            </SwipeableDrawer>
        </div>
    );
}

export default FunnelPanel