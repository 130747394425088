import React, { useState, useEffect } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { useResizeDetector } from 'react-resize-detector';
const QuestionsDefaultForm = ({
    getData,
    values,
    setPage
}) => {
    const [valuesForm, setValuesForm] = useState(values)
    const { width, height, ref } = useResizeDetector();


    const fields = [
        {
            col: 12,
            type: 'text',
            name: 'name',
            label: 'Nome',
            required: true
        },
        {
            col: 12,
            type: 'text',
            name: 'question',
            label: 'Pergunta',
            required: true
        }
    ]

    const onSubmit = () => {
        axios.post(Constants.APIEndpoints.QUESTIONS + (values.id_default_questions ? '/updatequestion' : '/createquestion'), valuesForm).then(res => {
            setPage('list')
            getData();
        })
    }
    return (
        <div ref={ref}>
            <CommonHeader title="Criar Pergunta"
                width={width}
                onBack={() => setPage('list')} />
            <CommonForm
                values={valuesForm}
                hasHeader={true}
                fields={fields}
                onChangeField={(f, v) => {
                    values[f.name] = v;
                    setValuesForm(values)
                }}
                onSubmit={onSubmit}
            />

        </div>)
}

export default QuestionsDefaultForm