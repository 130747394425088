import { motion } from "framer-motion";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import AgencyForm from "./AgencyForm";
import axios from 'app/utils/AxiosConfig';

import CommonView from '../../components/commonView/CommonView'
import CommonDialog from "app/components/dialog/CommonDialog";
import Constants from "app/utils/Constants";
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import ListAgenciesLeads from "../newLeads/Lists/ListAgenciesLeads";
import { Badge, Button } from "@material-ui/core";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
export default function AgencyList(props) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});
  const [singleAgency, setSingleAgency] = useState([])
  const [agencyDialog, setAgencyDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  let [agencySelected, setAgencySelected] = useState([])
  const [totalAgencyApprove, setTotalAgencyApprove] = useState(0)
  const [approveDialog, setApproveDialog] = useState(false)
  const [agencyToReject, setAgencyToReject] = useState(0)
  const [openConfirmRejectDialog, setOpenConfirmRejectDialog] = useState(false)
  const [agenciesToSubstitute, setAgenciesToSubstitute] = useState([])
  const [approved, setApproved] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)

  let clients_ = []


  useEffect(() => {
    axios
      .get(
        Constants.APIEndpoints.CLIENT + "/getAllClients")
      .then((res) => {
        clients_ = res.data

      })
    getTotalAgenciesApprove()

  }, [])

  const getTotalAgenciesApprove = () => {
    axios
      .post(
        Constants.APIEndpoints.AGENCY + "/getallagencies", { approve: true })
      .then((res) => {
        setTotalAgencyApprove(res.data.length)
      })
  }

  const approveAgency = () => {
    axios.post(Constants.APIEndpoints.AGENCY + '/approveagency', { id_agency: singleAgency.id_agency }).then(res => {
      getData(true)
      setApproveDialog(false)
    })
  }

  const rejectAgency = () => {
    axios.post(Constants.APIEndpoints.AGENCY + '/rejectagency', { id_agency: singleAgency.id_agency, newAgency: agencyToReject }).then(res => {
      getData(true)
      setOpenRejectDialog(false)
      getTotalAgenciesApprove()
      setOpenConfirmRejectDialog(false)
    })
  }

  const openRejectAgencyDialog = (id_agency) => {
    setAgencyToReject(id_agency)
    setOpenConfirmRejectDialog(true)
  }

  const openRejectAgency = () => {
    axios
      .post(
        Constants.APIEndpoints.AGENCY + "/getallagencies", { approve: false })
      .then((res) => {
        setAgenciesToSubstitute(res.data)
        setOpenRejectDialog(true)
      })
  }

  const columns = useMemo(
    () => [

      {
        Header: "Nome Fantasia",
        accessor: "fancy_name",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Razão Social",
        accessor: "company_name",
        sortable: true,
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
        sortable: true,
      },
      {
        Header: "Contato",
        accessor: "contact",
        sortable: true,
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {['admin', 'subadmin'].includes(logged_user.role) && row.original.status == 'Approve' ? (
              <IconButton
                onClick={(ev) => {
                  setApproveDialog(true)
                  setSingleAgency(row.original);
                }}
              >
                <Icon>check</Icon>
              </IconButton>
            ) : null}
            {['admin', 'subadmin'].includes(logged_user.role) && row.original.status == 'Approve' ? (
              <IconButton
                onClick={(ev) => {
                  setSingleAgency(row.original)
                  openRejectAgency()
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            ) : null}
            {access.agency_read ? (
              <IconButton
                onClick={(ev) => {
                  viewAgency(row.original);
                }}
              >
                <Icon>remove_red_eye</Icon>
              </IconButton>
            ) : null}
            {access.agency_update ? (

              <IconButton
                onClick={(ev) => {
                  setValues(row.original)
                  setPage('add')
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            ) : null}
            {access.agency_delete ? (

              <IconButton
                onClick={(ev) => {
                  setAgencySelected(row.original)
                  setDeleteDialog(true)

                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const onAdd = () => {
    setValues({})
    setPage("add");
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteAgency = (id) => {
    const data = { id_agency: agencySelected.id_agency }
    axios.post(Constants.APIEndpoints.AGENCY + "/deleteAgency", data).then((res) => {
      getData();
    });
  };

  const viewAgency = (agency) => {

    setAgencyDialog(true)
    let data = [
      {
        col: 12,
        label: 'NOME FANTASIA',
        value: agency.fancy_name
      },
      {
        col: 12,
        label: 'RAZÃO SOCIAL',
        value: agency.company_name
      },
      {
        col: 4,
        label: 'CNPJ',
        value: agency.cnpj
      },

      {
        col: 8,
        label: 'CONTATO',
        value: agency.contact
      },
      {
        col: 6,
        label: 'TELEFONE',
        value: agency.phone
      },
      {
        col: 6,
        label: 'EMAIL',
        value: agency.email
      },

      {
        col: 12,
        label: 'ENDEREÇO',
        value: agency.address
      },
      {
        col: 6,
        label: 'CLIENTES',
        value: clients_.filter(c => c.id_client == agency.fk_id_client)[0] ? clients_.filter(s => s.id_client == agency.fk_id_client)[0].fancy_name : ''
      },


    ]

    setSingleAgency(data)
  }

  const getData = (approve = false) => {
    setApproved(approve)
    axios.post(Constants.APIEndpoints.AGENCY + "/getallagencies", { approve }).then((res) => {
      setData(res.data);
    });
  };

  const rightSide = (
    <div style={{ width: '20%', display: 'flex', justifyContent: 'center' }}>
      <Badge color="secondary" badgeContent={totalAgencyApprove} variant="standard" invisible={approved}>
        <Button
          variant="contained"
          color="white"
          onClick={() => getData(!approved)}
        >
          <span className="hidden sm:flex">{!approved ? 'Aprovações' : 'Aprovadas'}</span>
        </Button>
      </Badge>

    </div>
  )

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
    >
      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar essa Agência?" cancel={() => setDeleteDialog(false)} confirm={deleteAgency} />
      ) : null}

      {approveDialog ? (
        <ConfirmDialog title="Deseja aprovar essa Agência?" cancel={() => setApproveDialog(false)} onClose={() => setApproveDialog(false)} confirm={approveAgency} />
      ) : null}

      {openConfirmRejectDialog ? (
        <ConfirmDialog title="Deseja substituir por essa agência?" cancel={() => setOpenConfirmRejectDialog(false)} onClose={() => setOpenConfirmRejectDialog(false)} confirm={rejectAgency} />
      ) : null}

      <ListAgenciesLeads
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        clients={[]}
        agencies={agenciesToSubstitute}
        selectItem={(id) => openRejectAgencyDialog(id)}
        noCreate={true}
      />

      <CommonDialog
        open={agencyDialog}
        onClose={() => setAgencyDialog(false)}
        title="Ver Agência"
        width="xl"
        print={true}
      >
        <CommonView dialog={true} data={singleAgency} title="Ver Agência" onBack={() => setPage('list')} />

      </CommonDialog>


      {page == "list" ? (
        <CommonTable
          id="agency_list"
          columns={columns}
          data={data}
          rightSide={rightSide}
          icon="directions_car"
          newText="Adicionar Nova Agência"
          onAdd={access.agency_create ? onAdd : null}
          headerTitle="Agências"
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <AgencyForm values={values} setPage={setPage} getData={getData} />
      )}
    </motion.div>
  );
}

