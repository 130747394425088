import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import CommonForm from "app/components/form/CommonForm";
import { Typography } from "@material-ui/core";
import moment from "moment-timezone";
import './finances.css'
const Dailies = ({
    type
}) => {

    const dispatch = useDispatch();
    const user = [];

    const [data, setData] = useState([])
    const [allValues, setAllValues] = useState({})
    const [vehicles, setVehicles] = useState([])
    const [squares, setSquares] = useState([])
    const [teams, setTeams] = useState([])

    const [month, setMonth] = useState(moment().month() + 1)
    const [year, setYear] = useState(moment().year())
    const [vehicle, setVehicle] = useState([])
    const [square, setSquare] = useState([])
    const [team, setTeam] = useState([])

    useEffect(() => {
        axios
            .get(
                Constants.APIEndpoints.VEHICLE + "/getAllVehicles")
            .then((res) => {
                setVehicles(res.data)
            })
        axios
            .get(
                Constants.APIEndpoints.SQUARE + "/getAllSquares")
            .then((res) => {
                setSquares(res.data[0])
            })
        axios
            .get(
                Constants.APIEndpoints.TEAM + "/getAllTeams")
            .then((res) => {
                setTeams(res.data)
            })
        getData()
    }, [])

    const getData = (month = moment().month() + 1, year = moment().year(), id = []) => {
        const data = {
            month: month,
            year: year,
        }
        if (type == 'vehicle') {
            data.vehicle = id
        } else if (type == 'square') {
            data.square = id
        } else {
            data.team = id
        }

        axios.post(Constants.APIEndpoints.FINANCE + '/financesbyday', data).then(res => {
            setData(res.data.proposals)
            setAllValues(res.data.allValues)
        })
    }

    const monthList = [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Dezembro' },
    ];

    const columns = useMemo(
        () => [
            {
                Header: 'Data',
                accessor: "date",
                className: "font-medium",
                sortable: true,
            },
            {
                Header: "Dia",
                accessor: "day",
                sortable: true,
            },
            {
                Header: "Realizado no dia",
                accessor: "realized_day",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: "Meta do Dia",
                accessor: "goal_day",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: "% Realizado/Meta",
                accessor: "balance_realized_goal",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: "Breakeven do Dia",
                accessor: "breakeven_day",
                sortable: true,
                disableSortBy: true
            },
            {
                Header: "Saldo do Breakeven",
                accessor: "balance_breakeven",
                sortable: true,
                disableSortBy: true
            },
        ],
        [dispatch, user.starred]
    );

    return (
        <div>

            <CommonTable
                columns={columns}
                underHeader={
                    <div
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <div
                            style={{ zIndex: 1201, position: 'relative', width: '35vw' }}
                        >
                            <CommonForm
                                fields={[
                                    {
                                        col: 3,
                                        type: 'select',
                                        name: 'month',
                                        label: 'Mês',
                                        options: monthList
                                    },
                                    {
                                        col: 3,
                                        type: 'select',
                                        name: 'year',
                                        label: 'Ano',
                                        options: [
                                            { value: moment().year() - 1, label: moment().year() - 1 },
                                            { value: moment().year(), label: moment().year() },
                                        ]
                                    },
                                    {
                                        col: 6,
                                        type: 'multiselect',
                                        name: 'vehicle',
                                        label: 'Veículo',
                                        options: vehicles.map(v => {
                                            return {
                                                value: v.id_vehicle,
                                                label: v.fancy_name
                                            }
                                        }),
                                        visible: type == 'vehicle'
                                    },
                                    {
                                        col: 6,
                                        type: 'multiselect',
                                        name: 'square',
                                        label: 'Praça',
                                        options: squares.map(v => {
                                            return {
                                                value: v.id_square,
                                                label: v.federative_unit
                                            }
                                        }),
                                        visible: type == 'square'
                                    },
                                    {
                                        col: 6,
                                        type: 'multiselect',
                                        name: 'team',
                                        label: 'Time',
                                        options: teams.map(v => {
                                            return {
                                                value: v.id_teams,
                                                label: v.name
                                            }
                                        }),
                                        visible: type == 'team'
                                    },
                                ]}
                                onChangeField={(f, v) => {
                                    if (f.name == 'month') {
                                        setMonth(v)
                                        getData(v, year, vehicle > 0 ? vehicle : square > 0 ? square : team)
                                    }
                                    if (f.name == 'year') {
                                        setYear(v)
                                        getData(month, v, vehicle > 0 ? vehicle : square > 0 ? square : team)
                                    }
                                    if (f.name == 'vehicle') {
                                        setVehicle(v)
                                        getData(month, year, v)
                                    }
                                    if (f.name == 'square') {
                                        setSquare(v)
                                        getData(month, year, v)
                                    }
                                    if (f.name == 'team') {
                                        setTeam(v)
                                        getData(month, year, v)
                                    }
                                }}
                                values={{
                                    month: month,
                                    year: year
                                }}
                            />
                        </div>
                        <div
                            style={{ display: 'flex', width: '75%' }}
                        >
                            <div style={{ width: '20%' }}>
                                <Typography className="labelFinances">Soma do Realizado</Typography>
                                <Typography className="valueFinances">{allValues.proposal && allValues.proposal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                            </div>
                            <div style={{ width: '20%' }}>
                                <Typography className="labelFinances">Soma da Meta</Typography>
                                <Typography className="valueFinances">{allValues.goals && allValues.goals.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                            </div>
                            <div style={{ width: '20%' }}>
                                <Typography className="labelFinances">% Realizado/Meta</Typography>
                                <Typography className="valueFinances">{allValues.balance_goal}</Typography>
                            </div>
                            <div style={{ width: '20%' }}>
                                <Typography className="labelFinances">Soma do Breakeven</Typography>
                                <Typography className="valueFinances">{allValues.breakeven && allValues.breakeven.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                            </div>
                            <div style={{ width: '20%' }}>
                                <Typography className="labelFinances">% Realizado/Breakeven</Typography>
                                <Typography className="valueFinances">{allValues.balance_breakeven && allValues.balance_breakeven}</Typography>
                            </div>
                        </div>
                    </div>
                }
                data={data}
                onRowClick={(ev, row) => { }}
            />
        </div>
    )
}

export default Dailies