import React, { useState, useEffect } from 'react'
import Timeline from '@mui/lab/Timeline';
import Constants from 'app/utils/Constants'
import axios from 'app/utils/AxiosConfig'
import SingleActivity from './SingleActivity';
import _ from 'lodash';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import Store from 'app/utils/Store';
import moment from 'moment/moment';

const logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const ActivitiesPage = ({ }) => {
    const [activities, setActivities] = useState([])
    useEffect(() => {
        axios.post(Constants.APIEndpoints.USER + '/getallusersactivities', {}).then(res => {
            const activitiesGrouped = _.groupBy(res.data, (atividade) => {
                return format(new Date(atividade.dt_cad), 'yyyy-MM-dd');
            });
            setActivities(activitiesGrouped)
        })
    }, [])

    return (
        <div className="flex flex-col flex-auto px-24 py-40 sm:px-64 sm:pt-72 sm:pb-80">
            <Timeline
                className="py-48"
                position="right"
                sx={{
                    '& .MuiTimelineItem-root:before': {
                        display: 'none',
                    },
                }}
            >

                {Object.entries(activities).map(([data, activitiesData]) => {
                    let title = '';
                    if (data == moment().format('YYYY-MM-DD')) {
                        title = 'Hoje';
                    } else if (data == moment().subtract(1, 'd').format('YYYY-MM-DD')) {
                        title = 'Ontem';
                    } else {
                        title = moment(data).format('DD/MM/YYYY');
                    }

                    return (
                        <div key={data}>
                            <h2>{title}</h2>
                            {activitiesData.map((activity) => (
                                <SingleActivity
                                    last={false}
                                    visuplac={access.menu_lead_visuplac}
                                    item={activity} />
                            ))}
                        </div>
                    );
                })}
            </Timeline>
        </div>
    )
}

export default ActivitiesPage